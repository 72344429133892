import {KTCard, KTCardBody, KTSVG} from "../../../../../../../_metronic/helpers";
import {stringToCents, stringToMillis} from "../../../../../../../_ylb/helpers/numerics";
import YInput from "../../../../../../../_ylb/form/YInput";
import React from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {YSelectProducts} from "../../../../../../../_ylb/form/YSelectProducts";
import {YSelectReferential} from "../../../../../../../_ylb/form/YSelectReferential";
import {YInputGroup} from "../../../../../../../_ylb/form/YInputGroup";
import {useAdminProviderRecap} from "../AdminProviderIdentifiedContext";
import {YSelectCountries} from "../../../../../../../_ylb/form/YSelectCountries";
import {YInputDatePicker} from "../../../../../../../_ylb/form/YInputDatePicker";
import {
  adminProviderUpdateProviderProductEndpoint,
  urlEnrich
} from "../../../../../../../_ylb/api/BackendEndpoints";
import {ProvideRecapHeader} from "../ProviderRecapHeader";
import {ProductMarginDetails} from "./ProductMarginDetails";
import {
  ConsumableProductCategory,
  Country,
  Currency,
  Incoterm,
  ProductPacking,
  ProductPriceUnit,
  ProductType
} from "../../../../../../../_ylb/model/BackendReferential";
import {backendApiRequest} from "../../../../../../../_ylb/api/YBackendApiClient";
import {getClosestUri} from "../../../../../../../_ylb/routing/YRoutingHelper";
import {toast} from "react-toastify";
import {in7Days} from "../../../../../../../_ylb/helpers/date";
import {FieldArray, Form, Formik} from "formik";
import {ProviderProductMargin} from "./ProviderProductMargin";
import {intlReferential} from "../../../../../../../_ylb/i18n/YlbIntlLabel";
import {useIntl} from "react-intl";
import {handleFormErrors} from "../../../../../../../_ylb/form/_form_helpers";

const providerProductInitialValues: ProviderProductData = {
  type: 'UKN',
  caliber: 'UKNOWN',
  category: 'UKN',
  packing: "UKN",
  packingWeight: '0',
  packingPerBox: 0,
  origin: "AF",
  incoterm: "FOB",
  incotermPrices: [{
    country: "AF",
    amount: "0"
  }],
  boxesPerPallet: 0,
  currency: 'EUR',
  priceUnit: "KG",
  price: '0',
  customMarginPercent: '0',
  expiresAt: in7Days()
}

type AdminProviderProductUpdateResponse = {
  productToken: string
}

export type ProductIncotermPrice = {
  country: Country
  amount: string
}

export type ProviderProductData = {
  type: ProductType
  caliber: string
  category: ConsumableProductCategory
  packing: ProductPacking
  packingWeight: string
  packingPerBox: number
  origin: Country
  incoterm: Incoterm
  incotermPrices: ProductIncotermPrice[]
  boxesPerPallet: 0
  currency: Currency
  priceUnit: ProductPriceUnit
  price: string
  customMarginPercent: string
  expiresAt: Date
}

export const allowedIncotermForCustomPrices: Incoterm[] = ["CIF", "CFR"];

export const AdminProviderProductUpdate = ({providerProductData}: {
  providerProductData?: ProviderProductData
}) => {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const {providerToken, productToken} = useParams();
  const adminProviderRecap = useAdminProviderRecap();

  let isExistingProduct = false;
  if (providerProductData) {
    isExistingProduct = true;
  }
  const providerProduct = isExistingProduct ? providerProductData : {
    ...providerProductInitialValues,
    incoterm: adminProviderRecap.provider.incoterm,
    origin: adminProviderRecap.provider.country
  };
  let initialValues = {
    providerToken: providerToken,
    ...providerProduct,
    currency: adminProviderRecap.provider.currency
  }
  if (!allowedIncotermForCustomPrices.includes(initialValues.incoterm!)) {
    initialValues = {
      ...initialValues,
      incotermPrices: []
    }
  }

  return (
      <>
        <ProvideRecapHeader/>
        <Formik initialValues={initialValues}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values, actions) => {
                  let {packingWeight, price, customMarginPercent, ...cleanedPayload} = values;
                  let payload = {
                    ...cleanedPayload,
                    packingWeightGrams: stringToMillis(packingWeight!),
                    priceInCents: stringToCents(price!),
                    customMarginPercentCents: stringToCents(customMarginPercent!),
                    incotermPrices: !allowedIncotermForCustomPrices.includes(values.incoterm!) ? [] :
                        values.incotermPrices!.map(price => ({
                          country: price.country,
                          amountInCents: stringToCents(price.amount)
                        }))
                  };

                  backendApiRequest<AdminProviderProductUpdateResponse>({
                    url: urlEnrich({
                      url: adminProviderUpdateProviderProductEndpoint,
                      providerToken: providerToken
                    }),
                    method: "POST",
                    data: payload,
                    onSuccess: response => {
                      actions.setSubmitting(false);
                      toast.success("Product successfully saved.");
                      if (isExistingProduct) {
                        navigate(getClosestUri(location.pathname, "/product"));
                      } else {
                        navigate(location.pathname.replace("newproduct", "product"));
                      }
                    },
                    onError: error => {
                      handleFormErrors(error.response?.data.payload!, values, actions,
                          fieldName => {
                            if (fieldName === "packingWeightGrams") {
                              return "packingWeight"
                            } else if (fieldName === "priceInCents") {
                              return "price"
                            } else if (fieldName === "customMarginPercentCents") {
                              return "customMarginPercent"
                            }
                            return fieldName;
                          });
                      actions.setSubmitting(false);
                    }
                  });
                }}>
          {({values}) => (
              <Form>
                <div className="row">
                  <div className="col-6">
                    <KTCard>
                      <div className="card-header border-0 pt-5">
                        <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold text-dark">
                      {isExistingProduct ? "Editing product: " + productToken : "New product"}
                    </span>
                        </h3>
                      </div>
                      <KTCardBody className="pt-1">
                        <div className="row pb-5">
                          <YSelectProducts label="Product"
                                           name="type"
                                           containerClass="col-6"
                                           required={true}
                                           disabled={isExistingProduct}/>
                        </div>
                        <div className="row pb-5">
                          <YSelectCountries label="Origin"
                                            name="origin"
                                            containerClass="col-4"
                                            required={true}
                                            disabled={isExistingProduct}/>
                          <YInput label="Size"
                                  name="caliber"
                                  containerClass="col-4"
                                  required={true}
                                  disabled={isExistingProduct}/>
                          <YSelectReferential referential="ConsumableProductCategory"
                                              label="Category"
                                              name="category"
                                              containerClass="col-4"
                                              required={true}
                                              disabled={isExistingProduct}/>
                        </div>
                        <div className="row pb-5">
                          <YSelectReferential label="Packing"
                                              name="packing"
                                              referential="ProductPacking"
                                              containerClass="col-4"
                                              required={true}
                                              disabled={isExistingProduct}/>
                          <YInput
                              label="Packing weight (kg)"
                              name="packingWeight"
                              type="text"
                              containerClass="col-md-4"
                              className="text-end"
                              required={true}
                              disabled={isExistingProduct}/>
                          <YSelectReferential label="Product incoterm"
                                              name="incoterm"
                                              referential="Incoterm"
                                              containerClass="col-4"
                                              required={true}
                                              disabled={isExistingProduct}/>
                        </div>
                        {isExistingProduct &&
                            <div
                                className="row py-3 mb-5 bg-light-warning border-warning border border-dashed d-flex align-items-center text-center">
                              <span>
                                <i className='fas fa-regular fa-up-long ms-2 fs-3'></i>&nbsp;
                                Attributes above cannot be modified after product creation.
                                <i className='fas fa-regular fa-up-long ms-2 fs-3'></i>
                              </span>
                            </div>}
                        <div className="row pb-5">
                          <YInput
                              label="Packing / colis"
                              name="packingPerBox"
                              type="text"
                              containerClass="col-md-3"
                              className="text-end"
                              required={true}/>
                          <YInput
                              label="Colis / pallette"
                              name="boxesPerPallet"
                              type="text"
                              containerClass="col-md-3"
                              className="text-end"
                              required={true}/>
                          <YSelectReferential label="Price unit"
                                              name="priceUnit"
                                              referential="ProductPriceUnit"
                                              containerClass="col-3"
                                              required={true}/>
                          {!allowedIncotermForCustomPrices.includes(values.incoterm!) &&
                              <YInputGroup
                                  rightLabel={adminProviderRecap.provider.currency}
                                  label={"Price / " + intlReferential({
                                    referential: "ProductPriceUnit",
                                    id: values.priceUnit!,
                                    intl: intl
                                  })}
                                  name="price"
                                  type="text"
                                  containerClass="col-md-3"
                                  className="text-end"
                                  required={true}/>}
                        </div>
                        {allowedIncotermForCustomPrices.includes(values.incoterm!) && <>
                          <h3 className="mb-8 mt-5">{values.incoterm} prices by country</h3>
                          <FieldArray name="incotermPrices">
                            {({remove, push}) => (
                                <>{values.incotermPrices && values.incotermPrices.map((incotermPrice, index) => (
                                    <div className="row" key={index}>
                                      <YSelectCountries label="Country"
                                                        name={`incotermPrices.${index}.country`}
                                                        containerClass="col-4"
                                                        required={true}/>
                                      <YInputGroup
                                          rightLabel={adminProviderRecap.provider.currency}
                                          label={values.incoterm + " Price / " + intlReferential({
                                            referential: "ProductPriceUnit",
                                            id: values.priceUnit!,
                                            intl: intl
                                          })}
                                          name={`incotermPrices.${index}.amount`}
                                          type="text"
                                          containerClass="col-md-4"
                                          className="text-end"
                                          required={true}/>
                                      <div className="col-md-4 d-flex align-items-center">
                                        <button
                                            type="button"
                                            className="btn btn-icon btn-active-light-primary w-30px h-30px"
                                            onClick={() => remove(index)}>
                                          <KTSVG
                                              path='/media/icons/duotune/general/gen034.svg'
                                              className='svg-icon svg-icon-2x svg-icon-danger'
                                          />
                                        </button>
                                      </div>
                                    </div>
                                ))}
                                  <a className="btn btn-sm btn-primary mb-10"
                                     onClick={() => {
                                       push({country: "AF", amount: "0"});
                                     }}><i className="fas fa-tag btn-link fs-4 me-2"></i>
                                    Add {values.incoterm} price</a>
                                </>
                            )}
                          </FieldArray>
                        </>}
                        <div className="row">
                          <ProviderProductMargin/>
                          <YInputDatePicker name="expiresAt"
                                            label="Prices expire at"
                                            containerClass="col-md-6"
                                            required={true}/>
                        </div>
                      </KTCardBody>
                    </KTCard>
                    <div className="d-flex justify-content-end mt-10 mb-15">
                      <button type="button" className="btn btn-light me-5"
                              onClick={() => navigate(-1)}>
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-primary"
                              data-kt-users-modal-action="submit">
                  <span className="indicator-label">
                    <i className='fas fa-floppy-disk ms-2 fs-5'></i> Save
                  </span>
                      </button>
                    </div>
                  </div>
                  <div className="col-6">
                    <KTCard>
                      <div className="card-header border-0 pt-5">
                        <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold text-dark">
                    {values.incoterm === "CFR" && <>Margins & prices for CFR destinations</>}
                    {values.incoterm === "CIF" && <>Margins & prices for CIF destinations</>}
                    {values.incoterm === "EXW" && <>Margins & prices from EXW quotations</>}
                    {values.incoterm === "FOB" && <>Margins & prices from transport quotations</>}
                  </span>
                        </h3>
                      </div>
                      <KTCardBody>
                        <ProductMarginDetails/>
                      </KTCardBody>
                    </KTCard>
                  </div>
                </div>
              </Form>
          )}
        </Formik>
      </>
  )
}