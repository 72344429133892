import clsx from "clsx";
import {useField, useFormikContext} from "formik";
import YFormLabel from "./YFormLabel";
import {CommonFieldProps} from "./_form_helpers";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

/**
 * @See https://stackoverflow.com/a/58650742
 */
// we must not pollute props with non-DOM attributes, see https://legacy.reactjs.org/warnings/unknown-prop.html
export const YInputDatePicker = ({
                                   label,
                                   containerClass,
                                   required,
                                   ...props
                                 }: CommonFieldProps<'input'>) => {
  const [field, meta] = useField({...props});
  const {setFieldValue} = useFormikContext();
  return (
      <div className={clsx(containerClass)}>
        <YFormLabel label={label} required={required}/>
        <div>
          {/* Options at https://github.com/Hacker0x01/react-datepicker/blob/main/docs/datepicker.md */}
          <DatePicker selected={(field.value && new Date(field.value)) || null}
                      onChange={val => {
                        setFieldValue(field.name, val);
                      }}
                      dateFormat="dd/MM/yyyy"
                      customInput={
                        <input
                            {...field} {...props}
                            className={clsx(
                                'form-control',
                                {'is-invalid': meta.touched && meta.error}
                            )}
                        />}/>
        </div>
        {meta.touched && meta.error && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{meta.error}</span>
            </div>
        )}
      </div>
  );
}