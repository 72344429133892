import {SupportedLanguages} from "../../../../_ylb/i18n/YlbI18n";
import _ from 'lodash'

export type UserModel = {
  userToken: string
  email: string
  password: string | undefined
  firstName: string
  lastName: string
  scopes: string[]
  language: SupportedLanguages
}

export const isAdmin = (user: UserModel) => {
  return _.chain(user.scopes)
  .filter(scope => scope === 'ROLE_ADMIN')
  .value()
      .length > 0
}

export type AuthModel = {
  apiToken: string
} & UserModel

export interface UserAddressModel {
  addressLine: string
  city: string
  state: string
  postCode: string
}

export interface UserCommunicationModel {
  email: boolean
  sms: boolean
  phone: boolean
}

export interface UserEmailSettingsModel {
  emailNotification?: boolean
  sendCopyToPersonalEmail?: boolean
  activityRelatesEmail?: {
    youHaveNewNotifications?: boolean
    youAreSentADirectMessage?: boolean
    someoneAddsYouAsAsAConnection?: boolean
    uponNewOrder?: boolean
    newMembershipApproval?: boolean
    memberRegistration?: boolean
  }
  updatesFromKeenthemes?: {
    newsAboutKeenthemesProductsAndFeatureUpdates?: boolean
    tipsOnGettingMoreOutOfKeen?: boolean
    thingsYouMissedSindeYouLastLoggedIntoKeen?: boolean
    newsAboutStartOnPartnerProductsAndOtherServices?: boolean
    tipsOnStartBusinessProducts?: boolean
  }
}

export interface UserSocialNetworksModel {
  linkedIn: string
  facebook: string
  twitter: string
  instagram: string
}

