import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
import {Navigate, Outlet, Route, Routes} from "react-router-dom";
import {AdminProviderList} from "./provider/AdminProviderList";
import {AdminProviderIdentifiedRoute} from "./provider/identified/AdminProviderIdentifiedRoute";
import {AdminConfigMarginRuleList} from "./config/AdminConfigMarginRuleList";
import {AdminConfigMarginRuleUpdate} from "./config/AdminConfigMarginRuleUpdate";
import {AdminConfigMarginRuleEdit} from "./config/AdminConfigMarginRuleEdit";
import {AdminProviderUpdate} from "./provider/identified/AdminProviderUpdate";
import {AdminProviderEdit} from "./provider/identified/AdminProviderEdit";
import {AdminCustomerList} from "./customer/AdminCustomerList";
import {AdminCustomerUpdate} from "./customer/AdminCustomerUpdate";
import {PlatformUserList} from "./common/user/PlatformUserList";
import {AdminCustomerEdit} from "./customer/AdminCustomerEdit";
import {PlatformUserEdit} from "./common/user/PlatformUserEdit";
import {PlatformUserUpdate} from "./common/user/PlatformUserUpdate";
import {AdminOrderList} from "./order/AdminOrderList";
import CommonOrderDetailsRoutes from "../common/order/CommonOrderDetailsRoutes";
import {AdminConfigTransportQuotationList} from "./config/AdminConfigTransportQuotationList";

const adminBreadcrumbs: Array<PageLink> = [
  {
    title: 'User Management',
    path: '/apps/admin/price-management',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const AdminRoutes = () => {
  return (
      <Routes>
        <Route element={<Outlet/>}>
          <Route
              path='provider'
              element={
                <>
                  <PageTitle breadcrumbs={adminBreadcrumbs}>Provider list</PageTitle>
                  <AdminProviderList/>
                </>
              }
          />
          {/*<Route*/}
          {/*    path='provider/:providerToken'*/}
          {/*    element={*/}
          {/*      <>*/}
          {/*        <AdminProviderShowProvider/>*/}
          {/*      </>*/}
          {/*    }*/}
          {/*/>*/}
          <Route
              path='provider/:providerToken/edit'
              element={
                <>
                  <AdminProviderEdit/>
                </>
              }
          />
          <Route
              path='provider/new'
              element={
                <>
                  <AdminProviderUpdate/>
                </>
              }
          />
          <Route
              path='provider/:providerToken/*'
              element={
                <>
                  <AdminProviderIdentifiedRoute/>
                </>
              }
          />
          <Route
              path='provider/:providerToken/user'
              element={
                <>
                  <PlatformUserList/>
                </>
              }
          />
          <Route
              path='provider/:providerToken/user/:userToken'
              element={
                <>
                  <PlatformUserEdit/>
                </>
              }
          />
          <Route
              path='provider/:providerToken/user/new'
              element={
                <>
                  <PlatformUserUpdate/>
                </>
              }
          />
          <Route
              path='customer'
              element={
                <>
                  <PageTitle breadcrumbs={adminBreadcrumbs}>Customer list</PageTitle>
                  <AdminCustomerList/>
                </>
              }
          />
          {/*<Route*/}
          {/*    path='customer/:customerToken'*/}
          {/*    element={*/}
          {/*      <>*/}
          {/*        <AdminProviderShowProvider/>*/}
          {/*      </>*/}
          {/*    }*/}
          {/*/>*/}
          <Route
              path='customer/:customerToken/edit'
              element={
                <>
                  <AdminCustomerEdit/>
                </>
              }
          />
          <Route
              path='customer/new'
              element={
                <>
                  <AdminCustomerUpdate/>
                </>
              }
          />
          <Route
              path='customer/:customerToken/user'
              element={
                <>
                  <PlatformUserList/>
                </>
              }
          />
          <Route
              path='customer/:customerToken/user/:userToken'
              element={
                <>
                  <PlatformUserEdit/>
                </>
              }
          />
          <Route
              path='customer/:customerToken/user/new'
              element={
                <>
                  <PlatformUserUpdate/>
                </>
              }
          />
          <Route
              path='order'
              element={
                <>
                  <PageTitle breadcrumbs={adminBreadcrumbs}>Order list</PageTitle>
                  <AdminOrderList/>
                </>
              }
          />
          <Route
              path='order/:orderToken/*'
              element={
                <>
                  <CommonOrderDetailsRoutes/>
                </>
              }
          />
          <Route
              path='config/marginrule'
              element={
                <>
                  <PageTitle breadcrumbs={adminBreadcrumbs}>Margin rules</PageTitle>
                  <AdminConfigMarginRuleList/>
                </>
              }
          />
          <Route
              path='config/marginrule/:marginRuleId'
              element={
                <>
                  <PageTitle breadcrumbs={adminBreadcrumbs}>Edit margin rule</PageTitle>
                  <AdminConfigMarginRuleEdit/>
                </>
              }
          />
          <Route
              path='config/marginrule/new'
              element={
                <>
                  <PageTitle breadcrumbs={adminBreadcrumbs}>New margin rule</PageTitle>
                  <AdminConfigMarginRuleUpdate/>
                </>
              }
          />
        </Route>
        <Route
            path='config/transportquotation'
            element={
              <>
                <PageTitle breadcrumbs={adminBreadcrumbs}>Transport quotations</PageTitle>
                <AdminConfigTransportQuotationList/>
              </>
            }
        />

        <Route index element={<Navigate to='/apps/admin/price-management'/>}/>
      </Routes>
  )
}