import {Country} from "../model/BackendReferential";
import Flags from "country-flag-icons/react/3x2";

/**
 * See https://www.npmjs.com/package/country-flag-icons
 */
export const CountryFlag = ({country, widthEm = 1.3}: {
  country: Country
  widthEm?: number
}) => {
  const Flag = Flags[country];
  return (
      <Flag style={{width: widthEm + 'em'}} title={country}/>
  )
}