import {toNumber} from "lodash";
import {useParams} from "react-router-dom";
import {useOrderWrapper} from "../CommonOrderDetailsContext";
import React from "react";
import {useIntl} from "react-intl";
import './container-details.css'
import {YlbIntlLabel} from "../../../../../../_ylb/i18n/YlbIntlLabel";

export const CommonOrderDetailsContainersTimeline = () => {
  const intl = useIntl();
  const {containerIndex} = useParams();
  const orderWrapper = useOrderWrapper();
  const orderDetails = orderWrapper.order;

  const container = orderDetails.containers[toNumber(containerIndex)];

  return (<>
    <div className="card ylb-mini-card h-xl-100">
      <div className="card-header align-items-center border-0 px-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="fw-bold mb-2 text-gray-900">
            <YlbIntlLabel id="CustomerOrderDetails.containerTimelineHeader" doCapitalize={true}/>
          </span>
        </h3>
      </div>

      <div className="card-body p-5 pt-0">
        <span className="text-muted fw-semibold fs-7">
          <YlbIntlLabel id="CustomerOrderDetails.containerTimelineNone" doCapitalize={true}/>.
        </span>

        {/*<div className="timeline-label">*/}
        {/*  <div className="timeline-item">*/}
        {/*    <div className="timeline-label fw-bold text-gray-800 fs-6">08:42</div>*/}
        {/*    <div className="timeline-badge">*/}
        {/*      <i className="fa fa-genderless text-warning fs-1"></i>*/}
        {/*    </div>*/}
        {/*    <div className="fw-mormal timeline-content text-muted ps-3">*/}
        {/*      Outlines keep you honest. And keep structure*/}
        {/*    </div>*/}
        {/*  </div>*/}

        {/*  <div className="timeline-item">*/}
        {/*    <div className="timeline-label fw-bold text-gray-800 fs-6">10:00</div>*/}
        {/*    <div className="timeline-badge">*/}
        {/*      <i className="fa fa-genderless text-success fs-1"></i>*/}
        {/*    </div>*/}
        {/*    <div className="timeline-content d-flex">*/}
        {/*      <span className="fw-bold text-gray-800 ps-3">AEOL meeting</span>*/}
        {/*    </div>*/}
        {/*  </div>*/}

        {/*</div>*/}
      </div>
    </div>
  </>)
}