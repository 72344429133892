import {KTCard, KTCardBody, KTSVG} from "../../../../../_metronic/helpers";
import React, {useContext} from "react";
import {
  CustomerProductSearchContext,
  CustomerProductSearchResponseItem
} from "../product/CustomerProductSearchContext";
import {createColumnHelper} from "@tanstack/react-table";
import {
  boxPerPalletColumn,
  boxWeightColumn, caliberColumn, categoryColumn,
  containerWeightColumn,
  incotermColumn,
  originColumn, packingColumn,
  priceKgColumn,
  productTypeColumn
} from "../product/CustomerProductSearchColumns";
import {useIntl} from "react-intl";
import {YTable} from "../../../../../_ylb/table/YTable";
import {numberCentsToString, numberMillisToString} from "../../../../../_ylb/helpers/numerics";
import {intlReferential, YlbIntlLabel} from "../../../../../_ylb/i18n/YlbIntlLabel";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {CountryDisplay} from "../../../../../_ylb/helpers/CountryDisplay";
import {getClosestUri} from "../../../../../_ylb/routing/YRoutingHelper";
import {useAuth} from "../../../auth";
import {getCustomerPreferredBoatHub} from "../../../../../_ylb/helpers/authDataHelper";
import {getCountryFromLocode} from "../../../../../_ylb/helpers/transportHelpers";
import clsx from "clsx";

export const CustomerCart = () => {
  const intl = useIntl();
  const {
    checkoutCart,
    updatePalletCount,
    addProductToCheckout,
  } = useContext(CustomerProductSearchContext);
  const navigate = useNavigate();
  const location = useLocation();
  const {currentUser} = useAuth();

  const columnHelper = createColumnHelper<CustomerProductSearchResponseItem>();
  const columns = [
    productTypeColumn(columnHelper),
    caliberColumn(columnHelper),
    categoryColumn(columnHelper, intl),
    packingColumn(columnHelper, intl),
    originColumn(columnHelper, intl),
    boxWeightColumn(columnHelper),
    boxPerPalletColumn(columnHelper),
    priceKgColumn(columnHelper),
    incotermColumn(columnHelper),
    columnHelper.display({
      id: 'actions',
      header: () => <span><YlbIntlLabel id="CustomerCart.actions" doCapitalize={true}/></span>,
      cell: context => <>
        <button type="button"
                className="btn btn-primary btn-sm py-1 px-3"
                onClick={() => {
                  addProductToCheckout(context.row.original.priceToken, 1)
                }}>
          <i className="fas fa-memory btn-link fs-5 me-2"></i>&nbsp;
          <YlbIntlLabel id="CustomerCart.addProduct" doCapitalize={true}/>
        </button>
      </>,
    }),
  ];

  const currentCustomerBoatHub = getCustomerPreferredBoatHub(currentUser!);
  const currentCustomerBoatHubCountry = getCountryFromLocode(currentCustomerBoatHub);

  return (
      <>{
        checkoutCart.providerProducts.length == 0 ?
            <KTCard className="mb-5">
              <KTCardBody className="mt-0">
                <YlbIntlLabel id="CustomerCart.noProviderProducts" doCapitalize={true}/>.<br/><br/>
                <Link to={getClosestUri(location.pathname, "/customer") + "/product"}>
                  <i className='fas fa-regular fa-angles-left ms-2 fs-6'></i>
                  &nbsp;<YlbIntlLabel id="CustomerCart.goBack" doCapitalize={true}/>
                </Link>
              </KTCardBody>
            </KTCard>
            : <>
              <div className="row">
                <div className="col-12">
                  <KTCard className="mb-5">
                    <div className="card-header border-bottom-0">
                      <div className="card-title">
                        <h2>
                          <YlbIntlLabel id="CustomerCart.productsFrom" doCapitalize={true}/>
                          &nbsp;{checkoutCart.providerName}&nbsp;
                          {checkoutCart.providerCountry.length > 0 ? ("(" + intlReferential({
                            referential: "country",
                            id: checkoutCart.providerCountry,
                            intl: intl
                          }) + ")") : ""}
                        </h2>
                      </div>
                    </div>
                    <KTCardBody className="mt-0 pt-0 pb-0">
                      <YTable data={checkoutCart.providerProducts} columns={columns}/>
                    </KTCardBody>
                  </KTCard>
                </div>
              </div>
              <div className="row">
                <div className="col-8">
                  <KTCard className="mb-5">
                    <div className="card-header border-bottom-0">
                      <div className="card-title">
                        <h2><YlbIntlLabel id="CustomerCart.products" doCapitalize={true}/></h2>
                      </div>
                    </div>
                    <KTCardBody className="mt-0 pt-0 pb-5">
                      {checkoutCart.palletCount > 0 ?
                          <table
                              className="table table-row-dashed table-row-gray-300 gy-1 text-center align-middle mb-0">
                            <thead>
                            <tr className="fw-bolder">
                              <th></th>
                              <th className="text-start">
                                <YlbIntlLabel id="CustomerProductSearch.productType"
                                              doCapitalize={true}/>
                              </th>
                              <th className="text-end">
                                <YlbIntlLabel id="CustomerProductSearch.priceKg" doCapitalize={true}/>
                              </th>
                              <th><YlbIntlLabel id="CustomerCart.palletCount" doCapitalize={true}/>
                              </th>
                              <th className="text-end">
                                <YlbIntlLabel id="CustomerCart.totalWeight" doCapitalize={true}/>
                              </th>
                              <th className="text-end">
                                <YlbIntlLabel id="CustomerCart.boxCount" doCapitalize={true}/>
                              </th>
                              <th className="text-end">
                                <YlbIntlLabel id="CustomerCart.totalPrice" doCapitalize={true}/>
                              </th>
                            </tr>
                            </thead>
                            <tbody>{
                              checkoutCart.cartProducts.map(product =>
                                  <tr key={product.priceToken}>
                                    <td className="p-0">
                                      <div className="d-flex align-items-center">
                                        <button
                                            type="button"
                                            className="btn btn-icon btn-active-light-primary w-30px h-30px"
                                            onClick={() => updatePalletCount(product.priceToken, 0)}>
                                          <KTSVG
                                              path='/media/icons/duotune/general/gen034.svg'
                                              className='svg-icon svg-icon-3 svg-icon-danger'
                                          />
                                        </button>
                                      </div>
                                    </td>
                                    <td className="text-start">{product.productDescription}</td>
                                    <td className="text-end">
                                      {numberCentsToString(product.priceKg)}
                                      &nbsp;{product.currency}
                                    </td>
                                    <td className="text-end">
                                      <div className="position-relative w-md-100px float-end">
                                        <button type="button"
                                                className="btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 start-0 fs-8"
                                                onClick={() => {
                                                  updatePalletCount(product.priceToken, product.palletCount - 1);
                                                }}>
                                          <i className="ki-duotone ki-minus-square fs-2">
                                            <span className="path1"></span><span
                                              className="path2"></span></i>
                                        </button>
                                        <input type="text"
                                               className="form-control form-control-solid text-end border-0 ps-12 pe-13 py-1"
                                               placeholder="Amount"
                                               name="manageBudget"
                                               value={product.palletCount}
                                               onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                 const valString = e.currentTarget.value;
                                                 const val = valString.length == 0 ? 0 : parseInt(e.currentTarget.value, 10);
                                                 if (!isNaN(val)) {
                                                   updatePalletCount(product.priceToken, val);
                                                 }
                                               }}/>
                                        <button type="button"
                                                className="btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 end-0"
                                                onClick={() => {
                                                  updatePalletCount(product.priceToken, product.palletCount + 1);
                                                }}>
                                          <i className="ki-duotone ki-plus-square fs-2">
                                            <span className="path1"></span><span
                                              className="path2"></span><span
                                              className="path3"></span></i>
                                        </button>
                                      </div>
                                    </td>
                                    <td className="text-end">
                                      {numberMillisToString(product.totalWeightGrams,
                                          ".",
                                          true)}&nbsp;kg
                                    </td>
                                    <td className="text-end">
                                      {product.boxCount}
                                    </td>
                                    <td className="text-end">
                                      {numberCentsToString(product.totalPriceCents)}
                                      &nbsp;{product.currency}
                                    </td>
                                  </tr>)}
                            <tr>
                              <td colSpan={3} className="text-end fw-bold">
                                <YlbIntlLabel id="CustomerCart.totalTitle" doCapitalize={true}/>
                              </td>
                              <td className={clsx("text-end fw-bolder", {
                                "text-danger": checkoutCart.fillingPercent != 100,
                                "text-success": checkoutCart.fillingPercent == 100,
                              })}
                                  style={{paddingRight: "52px"}}>{checkoutCart.palletCount}</td>
                              <td className="text-end">
                                {numberMillisToString(checkoutCart.totalWeightGrams,
                                    ".",
                                    true)}&nbsp;kg
                              </td>
                              <td className="text-end">
                                {checkoutCart.boxCount}
                              </td>
                              <td className="text-end fw-bold">
                                {numberCentsToString(checkoutCart.totalPriceCents)}
                                &nbsp;{checkoutCart.providerCurrency}
                              </td>
                            </tr>
                            </tbody>
                          </table>
                          : <YlbIntlLabel id="CustomerCart.emptyCart" doCapitalize={true}/>
                      }
                    </KTCardBody>
                  </KTCard>

                </div>
                <div className="col-4">
                  <KTCard className="mb-5">
                    <div className="card-header border-bottom-0">
                      <div className="card-title">
                        <h2><YlbIntlLabel id="CustomerCart.shippingTitle" doCapitalize={true}/></h2>
                      </div>
                    </div>
                    <KTCardBody className="mt-0 pt-0 pb-5">
                      <div className="row">
                        <div className="col-6">
                          <YlbIntlLabel id="CustomerCart.shippingTo" doCapitalize={true}/>:
                        </div>
                        <div className="col-6">
                          <CountryDisplay
                              country={currentCustomerBoatHubCountry}
                              showFlag={false}/>
                          &nbsp;({currentCustomerBoatHub})
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <YlbIntlLabel id="CustomerCart.nextDeparture" doCapitalize={true}/>:
                        </div>
                        <div className="col-6">
                          <YlbIntlLabel id="CommonLabels.asap" doCapitalize={true}/>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <YlbIntlLabel id="CustomerCart.containerCount" doCapitalize={true}/>:
                        </div>
                        <div className="col-6">
                          {checkoutCart.containerCount}<br/>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div
                              className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                            <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                              <span className='fw-bold fs-6'>
                                <YlbIntlLabel id="CustomerCart.containerCompletion"
                                              doCapitalize={true}/>
                              </span>
                              <span className='fw-bolder fs-6'>{checkoutCart.fillingPercent}%</span>
                            </div>
                            <div className='h-5px mx-3 w-100 bg-light mb-3'>
                              <div
                                  className={'rounded h-5px ' + (checkoutCart.fillingPercent === 100 ? "bg-success" : "bg-danger")}
                                  role='progressbar'
                                  style={{width: checkoutCart.fillingPercent + '%'}}
                              ></div>
                            </div>
                          </div>

                          {checkoutCart.fillingPercent == 100 ?
                              <div className="fw-bolder text-success">
                                <YlbIntlLabel id="CustomerCart.containerFull" doCapitalize={true}/>
                              </div>
                              : <div className="fw-bolder text-danger">
                                <YlbIntlLabel id="CustomerCart.containerNotFull1"
                                              doCapitalize={true}/>
                                &nbsp;{checkoutCart.containerCount * checkoutCart.palletPerContainer - checkoutCart.palletCount}&nbsp;
                                <YlbIntlLabel id="CustomerCart.containerNotFull2"
                                              doCapitalize={false}/>
                              </div>}
                        </div>
                      </div>
                    </KTCardBody>
                  </KTCard>
                </div>
              </div>
              <div className="d-flex justify-content-end mt-10 mb-15">
                <button type="button" className="btn btn-light me-5"
                        onClick={() => navigate(-1)}>
                  <YlbIntlLabel id="CommonLabels.back" doCapitalize={true}/>
                </button>
                <button type="submit" className="btn btn-primary"
                        data-kt-users-modal-action="submit"
                        onClick={() => navigate(getClosestUri(location.pathname, "/customer") + "/checkout")}
                        disabled={checkoutCart.palletCount <= 0 || checkoutCart.fillingPercent != 100}>
                  <span className="indicator-label">
                    <i className='fas fa-circle-arrow-right ms-2 fs-5'></i>&nbsp;
                    <YlbIntlLabel id="CustomerCart.goToCheckout" doCapitalize={true}/>
                  </span>
                </button>
              </div>
            </>
      }

      </>
  )
}