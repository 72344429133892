import {
  getCoreRowModel,
  useReactTable,
  flexRender,
  SortingState,
  getSortedRowModel, CellContext, Row, HeaderContext
} from '@tanstack/react-table';
import type {ColumnDef} from '@tanstack/react-table';
import React, {useState} from "react";
import {TableCellProps, TableHeaderProps} from "react-table";
import clsx from "clsx";

interface ReactTableProps<T extends object> {
  data: T[];

  // seems like a bug if we remove "any". See https://github.com/TanStack/table/issues/4382
  columns: ColumnDef<T, any>[];

  onClickRows?: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: Row<T>) => void;
}

/**
 * Had to add this to be able to customize <td>
 */
declare module '@tanstack/react-table' {
  interface ColumnMeta<TData, TValue> {
    getCellContext?: (context: CellContext<TData, TValue>) => TableCellProps | void,
    getHeaderContext?: (context: HeaderContext<TData, TValue>) => TableHeaderProps | void,
  }
}

/**
 * Sort is performed by accessor value.
 * @see https://dev.to/esponges/create-a-reusable-react-table-component-with-typescript-56d4
 */
export const YTable = <T extends object>({data, columns, onClickRows}: ReactTableProps<T>) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const onClickRowsConcrete = onClickRows ?? (() => {
  });

  return (
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden p-2">
              <table
                  className={clsx("table table-row-dashed table-row-gray-300 gy-1 text-center mb-0",
                      {"table-hover": typeof onClickRows !== "undefined"})}>
                <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id} className="fw-bolder fs-6 text-gray-800 text-center">
                      {headerGroup.headers.map((header) => {
                        let hasHeaderMeta = header.getContext().column.columnDef.meta
                        return (
                            <th key={header.id}
                                {...(hasHeaderMeta && hasHeaderMeta.getHeaderContext && {...hasHeaderMeta.getHeaderContext(header.getContext())})}>
                              {header.isPlaceholder ? null : (
                                  <div
                                      {...{
                                        className: clsx(
                                            {
                                              'cursor-pointer select-none': header.column.getCanSort(),
                                              'table-sort-asc': header.column.getIsSorted() as string === "asc",
                                              'table-sort-desc': header.column.getIsSorted() as string === "desc"
                                            }),
                                        onClick: header.column.getToggleSortingHandler(),
                                      }}
                                  >
                                    {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )}
                                  </div>
                              )}
                            </th>
                        )
                      })}
                    </tr>
                ))}
                </thead>
                <tbody>
                {table.getRowModel().rows.map((row) => (
                    <tr key={row.id} onClick={(e) => onClickRowsConcrete(e, row)}
                        className={clsx({'cursor-pointer': typeof onClickRows !== 'undefined'})}>
                      {row.getVisibleCells().map((cell) => {
                        let hasCellMeta = cell.getContext().cell.column.columnDef.meta
                        return (
                            <td key={cell.id}
                                {...(hasCellMeta && hasCellMeta.getCellContext && {...hasCellMeta.getCellContext(cell.getContext())})}>
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                        )
                      })}
                    </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
  );
};