import React from "react";
import {Navigate, Outlet, Route, Routes} from "react-router-dom";
import {PageLink, PageTitle} from "../../../../../../_metronic/layout/core";
import {AdminProviderIdentifiedContext} from "./AdminProviderIdentifiedContext";
import {AdminProviderProductList} from "./product/AdminProviderProductList";
import {AdminProviderProductShow} from "./product/AdminProviderProductShow";
import {AdminProviderProductUpdate} from "./product/AdminProviderProductUpdate";
import {AdminProviderProductEdit} from "./product/AdminProviderProductEdit";

const adminProviderIdentifiedBreadCrumbs: Array<PageLink> = [
  {
    title: 'Account',
    path: '/crafted/account/overview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const AdminProviderIdentifiedRoute: React.FC = () => {
  return (
      <Routes>
        <Route
            element={
              <AdminProviderIdentifiedContext>
                <Outlet/>
              </AdminProviderIdentifiedContext>
            }
        >
          <Route
              path='product'
              element={
                <>
                  <PageTitle breadcrumbs={adminProviderIdentifiedBreadCrumbs}>
                    Product list
                  </PageTitle>
                  <AdminProviderProductList/>
                </>
              }
          />
          <Route
              path='newproduct'
              element={
                <>
                  <PageTitle breadcrumbs={adminProviderIdentifiedBreadCrumbs}>
                    New product
                  </PageTitle>
                  <AdminProviderProductUpdate/>
                </>
              }
          />
          <Route
              path='product/:productToken'
              element={
                <>
                  <PageTitle breadcrumbs={adminProviderIdentifiedBreadCrumbs}>
                    Show product
                  </PageTitle>
                  <AdminProviderProductShow/>
                </>
              }
          />
          <Route
              path='product/:productToken/edit'
              element={
                <>
                  <PageTitle breadcrumbs={adminProviderIdentifiedBreadCrumbs}>
                    Edit product
                  </PageTitle>
                  <AdminProviderProductEdit/>
                </>
              }
          />
          <Route index element={<Navigate to='/admin/provider'/>}/>
        </Route>
      </Routes>
  )
}