import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {NotYetFetched, YFetched} from "../../../../../../_ylb/api/YFetched";
import {backendApiRequest} from "../../../../../../_ylb/api/YBackendApiClient";
import {adminProviderDetailsEndpoint, urlEnrich} from "../../../../../../_ylb/api/BackendEndpoints";
import {AdminProviderUpdate, ProviderData} from "./AdminProviderUpdate";
import {numberCentsToString} from "../../../../../../_ylb/helpers/numerics";
import {Address} from "../../../../../../_ylb/model/Address";
import {Incoterm, TransportHub} from "../../../../../../_ylb/model/BackendReferential";

export type ProviderDto = {
  token: string
  name: string
  displayName: string
  preDeliveryPaymentPercent: number
  marginPercentCents: number
  containerPreparationDelayDays: number
  defaultIncoterm: Incoterm
  preferredBoatHub: TransportHub
  registrationNumber: string
  billingAddress: Address
}

export const AdminProviderEdit = () => {
  const {providerToken} = useParams();
  const [providerData, setProviderData] = useState<YFetched<ProviderData>>(NotYetFetched);

  useEffect(() => {
        backendApiRequest<ProviderDto>({
          url: urlEnrich({
            url: adminProviderDetailsEndpoint,
            providerToken: providerToken
          }),
          onSuccess: (res) => {
            // destructuring assignment to remove transformed value
            const {marginPercentCents, ...providerDto} = res.data.data!;
            setProviderData({
              data: {
                ...providerDto,
                marginPercent: numberCentsToString(marginPercentCents)
              },
              isFetched: true
            })
          }
        });
      }, [providerToken]
  );

  return <>{
    providerData.isFetched ?
        <>
          <AdminProviderUpdate providerData={providerData.data}/>
        </>
        : <></>
  }</>
}