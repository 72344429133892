import React, {useEffect, useState} from "react";
import {NotYetFetched, YFetched} from "../../../../../../../_ylb/api/YFetched";
import {numberCentsToString} from "../../../../../../../_ylb/helpers/numerics";
import {backendApiRequest} from "../../../../../../../_ylb/api/YBackendApiClient";
import {
  adminProviderProductCustomMarginEndpoint,
  urlEnrich
} from "../../../../../../../_ylb/api/BackendEndpoints";
import {useFormikContext} from "formik";
import {ProviderProductData} from "./AdminProviderProductUpdate";
import {YInputGroup} from "../../../../../../../_ylb/form/YInputGroup";
import {useParams} from "react-router-dom";
import YFormLabel from "../../../../../../../_ylb/form/YFormLabel";

type CustomMarginDto = {
  customMarginPercentCents: number
}

export const ProviderProductMargin = () => {
  const {providerToken} = useParams();
  const {
    values: {type, customMarginPercent},
    setFieldValue,
  } = useFormikContext<ProviderProductData>();

  const [marginFetched, setMarginFetched] =
      useState<YFetched<boolean>>(NotYetFetched);

  const label = "Provider-product margin";
  const containerClass = "col-md-4";
  useEffect(() => {
        if (type !== "UKN") {
          setMarginFetched(NotYetFetched);
          backendApiRequest<CustomMarginDto>({
            method: "GET",
            url: urlEnrich({
              url: adminProviderProductCustomMarginEndpoint,
              providerToken: providerToken,
              productType: type,
            }),
            onSuccess: (res) => {
              const margin = numberCentsToString(res.data.data!.customMarginPercentCents);
              if (customMarginPercent != margin) {
                setFieldValue('customMarginPercent', margin);
              }
              setMarginFetched({
                data: true,
                isFetched: true
              });
            }
          });
        }
      }, [type]
  );

  return (
      <>
        {type !== "UKN" ? <>
          {marginFetched ?
              <YInputGroup
                  rightLabel="%"
                  label={label}
                  name="customMarginPercent"
                  type="text"
                  containerClass={containerClass}
                  className="text-end"/>
              : <div className={containerClass}>
                <YFormLabel label={label}/>
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>&nbsp;
                Loading margin...
              </div>}
        </> : <div className={containerClass}>
          <YFormLabel label={label}/>
          <div className="input-group">
            <i>(no product selected above)</i>
          </div>
        </div>}
      </>
  )
}