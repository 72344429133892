import React, {useEffect, useState} from "react";
import {backendApiRequest} from "../../../../../_ylb/api/YBackendApiClient";
import {customerOrderListEndpoint} from "../../../../../_ylb/api/BackendEndpoints";
import {KTCard, KTCardBody} from "../../../../../_metronic/helpers";
import {
  Country,
  Currency,
  OrderStatus,
  ProductType
} from "../../../../../_ylb/model/BackendReferential";
import {NotYetFetched, YFetched} from "../../../../../_ylb/api/YFetched";
import {createColumnHelper} from "@tanstack/react-table";
import {YTable} from "../../../../../_ylb/table/YTable";
import moment from "moment";
import {CustomerOrderStatusBadge} from "./CustomerOrderStatusBadge";
import {CountryDisplay} from "../../../../../_ylb/helpers/CountryDisplay";
import {numberCentsToString} from "../../../../../_ylb/helpers/numerics";
import {intlReferential, YlbIntlLabel} from "../../../../../_ylb/i18n/YlbIntlLabel";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";

type CustomerOrderListItemDto = {
  token: string
  createdAt: Date
  status: OrderStatus
  productTypes: ProductType[]
  shippingFrom: Country
  departureDate?: Date
  totalPriceCentsNoTax: number
  currency: Currency
}

type CustomerOrderListDto = {
  orders: CustomerOrderListItemDto[]
}

export const CustomerOrderList = () => {
  const [orderList, setOrderList] = useState<YFetched<CustomerOrderListDto>>(NotYetFetched);
  const intl = useIntl();
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<CustomerOrderListItemDto>();
  const columns = [
    columnHelper.accessor("createdAt",
        {
          header: () => <span><YlbIntlLabel id="CustomerOrderList.creationDate"
                                            doCapitalize={true}/></span>,
          cell: (context) => moment(context.getValue()).format("DD/MM/yyyy"),
          meta: {
            getCellContext: (context) => {
              return {
                key: context.cell.id,
                className: 'text-center',
              }
            },
          }
        }),
    columnHelper.accessor("status",
        {
          header: () => <span><YlbIntlLabel id="CustomerOrderList.status"
                                            doCapitalize={true}/></span>,
          cell: (context) => <CustomerOrderStatusBadge status={context.getValue()}/>,
          meta: {
            getCellContext: (context) => {
              return {
                key: context.cell.id,
                className: 'text-center',
              }
            },
          }
        }),
    columnHelper.accessor((originalRow) =>
            originalRow.productTypes.length == 0 ? ""
                : intlReferential({
                  referential: "ProductType",
                  id: originalRow.productTypes[0],
                  intl: intl
                }),
        {
          id: "products",
          header: () => <span><YlbIntlLabel id="CustomerOrderList.products"
                                            doCapitalize={true}/></span>,
          cell: (context) =>
              <span className="d-flex flex-column">
              {context.row.original.productTypes.length == 0 ? "(None)"
                  : context.row.original.productTypes.map(productType =>
                      <span key={context.row.original.token + productType}
                            className="fs-7 fw-semibold text-muted">
                        {intlReferential({
                          referential: "ProductType",
                          id: productType,
                          doCapitalize: true,
                          intl: intl
                        })}
                      </span>
                  )}
                   </span>,
          meta: {
            getCellContext: (context) => {
              return {
                key: context.cell.id,
                className: 'text-start',
              }
            },
            getHeaderContext: (context) => {
              return {
                key: context.header.id,
                className: 'text-start',
              }
            },
          }
        }),
    columnHelper.accessor("shippingFrom",
        {
          header: () => <span><YlbIntlLabel id="CustomerOrderList.shippingFrom"
                                            doCapitalize={true}/></span>,
          cell: (context) => <CountryDisplay country={context.getValue()}/>,
          meta: {
            getCellContext: (context) => {
              return {
                key: context.cell.id,
                className: 'text-start',
              }
            },
            getHeaderContext: (context) => {
              return {
                key: context.header.id,
                className: 'text-start',
              }
            },
          }
        }),
    columnHelper.accessor("departureDate",
        {
          header: () => <span><YlbIntlLabel id="CustomerOrderList.departureDate"
                                            doCapitalize={true}/></span>,
          cell: (context) =>
              context.getValue() ?
                  moment(context.getValue()).format("DD/MM/yyyy")
                  : <i><YlbIntlLabel id="CommonLabels.asap"
                                     doCapitalize={true}/></i>,
          meta: {
            getCellContext: (context) => {
              return {
                key: context.cell.id,
                className: 'text-center',
              }
            },
          }
        }),
    columnHelper.accessor("totalPriceCentsNoTax",
        {
          header: () => <span><YlbIntlLabel id="CustomerOrderList.totalNoTax" doCapitalize={true}/></span>,
          cell: (context) =>
              <>{numberCentsToString(context.getValue())}&nbsp;{context.row.original.currency}</>,
          meta: {
            getCellContext: (context) => {
              return {
                key: context.cell.id,
                className: 'text-end',
              }
            },
            getHeaderContext: (context) => {
              return {
                key: context.header.id,
                className: 'text-end',
              }
            },
          }
        }),
  ];

  useEffect(() => {
    backendApiRequest<CustomerOrderListDto>({
      url: customerOrderListEndpoint,
      onSuccess: (response) => setOrderList({
        isFetched: true,
        data: response.data.data!
      })
    });
  }, []);

  return (
      <KTCard>
        <KTCardBody className='py-4'> {
          orderList.isFetched ? orderList.data!.orders.length > 0 ? (
              <>
                <YTable data={orderList.data!.orders} columns={columns}
                        onClickRows={(e, row) =>
                            navigate("/customer/order/" + row.original.token)}/>
              </>) : (
              <>
                <YlbIntlLabel id="CustomerOrderList.noOrders" doCapitalize={true}/>.
              </>
          ) : (
              <>
                <YlbIntlLabel id="CommonLabels.loading" doCapitalize={true}/>...
              </>
          )
        }
        </KTCardBody>
      </KTCard>
  )

}