import React from 'react';
import clsx from "clsx";
import {useField} from "formik";
import YFormLabel from "./YFormLabel";
import {CommonFieldProps} from "./_form_helpers";
import _ from "lodash";

// we must not pollute props with non-DOM attributes, see https://legacy.reactjs.org/warnings/unknown-prop.html
const YInput = ({label, containerClass, required, ...props}: CommonFieldProps<'input'>) => {
  const [field, meta] = useField({...props});

  // removing "0" values that can cause user typing issues
  let inputValue = field.value;
  if (_.isNumber(inputValue)) {
    if (inputValue === 0) {
      inputValue = "";
    }
  } else if (inputValue && /^0/.test(inputValue)) {
    inputValue = inputValue.replace(/^0/, "")
  }

  return (
      <div className={clsx(containerClass)}>
        <YFormLabel label={label} required={required}/>
        <input
            {...field} {...props}
            className={clsx(
                'form-control',
                {'is-invalid': meta.touched && meta.error},
                props.className
            )}
            value={inputValue}
        />
        {meta.error && <div className='fv-plugins-message-container invalid-feedback'>
          {meta.error}
        </div>}
      </div>
  );
}

export default YInput;