import {ColumnHelper} from "@tanstack/react-table";
import {CustomerProductSearchResponseItem} from "./CustomerProductSearchContext";
import {IntlShape} from "react-intl";
import {intlReferential, YlbIntlLabel} from "../../../../../_ylb/i18n/YlbIntlLabel";
import {CountryDisplay} from "../../../../../_ylb/helpers/CountryDisplay";
import {
  bigDecimalCentsToString,
  numberCentsToString,
  numberMillisToString
} from "../../../../../_ylb/helpers/numerics";
import React from "react";
import bigDecimal from "js-big-decimal";
import {caliberDisplay} from "../../../../../_ylb/helpers/ProductDescription";

export const departureColumn = (columnHelper: ColumnHelper<CustomerProductSearchResponseItem>,
                                intl: IntlShape) =>
    columnHelper.accessor((originalRow) =>
            intlReferential({referential: "country", id: originalRow.departure, intl: intl}),
        {
          id: "departure",
          header: () => <span><YlbIntlLabel id="CustomerProductSearch.expeditionCountryShort"
                                            doCapitalize={true}/></span>,
          cell: (context) =>
              <CountryDisplay country={context.row.original.departure}/>,
          meta: {
            getCellContext: (context) => {
              return {
                key: context.cell.id,
                className: 'text-start',
              }
            },
          }
        });

export const providerColumn = (columnHelper: ColumnHelper<CustomerProductSearchResponseItem>) =>
    columnHelper.accessor("providerName", {
      header: () => <span><YlbIntlLabel id="CustomerProductSearch.provider"
                                        doCapitalize={true}/></span>,
      cell: (context) => context.getValue(),
      meta: {
        getCellContext: (context) => {
          return {
            key: context.cell.id,
            className: 'text-start',
          }
        },
        getHeaderContext: (context) => {
          return {
            key: context.header.id,
            className: 'text-start',
          }
        },
      }
    });

export const productTypeColumn = (columnHelper: ColumnHelper<CustomerProductSearchResponseItem>,
                                  providerName?: string) =>
    columnHelper.accessor("productDescription",
        {
          header: () => <>{
            providerName ? <span>
                <YlbIntlLabel id="CustomerProductSearch.productOfProvider"
                              doCapitalize={true}/> &nbsp;{providerName}
            </span>
                : <span>
                  <YlbIntlLabel id="CustomerProductSearch.productType"
                                doCapitalize={true}/>
              </span>
          }</>,
          cell: (context) => context.getValue(),
          meta:
              {
                getCellContext: (context) => {
                  return {
                    key: context.cell.id,
                    className: 'text-start',
                  }
                },
                getHeaderContext:
                    (context) => {
                      return {
                        key: context.header.id,
                        className: 'text-start',
                      }
                    },
              }
        });

export const caliberColumn = (columnHelper: ColumnHelper<CustomerProductSearchResponseItem>) =>
    columnHelper.accessor("caliber",
        {
          header: () => <span>
                <YlbIntlLabel id="CustomerProductSearch.caliber" doCapitalize={true}/>
            </span>,
          cell: (context) => caliberDisplay(context.getValue()),
          meta:
              {
                getCellContext: (context) => {
                  return {
                    key: context.cell.id,
                    className: 'text-start',
                  }
                },
                getHeaderContext:
                    (context) => {
                      return {
                        key: context.header.id,
                        className: 'text-start',
                      }
                    },
              }
        });

export const categoryColumn = (columnHelper: ColumnHelper<CustomerProductSearchResponseItem>,
                               intl: IntlShape) =>
    columnHelper.accessor("category",
        {
          header: () => <span>
                <YlbIntlLabel id="CustomerProductSearch.category" doCapitalize={true}/>
            </span>,
          cell: (context) => intlReferential({
            referential: "ConsumableProductCategory",
            id: context.getValue(),
            doCapitalize: true,
            intl: intl
          }),
          meta:
              {
                getCellContext: (context) => {
                  return {
                    key: context.cell.id,
                    className: 'text-start',
                  }
                },
                getHeaderContext:
                    (context) => {
                      return {
                        key: context.header.id,
                        className: 'text-start',
                      }
                    },
              }
        });

export const packingColumn = (columnHelper: ColumnHelper<CustomerProductSearchResponseItem>,
                              intl: IntlShape) =>
    columnHelper.accessor("packing",
        {
          header: () => <span>
                <YlbIntlLabel id="CustomerProductSearch.packing" doCapitalize={true}/>
            </span>,
          cell: (context) => (context.getValue() !== 'UKN' ? intlReferential({
            referential: "ProductPacking",
            id: context.getValue(),
            doCapitalize: true,
            intl: intl
          }) + " " : "") + numberMillisToString(context.row.original.packingWeightGrams!, ".", true) + "kg",
          meta:
              {
                getCellContext: (context) => {
                  return {
                    key: context.cell.id,
                    className: 'text-start',
                  }
                },
                getHeaderContext:
                    (context) => {
                      return {
                        key: context.header.id,
                        className: 'text-start',
                      }
                    },
              }
        });

export const originColumn = (columnHelper: ColumnHelper<CustomerProductSearchResponseItem>,
                             intl: IntlShape) =>
    columnHelper.accessor((originalRow) =>
            intlReferential({referential: "country", id: originalRow.origin, intl: intl}),
        {
          id: "origin",
          header: () => <span><YlbIntlLabel id="CustomerProductSearch.originCountryShort"
                                            doCapitalize={true}/></span>,
          cell: (context) =>
              <CountryDisplay country={context.row.original.origin}/>,
          meta: {
            getCellContext: (context) => {
              return {
                key: context.cell.id,
                className: 'text-start',
              }
            },
            getHeaderContext: (context) => {
              return {
                key: context.header.id,
                className: 'text-start',
              }
            },
          }
        });

export const boxPerPalletColumn = (columnHelper: ColumnHelper<CustomerProductSearchResponseItem>) =>
    columnHelper.accessor("boxPerPallet", {
      header: () => <span><YlbIntlLabel id="CustomerProductSearch.boxPerPallet"
                                        doCapitalize={true}/></span>,
      cell: (context) => context.getValue(),
      meta: {
        getCellContext: (context) => {
          return {
            key: context.cell.id,
            className: 'text-end',
          }
        },
        getHeaderContext: (context) => {
          return {
            key: context.header.id,
            className: 'text-end',
          }
        },
      }
    });

export const boxWeightColumn = (columnHelper: ColumnHelper<CustomerProductSearchResponseItem>) =>
    columnHelper.display({
      id: 'boxWeight',
      header: () => <span><YlbIntlLabel id="CustomerProductSearch.boxWeight"
                                        doCapitalize={true}/></span>,
      cell: (context) =>
          <>{numberMillisToString(context.row.original.packingWeightGrams
              * context.row.original.packingPerBox, ".", true)}&nbsp;kg</>,
      meta: {
        getCellContext: (context) => {
          return {
            key: context.cell.id,
            className: 'text-end',
          }
        },
        getHeaderContext: (context) => {
          return {
            key: context.header.id,
            className: 'text-end',
          }
        },
      }
    });

export const containerWeightColumn = (columnHelper: ColumnHelper<CustomerProductSearchResponseItem>) =>
    columnHelper.accessor("containerWeightGrams",
        {
          header: () => <span><YlbIntlLabel id="CustomerProductSearch.containerWeight"
                                            doCapitalize={true}/></span>,
          cell: (context) =>
              <>{numberMillisToString(context.getValue(),
                  ".",
                  true)}&nbsp;kg</>,
          meta: {
            getCellContext: (context) => {
              return {
                key: context.cell.id,
                className: 'text-end',
              }
            },
            getHeaderContext: (context) => {
              return {
                key: context.header.id,
                className: 'text-end',
              }
            },
          }
        });

export const priceKgColumn = (columnHelper: ColumnHelper<CustomerProductSearchResponseItem>) =>
    columnHelper.accessor("priceKg", {
      header: () => <span><YlbIntlLabel id="CustomerProductSearch.priceKg"
                                        doCapitalize={true}/></span>,
      cell: (context) => <>{numberCentsToString(context.getValue())}
        &nbsp;{context.row.original.currency}</>,
      meta: {
        getCellContext: (context) => {
          return {
            key: context.cell.id,
            className: 'text-end',
          }
        },
        getHeaderContext: (context) => {
          return {
            key: context.header.id,
            className: 'text-end',
          }
        },
      }
    });

export const pricePerBoxColumn = (columnHelper: ColumnHelper<CustomerProductSearchResponseItem>) =>
    columnHelper.display({
      id: 'pricePerBox',
      header: () => <span><YlbIntlLabel id="CustomerProductSearch.pricePerBox"
                                        doCapitalize={true}/></span>,
      cell: (context) =>
          <>{bigDecimalCentsToString(
              new bigDecimal(context.row.original.packingWeightGrams)
              .multiply(new bigDecimal(context.row.original.packingPerBox))
              .multiply(new bigDecimal(context.row.original.priceKg))
              .divide(new bigDecimal(1000)))}
            &nbsp;{context.row.original.currency}
          </>,
      meta: {
        getCellContext: (context) => {
          return {
            key: context.cell.id,
            className: 'text-end',
          }
        },
        getHeaderContext: (context) => {
          return {
            key: context.header.id,
            className: 'text-end',
          }
        },
      }
    });

export const unitPriceColumn = (columnHelper: ColumnHelper<CustomerProductSearchResponseItem>) =>
    columnHelper.display({
      id: 'unitPrice',
      header: () => <span><YlbIntlLabel id="CustomerProductSearch.unitPrice"
                                        doCapitalize={true}/></span>,
      cell: (context) => context.row.original.packingPerBox === 1 ? <>--</>
          : <>{bigDecimalCentsToString(
              new bigDecimal(context.row.original.priceKg)
              .multiply(new bigDecimal(context.row.original.packingWeightGrams))
              .divide(new bigDecimal(1000)))}
            &nbsp;{context.row.original.currency}
          </>,
      meta: {
        getCellContext: (context) => {
          return {
            key: context.cell.id,
            className: 'text-end',
          }
        },
        getHeaderContext: (context) => {
          return {
            key: context.header.id,
            className: 'text-end',
          }
        },
      }
    });

export const incotermColumn = (columnHelper: ColumnHelper<CustomerProductSearchResponseItem>) =>
    columnHelper.accessor("incoterm", {
      header: () => <span>Incoterm</span>,
      cell: (context) => context.getValue(),
    });