import {Navigate, Outlet, Route, Routes} from "react-router-dom";
import {CommonOrderDetailsContainersProduct} from "./CommonOrderDetailsContainersProduct";
import {CommonOrderDetailsContainers} from "./CommonOrderDetailsContainers";
import {CommonOrderDetailsContainersTimeline} from "./CommonOrderDetailsContainersTimeline";
import {CommonOrderDetailsContainersDocuments} from "./CommonOrderDetailsContainersDocuments";

const CommonOrderDetailsContainerRoutes: React.FC = () => {
  return (
      <Routes>
        <Route
            element={
              <>
                <CommonOrderDetailsContainers>
                  <Outlet/>
                </CommonOrderDetailsContainers>
              </>
            }
        >
          <Route
              path='product'
              element={
                <>
                  <div className="row">
                    <div className="col-4">
                      <CommonOrderDetailsContainersTimeline/>
                    </div>
                    <div className="col-5">
                      <CommonOrderDetailsContainersProduct/>
                    </div>
                    <div className="col-3">
                      <CommonOrderDetailsContainersDocuments/>
                    </div>
                  </div>
                </>
              }
          />
          <Route index element={<Navigate to='product'/>}/>
        </Route>
      </Routes>
  )
}

export default CommonOrderDetailsContainerRoutes