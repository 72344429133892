import {KTCard, KTCardBody} from "../../../../../_metronic/helpers";
import React, {useContext, useEffect} from "react";
import {
  PriceFilterType,
  productHierarchy,
  ProductMetaType,
  ProductType
} from "../../../../../_ylb/model/BackendReferential";
import {backendApiRequest} from "../../../../../_ylb/api/YBackendApiClient";
import {customerProductSearchEndpoint} from "../../../../../_ylb/api/BackendEndpoints";
import {CountryDisplay} from "../../../../../_ylb/helpers/CountryDisplay";
import YForm from "../../../../../_ylb/form/YForm";
import {toast} from "react-toastify";
import {YSelectReferential} from "../../../../../_ylb/form/YSelectReferential";
import {defaultCountryCode, YSelectCountries} from "../../../../../_ylb/form/YSelectCountries";
import {useFormikContext} from "formik";
import {
  CustomerProductSearchContext,
  CustomerProductSearchResponse,
  CustomerProductSearchResponseDto,
  CustomerProductSearchResponseItem,
  enrichSearchProduct,
} from "./CustomerProductSearchContext";
import _ from "lodash";
import {YSelectObjectBase} from "../../../../../_ylb/form/YSelectObjectBase";
import {useIntl} from "react-intl";
import {createColumnHelper} from "@tanstack/react-table";
import {YTable} from "../../../../../_ylb/table/YTable";
import {
  boxWeightColumn,
  caliberColumn,
  categoryColumn,
  incotermColumn,
  originColumn,
  packingColumn,
  priceKgColumn,
  pricePerBoxColumn,
  productTypeColumn,
  unitPriceColumn
} from "./CustomerProductSearchColumns";
import {useLocation, useNavigate} from "react-router-dom";
import {YlbIntlLabel} from "../../../../../_ylb/i18n/YlbIntlLabel";
import {capitalize} from "../../../../../_ylb/helpers/StringHelpers";

type searchFilterItem = {
  type: PriceFilterType
  value: string | number
}

type CustomerProductSearchRequest = {
  filters: string[]
}

type SearchFormParameters = {
  departure: string,
  family: string,
  metaType: ProductMetaType,
  origin: string,
  prov: string,
  type: ProductType
}

const defaultProviderToken = "XX";

const searchFormInitialValues: SearchFormParameters = {
  departure: defaultCountryCode,
  family: "0",
  metaType: "UKN",
  origin: defaultCountryCode,
  prov: defaultProviderToken,
  type: "UKN"
}

const computeSearchFilterRestrictions = (products: CustomerProductSearchResponseItem[]) => {
  const restrictions = {
    familyKeepOnly: _.chain(products).map(product => "" + productHierarchy[product.type].family).concat("0").uniq().value(),
    metaTypeKeepOnly: _.chain(products).map(product => productHierarchy[product.type].metaType).concat("UKN").uniq().value(),
    typeKeepOnly: _.chain(products).map(product => product.type).concat("UKN").uniq().value(),
    departureKeepOnly: _.chain(products).map(product => product.departure as string).concat(defaultCountryCode).uniq().value(),
    originKeepOnly: _.chain(products).map(product => product.origin as string).concat(defaultCountryCode).uniq().value(),
    providers: _.chain(products).uniqBy(product => product.providerToken)
    .map(product => {
      return {
        name: product.providerName,
        token: product.providerToken
      }
    })
    .sortBy(provider => provider.name)
    .value()
  }
  return restrictions;
}

const SearchParameterHandler = () => {
  const {values} = useFormikContext<SearchFormParameters>();
  const {
    productSearchResultsBase,
    setProductSearchResultsBase,
    setProductSearchResultsFiltered,
    setSearchFilterRestrictions
  } = useContext(CustomerProductSearchContext);
  const intl = useIntl();

  const getFilteredCacheResults = (searchQuery: searchFilterItem[]) => {
    if (productSearchResultsBase.isFetched) {
      let products = productSearchResultsBase.data!.products;
      searchQuery.forEach(filter => {
        switch (filter.type) {
          case "DEPARTURE":
            products = products.filter(product =>
                product.departure == filter.value);
            break;
          case "FAMILY":
            products = products.filter(product =>
                productHierarchy[product.type].family == filter.value);
            break;
          case "METATYPE":
            products = products.filter(product =>
                productHierarchy[product.type].metaType == filter.value);
            break;
          case "TYPE":
            products = products.filter(product =>
                product.type == filter.value);
            break;
          case "ORIGIN":
            products = products.filter(product =>
                product.origin == filter.value);
            break;
          case "PROV":
            products = products.filter(product =>
                product.providerToken == filter.value);
            break;
        }
      });
      return products;
    } else {
      return [];
    }
  }

  // filter order is important for frontend cache filtering, that should also mirror backend filtering
  let searchQuery: searchFilterItem[] = [];
  if (values.departure !== defaultCountryCode) {
    searchQuery.push({type: "DEPARTURE", value: values.departure});
  }
  if (Number(values.family) !== 0) {
    searchQuery.push({type: "FAMILY", value: values.family});
  }
  if (values.metaType !== "UKN") {
    searchQuery.push({type: "METATYPE", value: values.metaType});
  }
  if (values.origin !== defaultCountryCode) {
    searchQuery.push({type: "ORIGIN", value: values.origin});
  }
  if (values.prov !== defaultProviderToken) {
    searchQuery.push({type: "PROV", value: values.prov});
  }
  if (values.type !== "UKN") {
    searchQuery.push({type: "TYPE", value: values.type});
  }

  const useDataCache = true;
  useEffect(() => {
    if (!useDataCache || !productSearchResultsBase.isFetched) {
      backendApiRequest<CustomerProductSearchResponseDto, CustomerProductSearchRequest>({
        method: "POST",
        data: {
          filters: searchQuery.map(searchItem => searchItem.type + "_" + searchItem.value)
        },
        url: customerProductSearchEndpoint,
        onSuccess: (response) => {
          let customerProductSearchResponse: CustomerProductSearchResponse = {
            ...response.data.data!,
            products: _.chain(response.data.data!.products.map(dto =>
                enrichSearchProduct(dto, intl)))
            .sortBy('productDescription')
            .value()
          }
          setProductSearchResultsBase({
            data: customerProductSearchResponse,
            isFetched: true
          });
          setProductSearchResultsFiltered(customerProductSearchResponse.products);
          setSearchFilterRestrictions(
              computeSearchFilterRestrictions(customerProductSearchResponse.products));
        }
      });
    } else {
      const filteredResults = getFilteredCacheResults(searchQuery);
      setProductSearchResultsFiltered(filteredResults);
      setSearchFilterRestrictions(computeSearchFilterRestrictions(filteredResults));
    }
  }, [values]);
  return null;
};

export const CustomerProductSearchControls = () => {
  const intl = useIntl();
  const {
    searchFilterRestrictions
  } = useContext(CustomerProductSearchContext)

  return (
      <div className="row">
        <YSelectReferential name="family"
                            referential="ProductFamily"
                            topDefaultOptionValue="0"
                            keepOnly={searchFilterRestrictions.familyKeepOnly}
                            label="CustomerProductSearch.productFamily"
                            containerClass="col-2"/>
        <YSelectReferential name="metaType"
                            referential="ProductMetaType"
                            topDefaultOptionValue="UKN"
                            keepOnly={searchFilterRestrictions.metaTypeKeepOnly}
                            label="CustomerProductSearch.productMetaType"
                            containerClass="col-2"/>
        <YSelectReferential name="type"
                            referential="ProductType"
                            topDefaultOptionValue="UKN"
                            keepOnly={searchFilterRestrictions.typeKeepOnly}
                            label="CustomerProductSearch.productType"
                            containerClass="col-2"/>
        <YSelectCountries name="origin"
                          label="CustomerProductSearch.originCountry"
                          additionalOptions={[
                            {
                              value: defaultCountryCode,
                              label: "- " + capitalize(
                                  intl.formatMessage({
                                    id: "CommonLabels.all"
                                  })) + " -"
                            }
                          ]}
                          atFirst={[defaultCountryCode]}
                          keepOnly={searchFilterRestrictions.originKeepOnly.concat(["XX"])}
                          containerClass="col-2"/>
        <YSelectCountries name="departure"
                          label="CustomerProductSearch.expeditionCountry"
                          additionalOptions={[
                            {
                              value: defaultCountryCode,
                              label: "- " + capitalize(
                                  intl.formatMessage({
                                    id: "CommonLabels.all"
                                  })) + " -"
                            }
                          ]}
                          atFirst={[defaultCountryCode]}
                          keepOnly={searchFilterRestrictions.departureKeepOnly.concat(["XX"])}
                          containerClass="col-2"/>
        <YSelectObjectBase name="prov"
                           label="CustomerProductSearch.provider"
                           advancedMode={true}
                           options={[
                             {
                               value: defaultProviderToken,
                               label: "- " + intl.formatMessage({
                                 id: "CommonLabels.all",
                                 defaultMessage: "Select provider"
                               }) + " -"
                             }].concat(
                               searchFilterRestrictions.providers.map(provider => {
                                 return {
                                   value: provider.token,
                                   label: provider.name
                                 }
                               }))}
                           containerClass="col-2"/>
      </div>
  )
}


/**
 * That component is complex enough, let's not set results to NotYetFetched as it's signalling that
 * cache is still in initial status.
 */
export const CustomerProductSearch = () => {
  const {
    productSearchResultsBase,
    productSearchResultsFiltered,
    addProductToCheckout,
  } = useContext(CustomerProductSearchContext);
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();

  const onCheckoutClick = (priceToken: string) => {
    const cart = addProductToCheckout(priceToken, 0);
    if (cart) {
      navigate(location.pathname.replace("/customer/product", "/customer/cart"));
    }
  };

  const columnHelper = createColumnHelper<CustomerProductSearchResponseItem>();
  const getColumns = (providerName: string) => [
    productTypeColumn(columnHelper),
    caliberColumn(columnHelper),
    categoryColumn(columnHelper, intl),
    packingColumn(columnHelper, intl),
    originColumn(columnHelper, intl),
    boxWeightColumn(columnHelper),
    priceKgColumn(columnHelper),
    pricePerBoxColumn(columnHelper),
    unitPriceColumn(columnHelper),
    incotermColumn(columnHelper),
    columnHelper.display({
      id: 'actions',
      header: () => <span><YlbIntlLabel id="CustomerProductSearch.actions"
                                        doCapitalize={true}/></span>,
      cell: context => <>
        <button type="button"
                className="btn btn-primary btn-sm"
                onClick={() => onCheckoutClick(context.row.original.priceToken)}>
          <i className="fas fa-shop btn-link fs-4 me-2"></i>&nbsp;
          <YlbIntlLabel id="CustomerProductSearch.buy" doCapitalize={true}/>
        </button>
      </>,
    }),
  ];

  return (
      <>
        <div className="row">
          <div className="col-12">
            <KTCard className="mb-5">
              <div className="card-header border-0 pt-5 pb-3">
                <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-dark">
                  <YlbIntlLabel id="CustomerProductSearch.title" doCapitalize={true}/>
                  {
                      productSearchResultsBase.isFetched && <>
                        &nbsp;<YlbIntlLabel id="CustomerProductSearch.titleTo"/>&nbsp;<CountryDisplay
                          country={productSearchResultsBase.data!.destinationCountry}/>
                      </>
                  }
                </span>
                </h3>
              </div>
              <KTCardBody className="border-top">
                <YForm initialValues={{...searchFormInitialValues}} onSubmit={() => {
                  toast.success("searched.")
                }}>
                  <SearchParameterHandler/>{
                    productSearchResultsBase.isFetched && <CustomerProductSearchControls/>
                }
                </YForm>
              </KTCardBody>
            </KTCard>
          </div>
        </div>
        {productSearchResultsBase.isFetched ? <>{
              productSearchResultsFiltered.length > 0 ?
                  _.chain(productSearchResultsFiltered)
                  .groupBy('providerToken')
                  .map((value, key) => value)
                  .value().map(products =>
                      <div className="row"
                           key={products[0].providerToken}
                      >
                        <div className="col-12">
                          <KTCard className="mb-5">
                            <KTCardBody className="py-1">
                              <div
                                  className="px-2 pt-2 fw-bolder">Magasin: {products[0].providerName}</div>
                              <YTable data={products} columns={getColumns(products[0].providerName)}/>
                            </KTCardBody>
                          </KTCard>
                        </div>
                      </div>
                  )
                  : <div className="row">
                    <div className="col-12">
                      <KTCard className="mb-5">
                        <KTCardBody className="py-1">
                          <span className="text-end">
                              <YlbIntlLabel id="CustomerProductSearchLabel.noProducts"
                                            doCapitalize={true}/>
                            </span>
                        </KTCardBody>
                      </KTCard>
                    </div>
                  </div>
            }</>
            : <div className="row">
              <div className="col-12">
                <KTCard className="mb-5">
                  <KTCardBody className="py-1">
                    <span className="text-end">
                      <YlbIntlLabel id="CommonLabels.loading" doCapitalize={true}/>...
                    </span>
                  </KTCardBody>
                </KTCard>
              </div>
            </div>
        }      </>
  )
}