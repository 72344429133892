import {OrderContents} from "../../customer/checkout/OrderContents";
import React from "react";
import {useOrderWrapper} from "./CommonOrderDetailsContext";

export const CommonOrderDetailsBilling = () => {
  const orderWrapper = useOrderWrapper();
  const orderDetails = orderWrapper.order;

  return (<>
        <div className="d-flex flex-column gap-7 gap-lg-10">
          <div className="card card-flush py-4 flex-row-fluid overflow-hidden">
            <div className="card-body pt-0">
              <OrderContents products={orderDetails.products}
                             currency={orderDetails.currency}
                             totalPriceNoTax={orderDetails.totalPriceNoTax}
                             totalPriceWithTax={orderDetails.totalPriceWithTax}/>
            </div>
          </div>
        </div>
      </>
  )
}