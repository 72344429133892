import {CountryFlag} from "../../../../../../_ylb/helpers/CountryFlag";
import {KTCard} from "../../../../../../_metronic/helpers";
import React from "react";
import {useAdminProviderRecap} from "./AdminProviderIdentifiedContext";
import {numberCentsToString} from "../../../../../../_ylb/helpers/numerics";

export const ProvideRecapHeader = () => {
  const adminProviderRecap = useAdminProviderRecap();

  return (
      <div className="row">
        <div className="col-12">
          <KTCard className="mb-5">
            <div className="card-header border-0 pt-5 pb-3">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-dark">
                  Provider: {adminProviderRecap.provider.name}
                  &nbsp;<CountryFlag country={adminProviderRecap.provider.country}/>
                </span>
                <span className="text-muted mt-1 fw-semibold fs-7">
                  Display name: {adminProviderRecap.provider.displayName}
                </span>
                <span className="text-muted mt-1 fw-semibold fs-7">
                  Margin on all products: {numberCentsToString(adminProviderRecap.provider.marginPercentCents)}%
                </span>
              </h3>
            </div>
          </KTCard>
        </div>
      </div>
  )
}