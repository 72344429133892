import React, {useEffect, useState} from "react";
import {KTCard, KTCardBody, KTSVG} from "../../../../../../../_metronic/helpers";
import {
  ConsumableProductCategory,
  Country,
  Currency,
  Incoterm,
  ProductPacking,
  ProductStatus,
  ProductType
} from "../../../../../../../_ylb/model/BackendReferential";
import {
  adminProviderDeleteProviderProductEndpoint,
  adminProviderListProviderProductsEndpoint,
  urlEnrich
} from "../../../../../../../_ylb/api/BackendEndpoints";
import {Link, useNavigate, useParams} from "react-router-dom";
import {CountryDisplay} from "../../../../../../../_ylb/helpers/CountryDisplay";
import {
  numberCentsToString,
  numberMillisToString
} from "../../../../../../../_ylb/helpers/numerics";
import {ProvideRecapHeader} from "../ProviderRecapHeader";
import {backendApiRequest} from "../../../../../../../_ylb/api/YBackendApiClient";
import {
  caliberDisplay,
  productDescription
} from "../../../../../../../_ylb/helpers/ProductDescription";
import {useIntl} from "react-intl";
import {NotYetFetched, YFetched} from "../../../../../../../_ylb/api/YFetched";
import {toast} from "react-toastify";
import {YTable} from "../../../../../../../_ylb/table/YTable";
import {intlReferential, YlbIntlLabel} from "../../../../../../../_ylb/i18n/YlbIntlLabel";
import {createColumnHelper} from "@tanstack/react-table";
import {allowedIncotermForCustomPrices} from "./AdminProviderProductUpdate";

type ProviderProductList = {
  products: ProviderProductListItem[]
}

type ProviderProductIncotermPrice = {
  country: Country
  incotermPricePerKgCents: number
  incotermPricePerBoxCents: number
}

type ProviderProductCustomerPrice = {
  country: Country
  currency: Currency
  pricePerKgCents: number
  pricePerBoxCents: number
}

type ProviderProductListItem = {
  token: string
  status: ProductStatus
  origin: Country
  incoterm: Incoterm
  type: ProductType
  category: ConsumableProductCategory
  caliber: string
  boxesPerPallet: number
  currency: Currency
  fobPricePerKgInCents: number
  fobPricePerBoxInCents: number
  incotermPrices: ProviderProductIncotermPrice[]
  customerPrices: ProviderProductCustomerPrice[]
  packing: ProductPacking
  packingWeightGrams: number
  packingPerBox: number
  expiresAt: Date
}

export const AdminProviderProductList = () => {
  const [productList, setProductList] = useState<YFetched<ProviderProductList>>(NotYetFetched);
  const {providerToken} = useParams();
  const navigate = useNavigate();
  const intl = useIntl();

  const deleteProduct = (productToken: string) => {
    backendApiRequest<ProviderProductList>({
      method: "DELETE",
      url: urlEnrich({
        url: adminProviderDeleteProviderProductEndpoint,
        providerToken: providerToken,
        productToken: productToken
      }),
      onSuccess: (res) => {
        toast.success("Product successfully deleted.");
        loadProducts();
      }
    });
  }

  const loadProducts = () => {
    backendApiRequest<ProviderProductList>({
      url: urlEnrich({
        url: adminProviderListProviderProductsEndpoint,
        providerToken: providerToken
      }),
      onSuccess: (res) => setProductList({
        data: res.data.data!,
        isFetched: true
      })
    });
  }


  useEffect(() => loadProducts(), []);

  const columnHelper = createColumnHelper<ProviderProductListItem>();
  const getColumns = () => [
    columnHelper.accessor("type",
        {
          header: () => <span>
                  <YlbIntlLabel id="CustomerProductSearch.productType"
                                doCapitalize={true}/>
              </span>,
          cell: (context) => productDescription(
              intl,
              context.getValue()),
          meta:
              {
                getCellContext: (context) => {
                  return {
                    key: context.cell.id,
                    className: 'text-start',
                  }
                },
                getHeaderContext:
                    (context) => {
                      return {
                        key: context.header.id,
                        className: 'text-start',
                      }
                    },
              }
        }),
    columnHelper.accessor("caliber",
        {
          header: () => <span>
                <YlbIntlLabel id="CustomerProductSearch.caliber" doCapitalize={true}/>
            </span>,
          cell: (context) => caliberDisplay(context.getValue()),
          meta:
              {
                getCellContext: (context) => {
                  return {
                    key: context.cell.id,
                    className: 'text-start',
                  }
                },
                getHeaderContext:
                    (context) => {
                      return {
                        key: context.header.id,
                        className: 'text-start',
                      }
                    },
              }
        }),
    columnHelper.accessor("category",
        {
          header: () => <span>
                <YlbIntlLabel id="CustomerProductSearch.category" doCapitalize={true}/>
            </span>,
          cell: (context) => intlReferential({
            referential: "ConsumableProductCategory",
            id: context.getValue(),
            doCapitalize: true,
            intl: intl
          }),
          meta:
              {
                getCellContext: (context) => {
                  return {
                    key: context.cell.id,
                    className: 'text-start',
                  }
                },
                getHeaderContext:
                    (context) => {
                      return {
                        key: context.header.id,
                        className: 'text-start',
                      }
                    },
              }
        }),
    columnHelper.accessor("packing",
        {
          header: () => <span>
                <YlbIntlLabel id="CustomerProductSearch.packing" doCapitalize={true}/>
            </span>,
          cell: (context) => (context.getValue() !== 'UKN' ? intlReferential({
            referential: "ProductPacking",
            id: context.getValue(),
            doCapitalize: true,
            intl: intl
          }) + " " : "") + numberMillisToString(context.row.original.packingWeightGrams!, ".", true) + "kg",
          meta:
              {
                getCellContext: (context) => {
                  return {
                    key: context.cell.id,
                    className: 'text-start',
                  }
                },
                getHeaderContext:
                    (context) => {
                      return {
                        key: context.header.id,
                        className: 'text-start',
                      }
                    },
              }
        }),
    columnHelper.accessor((originalRow) =>
            intlReferential({referential: "country", id: originalRow.origin, intl: intl}),
        {
          id: "origin",
          header: () => <span><YlbIntlLabel id="CustomerProductSearch.originCountryShort"
                                            doCapitalize={true}/></span>,
          cell: (context) =>
              <CountryDisplay country={context.row.original.origin}/>,
          meta: {
            getCellContext: (context) => {
              return {
                key: context.cell.id,
                className: 'text-start',
              }
            },
            getHeaderContext: (context) => {
              return {
                key: context.header.id,
                className: 'text-start',
              }
            },
          }
        }),
    columnHelper.display({
      id: 'boxWeight',
      header: () => <span><YlbIntlLabel id="CustomerProductSearch.boxWeight"
                                        doCapitalize={true}/></span>,
      cell: (context) =>
          <>{numberMillisToString(context.row.original.packingWeightGrams
              * context.row.original.packingPerBox, ".", true)}&nbsp;kg</>,
      meta: {
        getCellContext: (context) => {
          return {
            key: context.cell.id,
            className: 'text-end',
          }
        },
        getHeaderContext: (context) => {
          return {
            key: context.header.id,
            className: 'text-end',
          }
        },
      }
    }),
    columnHelper.accessor("boxesPerPallet", {
      header: () => <span><YlbIntlLabel id="CustomerProductSearch.boxPerPallet"
                                        doCapitalize={true}/></span>,
      cell: (context) => context.getValue(),
      meta: {
        getCellContext: (context) => {
          return {
            key: context.cell.id,
            className: 'text-end',
          }
        },
        getHeaderContext: (context) => {
          return {
            key: context.header.id,
            className: 'text-end',
          }
        },
      }
    }),
    columnHelper.display({
      id: 'pricePerKg',
      header: () => <span>Price / kg</span>,
      cell: context => <>
        {!allowedIncotermForCustomPrices.includes(context.row.original.incoterm) && <>{numberCentsToString(context.row.original.fobPricePerKgInCents)} {context.row.original.currency}</>}
        {allowedIncotermForCustomPrices.includes(context.row.original.incoterm) && <>{context.row.original.incotermPrices.map(incotermPrice =>
            <div key={incotermPrice.country}>
              <CountryDisplay showLabel={false}
                              country={incotermPrice.country}/>&nbsp;
              {numberCentsToString(incotermPrice.incotermPricePerKgCents)}&nbsp;{context.row.original.currency}
            </div>)}
        </>}
      </>,
      meta: {
        getCellContext: (context) => {
          return {
            key: context.cell.id,
            className: 'text-end',
          }
        },
        getHeaderContext: (context) => {
          return {
            key: context.header.id,
            className: 'text-end',
          }
        },
      }
    }),
    columnHelper.display({
      id: 'pricePerBox',
      header: () => <span>Price / box</span>,
      cell: context => <>
        {!allowedIncotermForCustomPrices.includes(context.row.original.incoterm) && <>{numberCentsToString(context.row.original.fobPricePerBoxInCents)} {context.row.original.currency}</>}
        {allowedIncotermForCustomPrices.includes(context.row.original.incoterm) && <>{context.row.original.incotermPrices.map(incotermPrice =>
            <div key={incotermPrice.country}>
              <CountryDisplay showLabel={false}
                              country={incotermPrice.country}/>&nbsp;
              {numberCentsToString(incotermPrice.incotermPricePerBoxCents)}&nbsp;{context.row.original.currency}
            </div>)}
        </>}
      </>,
      meta: {
        getCellContext: (context) => {
          return {
            key: context.cell.id,
            className: 'text-end',
          }
        },
        getHeaderContext: (context) => {
          return {
            key: context.header.id,
            className: 'text-end',
          }
        },
      }
    }),
    columnHelper.display({
      id: 'customerPricePerKg',
      header: () => <span>Cust. pr. / kg</span>,
      cell: context => <>
        {context.row.original.customerPrices.map(customerPrice =>
            <div key={customerPrice.country}>
              <CountryDisplay showLabel={false}
                              country={customerPrice.country}/>&nbsp;
              {numberCentsToString(customerPrice.pricePerKgCents)}&nbsp;{customerPrice.currency}
            </div>)}
      </>,
      meta: {
        getCellContext: (context) => {
          return {
            key: context.cell.id,
            className: 'text-end',
          }
        },
        getHeaderContext: (context) => {
          return {
            key: context.header.id,
            className: 'text-end',
          }
        },
      }
    }),
    columnHelper.display({
      id: 'customerPricePerBox',
      header: () => <span>Cust. pr. / box</span>,
      cell: context => <>
        {context.row.original.customerPrices.map(customerPrice =>
            <div key={customerPrice.country}>
              <CountryDisplay showLabel={false}
                              country={customerPrice.country}/>&nbsp;
              {numberCentsToString(customerPrice.pricePerBoxCents)}&nbsp;{customerPrice.currency}
            </div>)}
      </>,
      meta: {
        getCellContext: (context) => {
          return {
            key: context.cell.id,
            className: 'text-end',
          }
        },
        getHeaderContext: (context) => {
          return {
            key: context.header.id,
            className: 'text-end',
          }
        },
      }
    }),
    columnHelper.accessor("incoterm", {
      header: () => <span>Incoterm</span>,
      cell: (context) => context.getValue(),
    }),
    columnHelper.accessor("expiresAt", {
      header: () => <span>Expires at</span>,
      cell: (context) => context.getValue(),
    }),
    columnHelper.display({
      id: 'actions',
      header: () => <span><YlbIntlLabel id="CustomerProductSearch.actions"
                                        doCapitalize={true}/></span>,
      cell: context => <div className="d-flex align-items-center">
        <Link to={`${context.row.original.token}/edit`}>
          <i
              className='fas fa-regular fa-pen-to-square ms-2 fs-3'
              data-bs-toggle='tooltip'
              title='Edit product'
          ></i>
        </Link>

        <button
            type="button"
            className="btn btn-icon btn-active-light-primary w-30px h-30px"
            onClick={() => {
              if (window.confirm("Do you confirm product deletion ?")) {
                deleteProduct(context.row.original.token);
              }
            }}>
          <KTSVG
              path='/media/icons/duotune/general/gen034.svg'
              className='svg-icon svg-icon-1 svg-icon-danger'
          />
        </button>
      </div>,
    }),
  ];

  return (<>
    <ProvideRecapHeader/>
    <KTCard>
      <KTCardBody className='py-4'>
        {productList.isFetched ? <>
              <div className="row">
                <div className="col-12">
                  {productList.data!.products.length > 0 ?
                      <YTable data={productList.data!.products} columns={getColumns()}/>
                      : <>
                        No product found.
                        <br/>
                      </>}
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-12">
                  <button className="btn btn-primary btn-sm" onClick={() => {
                    navigate("../newproduct");
                  }}>
                    <i className="las la-plus-circle fs-4 me-2"></i> New product
                  </button>
                </div>
              </div>
            </>
            : <>
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>&nbsp;
              Loading...
            </>}
      </KTCardBody>
    </KTCard>
  </>)
}