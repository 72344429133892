import {toast} from "react-toastify";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import React from "react";
import {Address} from "../../../../../_ylb/model/Address";
import YForm from "../../../../../_ylb/form/YForm";
import {backendApiRequest} from "../../../../../_ylb/api/YBackendApiClient";
import {adminCustomerUpdateEndpoint} from "../../../../../_ylb/api/BackendEndpoints";
import {getClosestUri} from "../../../../../_ylb/routing/YRoutingHelper";
import {KTCard, KTCardBody} from "../../../../../_metronic/helpers";
import YInput from "../../../../../_ylb/form/YInput";
import {YInputGroup} from "../../../../../_ylb/form/YInputGroup";
import {YSelectCountries} from "../../../../../_ylb/form/YSelectCountries";
import {handleFormErrors} from "../../../../../_ylb/form/_form_helpers";

const customerInitialValues: CustomerData = {
  token: "",
  name: "",
  preDeliveryPaymentPercent: 0,
  registrationNumber: "",
  billingAddress: {
    address1: "",
    zipcode: "",
    city: "",
    state: "",
    country: "AF"
  }
}

export type CustomerData = {
  token: string
  name: string
  preDeliveryPaymentPercent: number
  registrationNumber: string
  billingAddress: Address
}

export const AdminCustomerUpdate = ({customerData}: {
  customerData?: CustomerData
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {customerToken} = useParams();

  let isExistingCustomer = false;
  if (customerData) {
    isExistingCustomer = true;
  }
  const customerProduct = customerData ?? customerInitialValues;

  return (
      <>
        <YForm initialValues={customerProduct}
               onSubmit={(values, actions) => {
                 let payload = {
                   ...values,
                 };

                 backendApiRequest<string>({
                   url: adminCustomerUpdateEndpoint,
                   method: "POST",
                   data: payload,
                   onSuccess: response => {
                     actions.setSubmitting(false);
                     toast.success("Customer successfully saved.");
                     navigate(getClosestUri(location.pathname, "/customer"));
                   },
                   onError: error => {
                     handleFormErrors(error.response?.data.payload!, values, actions);
                     actions.setSubmitting(false);
                   }
                 });
               }}>
          <div className="row">
            <div className="col-6">
              <KTCard>
                <div className="card-header border-0 pt-5">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold text-dark">
                      {isExistingCustomer ? "Editing customer: " + customerToken : "New customer"}
                    </span>
                  </h3>
                </div>
                <KTCardBody>
                  <div className="row pb-5">
                    <YInput label="Name"
                            name="name"
                            containerClass="col-6"
                            required={true}/>
                    <YInput label="Registration number"
                            name="registrationNumber"
                            containerClass="col-6"
                            required={true}/>
                  </div>
                  <div className="row pb-5">
                    <YInputGroup
                        rightLabel="%"
                        label="Pre-percent percentage"
                        name="preDeliveryPaymentPercent"
                        type="text"
                        containerClass="col-md-6"
                        className="text-end"
                        required={true}/>
                  </div>
                  <div className="row pb-5">
                    <h2>Billing address</h2>
                  </div>
                  <div className="row pb-5">
                    <YInput label="Address (street & number)"
                            name="billingAddress.address1"
                            required={true}/>
                  </div>
                  <div className="row pb-5">
                    <YInput label="Address (more)"
                            name="billingAddress.address2"/>
                  </div>
                  <div className="row pb-5">
                    <YInput label="Zipcode"
                            name="billingAddress.zipcode"
                            containerClass="col-4"
                            required={true}/>
                    <YInput label="City"
                            name="billingAddress.city"
                            containerClass="col-8"
                            required={true}/>
                  </div>
                  <div className="row pb-5">
                    <YInput label="State"
                            name="billingAddress.state"
                            containerClass="col-6"/>
                    <YSelectCountries label="Country"
                                      name="billingAddress.country"
                                      containerClass="col-6"
                                      required={true}
                                      disabled={isExistingCustomer}
                                      keepOnly={["BB", "CL", "CO", "CR", "GP", "HN", "MT", "MQ", "MX", "PE", "TT"]}/>
                  </div>
                </KTCardBody>
              </KTCard>
              <div className="d-flex justify-content-end mt-10 mb-15">
                <button type="button" className="btn btn-light me-5"
                        onClick={() => navigate(-1)}>
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary"
                        data-kt-users-modal-action="submit">
                  <span className="indicator-label">
                    <i className='fas fa-floppy-disk ms-2 fs-5'></i> Save
                  </span>
                </button>
              </div>
            </div>
          </div>
        </YForm>
      </>
  )
}