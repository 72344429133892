import {RelativeTabLink} from "../../../../../_ylb/routing/RelativeTabLink";
import {YlbIntlLabel} from "../../../../../_ylb/i18n/YlbIntlLabel";
import React from "react";
import {useOrderWrapper} from "./CommonOrderDetailsContext";
import {CustomerOrderStatusBadge} from "../../customer/order/CustomerOrderStatusBadge";
import moment from "moment/moment";
import {isAdmin, useAuth} from "../../../auth";
import {AdminOrderStatusBadge} from "../../admin/order/AdminOrderStatusBadge";

export const CommonOrderDetailsHeader = () => {
  const orderWrapper = useOrderWrapper();
  const orderDetails = orderWrapper.order;
  const {currentUser} = useAuth()

  const isCurrentUserAdmin = isAdmin(currentUser!);

  return (
      <>
        <div className='card mb-5 mb-xl-10'>
          <div className='card-body pt-9 pb-0'>
            <div className="flex-grow-1">
              <div className="d-flex justify-content-between align-items-start flex-wrap">
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-2">
                    <div className="text-gray-900 text-hover-primary fs-2 fw-bold me-5">
                      <YlbIntlLabel id="CustomerOrderCreation.title"
                                    doCapitalize={true}/> #{orderDetails.token}
                    </div>
                    {isCurrentUserAdmin ? <AdminOrderStatusBadge status={orderDetails.status}/>
                        : <CustomerOrderStatusBadge status={orderDetails.status}/>}
                  </div>

                  <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 mt-2 pe-2">
                    <span className="d-flex align-items-center text-gray-500 me-5 mb-2">
                      <i className="ki-outline ki-geolocation fs-4 me-1"></i>
                      <YlbIntlLabel id="CustomerOrderCreation.shippingFrom"
                                    doCapitalize={true}/>:&nbsp;{orderDetails.shippingFrom}
                    </span>
                    <span className="d-flex align-items-center text-gray-500 me-5 mb-2">
                      <i className="ki-outline ki-geolocation fs-4 me-1"></i>
                      <YlbIntlLabel id="CustomerOrderCreation.shippingTo"
                                    doCapitalize={true}/>:&nbsp;{orderDetails.shippingTo}
                    </span>
                    <span className="d-flex align-items-center text-gray-500 me-5 mb-2">
                      <i className="ki-outline ki-profile-circle fs-4 me-1"></i>
                      <YlbIntlLabel id="CustomerOrderCreation.provider"
                                    doCapitalize={true}/>:&nbsp;
                      {isCurrentUserAdmin ? <> {orderDetails.providerName} (public name:
                            "{orderDetails.providerDisplayName}")</>
                          : <> {orderDetails.providerDisplayName}</>}
                    </span>
                  </div>
                </div>
                <div>
                  <span className="d-flex align-items-center text-gray-500 me-5 mb-2">
                      <YlbIntlLabel id="CustomerOrderDetails.headerOrderDate"
                                    doCapitalize={true}/>:
                    &nbsp;{moment(orderDetails.createdAt).format("DD/MM/yyyy")}
                  </span>
                </div>
              </div>
              <div className="separator"></div>
            </div>

            <div className='d-flex overflow-auto h-55px'>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                {isCurrentUserAdmin && <li className='nav-item'>
                  <RelativeTabLink uri='admin-overview'>
                    <YlbIntlLabel id="AdminOrderDetails.adminOverview" doCapitalize={true}/>
                  </RelativeTabLink>
                </li>}
                <li className='nav-item'>
                  <RelativeTabLink uri='overview'>
                    {isCurrentUserAdmin ?
                        <YlbIntlLabel id="AdminOrderDetails.overview" doCapitalize={true}/>
                        : <YlbIntlLabel id="CustomerOrderCreation.overview" doCapitalize={true}/>}
                  </RelativeTabLink>
                </li>
                <li className='nav-item'>
                  <RelativeTabLink uri='containers/0'>
                    <YlbIntlLabel id="CustomerOrderCreation.containers" doCapitalize={true}/>
                    &nbsp;({orderDetails.containers.length})
                  </RelativeTabLink>
                </li>
                <li className='nav-item'>
                  <RelativeTabLink uri='billing'>
                    <YlbIntlLabel id="CustomerOrderCreation.billing" doCapitalize={true}/>
                  </RelativeTabLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
  )
}