import {Link} from "react-router-dom";
import {PropsWithChildren} from "react";

export const RelativeTabLink = ({children, uri}: PropsWithChildren<{ uri: string }>) => {
  const canonicalUri = uri.split("/")[0];
  return <Link
      className={
          `nav-link text-active-primary me-6 ` +
          (window.location.pathname.indexOf('/' + canonicalUri) > 0 && 'active')
      }
      to={'../' + uri}
  >
    {children}
  </Link>
}