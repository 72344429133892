import {PropsWithChildren, useEffect, useState} from "react";
import {Country, Currency, Incoterm} from "../../../../../../_ylb/model/BackendReferential";
import {NotYetFetched, YFetched} from "../../../../../../_ylb/api/YFetched";
import {useParams} from "react-router-dom";
import {createStrictContext} from "../../../../../../_ylb/context/ContextHelper";
import {adminProviderRecapEndpoint, urlEnrich} from "../../../../../../_ylb/api/BackendEndpoints";
import {backendApiRequest} from "../../../../../../_ylb/api/YBackendApiClient";

type AdminProviderRecap = {
  provider: AdminProviderRecapProvider
}

type AdminProviderRecapProvider = {
  token: string
  name: string
  displayName: string
  country: Country
  currency: Currency
  palletPerContainer: number
  marginPercentCents: number
  incoterm: Incoterm
}

const [AdminProviderRecapProvider, useAdminProviderRecap] = createStrictContext<AdminProviderRecap>();

const AdminProviderIdentifiedContext = ({children}: PropsWithChildren<{}>) => {
  const [providerRecap, setProviderRecap] = useState<YFetched<AdminProviderRecap>>(NotYetFetched);
  const {providerToken} = useParams();

  useEffect(() => {
    backendApiRequest<AdminProviderRecap>({
      url: urlEnrich({
        url: adminProviderRecapEndpoint,
        providerToken: providerToken
      }),
      onSuccess: (res) => setProviderRecap({
        data: res.data.data!,
        isFetched: true
      })
    });
  }, []);

  return <>{
    providerRecap.isFetched ?
        <AdminProviderRecapProvider value={providerRecap.data!}>
          {children}
        </AdminProviderRecapProvider>
        : <></>
  }
  </>
}
export {AdminProviderIdentifiedContext, useAdminProviderRecap}
