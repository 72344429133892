import _ from "lodash";
import React from "react";
import {VerticalStepperStep} from "../../../../../_ylb/page/order/VerticalStepperStep";
import {OrderStatus} from "../../../../../_ylb/model/BackendReferential";
import {useOrderWrapper} from "./CommonOrderDetailsContext";
import {numberCentsToString} from "../../../../../_ylb/helpers/numerics";
import {YlbIntlLabel} from "../../../../../_ylb/i18n/YlbIntlLabel";
import {Link, useNavigate} from "react-router-dom";

export const allOrderStatusChronological: OrderStatus[] = [
  "CRE",
  "CAC",
  "PCR",
  "PAC",
  "PPG",
  "FDP",
  "FAD",
  "ALL",
  "COM",
  "CNC"
]

export const CustomerOrderDetailsOverview = () => {
  const orderWrapper = useOrderWrapper();
  const orderDetails = orderWrapper.order;
  const navigate = useNavigate();

  let customerViewStepOrder = allOrderStatusChronological.filter(step => step !== "CAC" && step !== "CNC");
  if (orderDetails.status !== "CRE") {
    customerViewStepOrder = customerViewStepOrder.filter(step => step !== "CRE");
  }
  if (orderDetails.preOrderPaymentPercent === 0) {
    customerViewStepOrder = customerViewStepOrder.filter(step => step !== "PAC");
  }

  const orderStatusForDisplay = orderDetails.status === "CAC" ? "PCR" : orderDetails.status;
  const currentStep = _.findIndex(customerViewStepOrder,
      step => step === orderStatusForDisplay) + 1;

  return (
      <div
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_create_account_stepper'>
        <div
            className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
          <div className='card-body px-6 px-lg-10 px-xxl-15 py-20'>
            <div className='stepper-nav'>
              {customerViewStepOrder.map((step, index) =>
                  <VerticalStepperStep key={step} stepNumber={index + 1}
                                       labelId={"CustomerOrderStatusStepper." + step}
                                       subLabelId={"CustomerOrderStatusStepper." + step + "_sub"}
                                       appendLine={index < (customerViewStepOrder.length - 1)}
                                       currentStepNumber={currentStep}/>
              )}
            </div>
          </div>
        </div>

        <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
          <div className='current'>
            {orderDetails.status === "CRE" &&
                <button type="button" className="btn btn-primary"
                        onClick={() => navigate("../billing")}>
                  <YlbIntlLabel id={`CustomerOrderStatusStepper.${orderDetails.status}_details1`}
                                doCapitalize={true}/>
                </button>}
            {orderDetails.status === "FDP" &&
                <Link to="../containers/0">
                  <YlbIntlLabel id="CustomerOrderDetails.goToContainerDetails"
                                doCapitalize={true}/>
                </Link>}
            {(orderDetails.status === "PCR"
                    || orderDetails.status === "PAC") &&
                <>
                  <div>
                    {orderDetails.status === "PCR" &&
                        <YlbIntlLabel
                            id={`CustomerOrderStatusStepper.${orderDetails.status}_details1`}
                            doCapitalize={true}/>}
                    {orderDetails.status === "PAC" &&
                        <YlbIntlLabel
                            id={`CustomerOrderStatusStepper.${orderDetails.status}_details1`}
                            doCapitalize={true}/>}
                    &nbsp;<YlbIntlLabel id="CustomerOrderStatusStepper.PAC_details2"/>
                    &nbsp;{numberCentsToString(orderDetails.unpaidPreOrderAmountCents)}&nbsp;{orderDetails.currency}
                  </div>
                  <div className="text-center pt-5">
                    <button type="button" className="btn btn-primary"
                            onClick={() => {
                            }}>
                      <YlbIntlLabel id="CustomerOrderStatusStepper.PAC_details3"
                                    doCapitalize={true}/>
                    </button>
                  </div>
                </>}
            {orderDetails.status === "PPG" &&
                <>
                  <YlbIntlLabel id={`CustomerOrderStatusStepper.${orderDetails.status}_details1`}
                                doCapitalize={true}/>.
                </>}
            {(orderDetails.status === "ALL"
                    || orderDetails.status === "FAD") &&
                <>
                  {orderDetails.unpaidTotalAmountCents > 0 ?
                      <>
                        <div>
                          <YlbIntlLabel id="CustomerOrderDetails.payRemaining1"
                                        doCapitalize={true}/>:
                          &nbsp;{numberCentsToString(orderDetails.unpaidTotalAmountCents)}&nbsp;{orderDetails.currency}
                        </div>
                        <div className="text-center pt-5">
                          <button type="button" className="btn btn-primary"
                                  onClick={() => {
                                  }}>
                            <YlbIntlLabel id="CustomerOrderStatusStepper.PAC_details3"
                                          doCapitalize={true}/>
                          </button>
                        </div>
                      </>
                      : <Link to="../containers/0">
                        <YlbIntlLabel id="CustomerOrderDetails.goToContainerDetails"
                                      doCapitalize={true}/>
                      </Link>}
                </>}
            {orderDetails.status === "COM" &&
                <>
                  <div
                      className='d-flex flex-column'
                      id='kt_create_account_stepper'>
                    <div className="text-center">
                      <i className='fas fa-regular fa-circle-check text-success ms-2 fs-5tx'></i>
                    </div>
                    <div className="pt-5">
                      <h2 className="fw-bolder text-center">
                        <YlbIntlLabel id="CustomerOrderStatusStepper.COM_details1"
                                      doCapitalize={true}/>&nbsp;!
                      </h2>
                    </div>
                    <div className="pt-5 text-center">
                      <YlbIntlLabel id="CustomerOrderStatusStepper.COM_details2"
                                    doCapitalize={true}/>.
                    </div>
                  </div>
                </>}
          </div>
        </div>
      </div>
  )
}