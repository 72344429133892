import _ from "lodash";
import {UserModel} from "../../app/modules/auth";

const customerBoatHubScopePrefix = "CUSTOMER_BOAT_HUB_";

export const getCustomerPreferredBoatHub = (user: UserModel) => {
  return _.chain(user.scopes)
  .filter(scope => scope.indexOf(customerBoatHubScopePrefix) === 0)
  .map(scope => scope.replaceAll(customerBoatHubScopePrefix, ""))
  .first()
  .value();
}