import bigDecimal from "js-big-decimal";

export const stringToCents = (str: string) => {
  return stringToGenericNumeral(str, 2);
}

export const stringToMillis = (str: string) => {
  return stringToGenericNumeral(str, 3);
}

export const stringToInt = (str: string) => {
  return stringToGenericNumeral(str, 0);
}

const stringToGenericNumeral = (str: string, decimalCount: number) => {
  str = str ?? ""
  // multi locale and remove space
  str = str.replace(".", ",").replace(/ /g, '');
  let negative = str.startsWith("-");
  if (negative) {
    str = str.substring(1);
  }
  const lastSeparator = str.lastIndexOf(",");
  let numberInCents = numeralStringToCents(
      str.substring(0, lastSeparator >= 0 ? lastSeparator : undefined)) * Math.pow(10, decimalCount);
  if (lastSeparator >= 0) { // at least 1 decimal part
    let decimalPart = str.substring(lastSeparator + 1).padEnd(decimalCount, "0");
    if (decimalPart.length > decimalCount) {
      decimalPart = decimalPart.substring(0, decimalCount);
    }
    numberInCents += numeralStringToCents(decimalPart);
  }
  if (negative) {
    numberInCents *= -1;
  }
  return numberInCents;
};

/**
 * Handles int.
 * Requires no whitespace and no decimal part.
 */
const numeralStringToCents = (numericPart: string) => {
  numericPart = numericPart.replace(",", "").padStart(1, "0");
  return parseInt(numericPart, 10);
}

export const numberCentsToString = (
    cents: number,
    decimalSeparator?: string,
    simplifyDecimal?: boolean) => {
  return genericNumeralToString(cents, 2, decimalSeparator, simplifyDecimal);
}

export const bigDecimalCentsToString = (
    cents: bigDecimal,
    decimalSeparator?: string,
    simplifyDecimal?: boolean) => {
  return genericNumeralToString(parseInt(cents.round().getValue(), 10), 2, decimalSeparator, simplifyDecimal);
}

export const numberMillisToString = (
    millis: number,
    decimalSeparator?: string,
    simplifyDecimal?: boolean) => {
  return genericNumeralToString(millis, 3, decimalSeparator, simplifyDecimal);
}

export const genericNumeralToString = (
    numeral: number,
    decimalCount: number,
    decimalSeparator?: string,
    simplifyDecimal?: boolean) => {
  if (!decimalSeparator) {
    decimalSeparator = ".";
  }
  let numberString = numeral.toString();
  if (numberString.indexOf(".") >= 0) {
    numberString = "0" + numberString;
  }
  let negative = numeral < 0;
  let sign = "";
  if (negative) {
    numeral *= -1;
    sign = "-";
    numberString = numberString.substring(1);
  }
  if (numeral < Math.pow(10, decimalCount)) {
    numberString = "0." + numberString.padStart(decimalCount, "0");
  } else {
    numberString = numberString.substring(0, numberString.length - decimalCount) + "."
        + numberString.substring(numberString.length - decimalCount);
  }
  numberString = numberString.replace(".", decimalSeparator);

  // thousands separator (space)
  numberString = numberString.replace(/\d(?=(\d{3})+\.)/g, '$& ');

  // simplification
  if (simplifyDecimal) {
    const decimalSeparatorIndex = numberString.indexOf(decimalSeparator);
    if (decimalSeparatorIndex >= 0) {
      while (numberString.endsWith("0")) {
        numberString = numberString.slice(0, -1);
      }
    }
    if (numberString.endsWith(decimalSeparator)) {
      numberString = numberString.slice(0, -1);
    }
  }
  return sign + numberString;
}

export const isNumeric = (num: any) => (typeof (num) === 'number' || typeof (num) === "string" && num.trim() !== '') && !isNaN(num as number);