/****************************************************************************
 *  This file is generated automatically by backend. Do not edit manually!  *
 *  And do not move this file as the generator writes it directly here.     *
 *  All value arrays must be sorted alphabetically.                         *
 ***************************************************************************/
export const allCarrier = [
  "CMAB",
  "MARB"
] as const;
type CarrierTuple = typeof allCarrier;
export type Carrier = CarrierTuple[number];

export const allConsumableProductCategory = [
  "1P5",
  "ONE",
  "TWO",
  "UKN",
  "XTR"
] as const;
type ConsumableProductCategoryTuple = typeof allConsumableProductCategory;
export type ConsumableProductCategory = ConsumableProductCategoryTuple[number];

export const allCountry = [
  "AD",
  "AE",
  "AF",
  "AG",
  "AI",
  "AL",
  "AM",
  "AO",
  "AQ",
  "AR",
  "AS",
  "AT",
  "AU",
  "AW",
  "AX",
  "AZ",
  "BA",
  "BB",
  "BD",
  "BE",
  "BF",
  "BG",
  "BH",
  "BI",
  "BJ",
  "BL",
  "BM",
  "BN",
  "BO",
  "BQ",
  "BR",
  "BS",
  "BT",
  "BV",
  "BW",
  "BY",
  "BZ",
  "CA",
  "CC",
  "CD",
  "CF",
  "CG",
  "CH",
  "CI",
  "CK",
  "CL",
  "CM",
  "CN",
  "CO",
  "CR",
  "CU",
  "CV",
  "CW",
  "CX",
  "CY",
  "CZ",
  "DE",
  "DJ",
  "DK",
  "DM",
  "DO",
  "DZ",
  "EC",
  "EE",
  "EG",
  "EH",
  "ER",
  "ES",
  "ET",
  "FI",
  "FJ",
  "FK",
  "FM",
  "FO",
  "FR",
  "GA",
  "GB",
  "GD",
  "GE",
  "GF",
  "GG",
  "GH",
  "GI",
  "GL",
  "GM",
  "GN",
  "GP",
  "GQ",
  "GR",
  "GS",
  "GT",
  "GU",
  "GW",
  "GY",
  "HK",
  "HM",
  "HN",
  "HR",
  "HT",
  "HU",
  "ID",
  "IE",
  "IL",
  "IM",
  "IN",
  "IO",
  "IQ",
  "IR",
  "IS",
  "IT",
  "JE",
  "JM",
  "JO",
  "JP",
  "KE",
  "KG",
  "KH",
  "KI",
  "KM",
  "KN",
  "KP",
  "KR",
  "KW",
  "KY",
  "KZ",
  "LA",
  "LB",
  "LC",
  "LI",
  "LK",
  "LR",
  "LS",
  "LT",
  "LU",
  "LV",
  "LY",
  "MA",
  "MC",
  "MD",
  "ME",
  "MF",
  "MG",
  "MH",
  "MK",
  "ML",
  "MM",
  "MN",
  "MO",
  "MP",
  "MQ",
  "MR",
  "MS",
  "MT",
  "MU",
  "MV",
  "MW",
  "MX",
  "MY",
  "MZ",
  "NA",
  "NC",
  "NE",
  "NF",
  "NG",
  "NI",
  "NL",
  "NO",
  "NP",
  "NR",
  "NU",
  "NZ",
  "OM",
  "PA",
  "PE",
  "PF",
  "PG",
  "PH",
  "PK",
  "PL",
  "PM",
  "PN",
  "PR",
  "PS",
  "PT",
  "PW",
  "PY",
  "QA",
  "RE",
  "RO",
  "RS",
  "RU",
  "RW",
  "SA",
  "SB",
  "SC",
  "SD",
  "SE",
  "SG",
  "SH",
  "SI",
  "SJ",
  "SK",
  "SL",
  "SM",
  "SN",
  "SO",
  "SR",
  "SS",
  "ST",
  "SV",
  "SX",
  "SY",
  "SZ",
  "TC",
  "TD",
  "TF",
  "TG",
  "TH",
  "TJ",
  "TK",
  "TL",
  "TM",
  "TN",
  "TO",
  "TR",
  "TT",
  "TV",
  "TW",
  "TZ",
  "UA",
  "UG",
  "UM",
  "US",
  "UY",
  "UZ",
  "VA",
  "VC",
  "VE",
  "VG",
  "VI",
  "VN",
  "VU",
  "WF",
  "WS",
  "YE",
  "YT",
  "ZA",
  "ZM",
  "ZW"
] as const;
type CountryTuple = typeof allCountry;
export type Country = CountryTuple[number];
export type CountryWithDefault = Country & "XX";

export const allCurrency = [
  "EUR",
  "JPY",
  "USD"
] as const;
type CurrencyTuple = typeof allCurrency;
export type Currency = CurrencyTuple[number];

export const allIncoterm = [
  "CFR",
  "CIF",
  "EXW",
  "FOB"
] as const;
type IncotermTuple = typeof allIncoterm;
export type Incoterm = IncotermTuple[number];

export const allOrderContainerVoyageStatus = [
  "ARR",
  "PND",
  "TRA"
] as const;
type OrderContainerVoyageStatusTuple = typeof allOrderContainerVoyageStatus;
export type OrderContainerVoyageStatus = OrderContainerVoyageStatusTuple[number];

export const allOrderPaymentDueStatus = [
  "CCO",
  "ERR",
  "FPA",
  "RFU",
  "WPA"
] as const;
type OrderPaymentDueStatusTuple = typeof allOrderPaymentDueStatus;
export type OrderPaymentDueStatus = OrderPaymentDueStatusTuple[number];

export const allOrderPaymentStatus = [
  "CNC",
  "PRE",
  "PRP",
  "RFU",
  "TPA"
] as const;
type OrderPaymentStatusTuple = typeof allOrderPaymentStatus;
export type OrderPaymentStatus = OrderPaymentStatusTuple[number];

export const allOrderPaymentType = [
  "CRN",
  "PRE",
  "PST"
] as const;
type OrderPaymentTypeTuple = typeof allOrderPaymentType;
export type OrderPaymentType = OrderPaymentTypeTuple[number];

export const allOrderStatus = [
  "ALL",
  "CAC",
  "CNC",
  "COM",
  "CRE",
  "DIR",
  "DIS",
  "FAD",
  "FDP",
  "PAC",
  "PCR",
  "PPG"
] as const;
type OrderStatusTuple = typeof allOrderStatus;
export type OrderStatus = OrderStatusTuple[number];

export const allPlatformAsset = [
  "ADM",
  "CUS",
  "ORD",
  "PFA",
  "PLA",
  "PMT",
  "PRV",
  "PTY"
] as const;
type PlatformAssetTuple = typeof allPlatformAsset;
export type PlatformAsset = PlatformAssetTuple[number];

export const allPriceFilterType = [
  "DEPARTURE",
  "FAMILY",
  "METATYPE",
  "ORIGIN",
  "PROV",
  "TYPE"
] as const;
type PriceFilterTypeTuple = typeof allPriceFilterType;
export type PriceFilterType = PriceFilterTypeTuple[number];

export const allPriceItemType = [
  "NEC",
  "ODM",
  "VAT"
] as const;
type PriceItemTypeTuple = typeof allPriceItemType;
export type PriceItemType = PriceItemTypeTuple[number];

export const allProductFamily = [
  "1",
  "2"
] as const;
type ProductFamilyTuple = typeof allProductFamily;
export type ProductFamily = ProductFamilyTuple[number];

export const allProductMetaType = [
  "APL",
  "APR",
  "ART",
  "ASP",
  "AVO",
  "BAN",
  "BEA",
  "BEE",
  "BLB",
  "BLU",
  "BPE",
  "BRO",
  "BRU",
  "CAB",
  "CAL",
  "CAR",
  "CAS",
  "CCB",
  "CEL",
  "CHA",
  "CHC",
  "CHE",
  "CHN",
  "CLE",
  "COC",
  "COR",
  "CRA",
  "CRD",
  "CRN",
  "CUR",
  "DAT",
  "DRG",
  "EGP",
  "END",
  "FEN",
  "FIG",
  "GAR",
  "GIN",
  "GRP",
  "KIW",
  "KUM",
  "LEE",
  "LEM",
  "LET",
  "LME",
  "LYC",
  "MDR",
  "MEL",
  "MGO",
  "MGS",
  "MUS",
  "NAS",
  "NEC",
  "OKR",
  "ONI",
  "ORN",
  "PAP",
  "PAR",
  "PEH",
  "PEP",
  "PER",
  "PGN",
  "PIN",
  "PLU",
  "POL",
  "PRU",
  "PSF",
  "PSL",
  "PSM",
  "PTA",
  "QUI",
  "RAD",
  "RAM",
  "RSB",
  "SHA",
  "SOR",
  "SPO",
  "SQU",
  "STB",
  "SUA",
  "TAM",
  "TAR",
  "THY",
  "TNG",
  "TOM",
  "TRN",
  "TUR",
  "UKN",
  "WAL",
  "WAT",
  "YAM",
  "ZUC"
] as const;
type ProductMetaTypeTuple = typeof allProductMetaType;
export type ProductMetaType = ProductMetaTypeTuple[number];

export const allProductPacking = [
  "BAC",
  "BAG",
  "BAO",
  "BEU",
  "BLK",
  "BNC",
  "BUK",
  "NET",
  "RO1",
  "RO2",
  "SOV",
  "TRY",
  "UKN"
] as const;
type ProductPackingTuple = typeof allProductPacking;
export type ProductPacking = ProductPackingTuple[number];

export const allProductPriceUnit = [
  "BOX",
  "KG"
] as const;
type ProductPriceUnitTuple = typeof allProductPriceUnit;
export type ProductPriceUnit = ProductPriceUnitTuple[number];

export const allProductStatus = [
  "PUB",
  "REV"
] as const;
type ProductStatusTuple = typeof allProductStatus;
export type ProductStatus = ProductStatusTuple[number];

export const allProductType = [
  "APARAG",
  "APLCRI",
  "APLGAL",
  "APLGOL",
  "APLGOR",
  "APLGRN",
  "APLPNK",
  "APLPNL",
  "APLRED",
  "APRICO",
  "ARTICH",
  "AVOCAD",
  "BANCAU",
  "BANCAV",
  "BANPLA",
  "BEANGR",
  "BEET",
  "BLBERY",
  "BLUBRY",
  "BPEGRE",
  "BPEORN",
  "BPERED",
  "BPETRI",
  "BPEYEL",
  "BROCOL",
  "BRUSPR",
  "CABRED",
  "CABROM",
  "CABWHI",
  "CALIFL",
  "CALORG",
  "CALPRP",
  "CARFAN",
  "CARROT",
  "CASSAV",
  "CBPAKC",
  "CBPTSA",
  "CCBER",
  "CELIAC",
  "CELLEF",
  "CHARD",
  "CHAWHT",
  "CHAYOT",
  "CHERRY",
  "CHESTN",
  "CLEMEN",
  "CLETAN",
  "COCONU",
  "CORIAN",
  "CORN",
  "CURMAX",
  "CURPPO",
  "CURRAN",
  "DATMED",
  "DRAGON",
  "EGPLAN",
  "ENDIVE",
  "ENDRED",
  "FENNEL",
  "FIGFRE",
  "GARLIC",
  "GARPUR",
  "GINGER",
  "GRPGLO",
  "GRPRSE",
  "GRPWCH",
  "GRPWMU",
  "GRPWTE",
  "KIWI",
  "KIWYEL",
  "KUMQUA",
  "LEEK",
  "LEMON",
  "LETARU",
  "LETBAT",
  "LETBUT",
  "LETGEM",
  "LETICE",
  "LETLAM",
  "LETLEA",
  "LETLOO",
  "LETROM",
  "LME",
  "LYCHEE",
  "MANDAR",
  "MANMUR",
  "MANNOV",
  "MANORA",
  "MANORT",
  "MELGRE",
  "MELON",
  "MGSALA",
  "MNGO",
  "MUSHRO",
  "MUSPAR",
  "NASHI",
  "NECWHI",
  "NECYEL",
  "OKRA",
  "ONION",
  "ONIRED",
  "ONIWHI",
  "ORANAV",
  "ORANGE",
  "ORASDO",
  "PAPAYA",
  "PAPGRE",
  "PARSLY",
  "PARSNI",
  "PEHFLA",
  "PEHWHI",
  "PEHYEL",
  "PEPSTR",
  "PEPSWE",
  "PERABA",
  "PERALE",
  "PERCAR",
  "PERCON",
  "PERLIM",
  "PERROC",
  "PERWIL",
  "PGNATE",
  "PINFRU",
  "PINMD2",
  "PINQUE",
  "PLUGBA",
  "PLUPDE",
  "PLURED",
  "PLUYEL",
  "POMELO",
  "POMPIN",
  "PRUNE",
  "PSFRUI",
  "PSMMON",
  "PTABAB",
  "PTAFRY",
  "PTAGAT",
  "PTAGRI",
  "PTASPU",
  "PTASWE",
  "PTATO",
  "PTAWAS",
  "QUINCE",
  "RADBLK",
  "RADPIN",
  "RAMBUT",
  "RSBERY",
  "SHALOT",
  "SOURSO",
  "SPRONI",
  "SQUASH",
  "STRAWB",
  "STRGAR",
  "SUGAPP",
  "TAMARI",
  "TANGEL",
  "TARO",
  "THYME",
  "TOMCHE",
  "TOMELI",
  "TOMRND",
  "TOMROM",
  "TOMRVI",
  "TURMER",
  "TURNIP",
  "UKN",
  "WALFRA",
  "WATMLN",
  "YAMTRI",
  "YAMWHI",
  "YAMYEL",
  "ZUCCHI",
  "ZUCYEL"
] as const;
type ProductTypeTuple = typeof allProductType;
export type ProductType = ProductTypeTuple[number];

export const allProviderStatus = [
  "ACT",
  "DIS",
  "REV",
  "SBA"
] as const;
type ProviderStatusTuple = typeof allProviderStatus;
export type ProviderStatus = ProviderStatusTuple[number];

export const allResolvedIncoterm = [
  "CTC",
  "CTR",
  "ETF",
  "FTC",
  "FTF"
] as const;
type ResolvedIncotermTuple = typeof allResolvedIncoterm;
export type ResolvedIncoterm = ResolvedIncotermTuple[number];

export const allSupportedLanguage = [
  "en",
  "es",
  "fr"
] as const;
type SupportedLanguageTuple = typeof allSupportedLanguage;
export type SupportedLanguage = SupportedLanguageTuple[number];

export const allTransportHub = [
  "BBBGI",
  "BEANR",
  "CLSAI",
  "COCTG",
  "CRMOB",
  "CRPMN",
  "DOCAU",
  "DOHAI",
  "EGALY",
  "EGDAM",
  "ESVLC",
  "FRLEH",
  "FRMTX",
  "GPPTP",
  "HNPCR",
  "ITLIV",
  "JPNGO",
  "MAPTM",
  "MQFDF",
  "MTMLA",
  "MXVER",
  "NLRTM",
  "PECLL",
  "PEPAI",
  "TTPOS",
  "XXX"
] as const;
type TransportHubTuple = typeof allTransportHub;
export type TransportHub = TransportHubTuple[number];

export const productHierarchy = {
  APLCRI: {
    metaType: "APL",
    family: 1
  },
  APLGAL: {
    metaType: "APL",
    family: 1
  },
  APLGOL: {
    metaType: "APL",
    family: 1
  },
  APLGOR: {
    metaType: "APL",
    family: 1
  },
  APLGRN: {
    metaType: "APL",
    family: 1
  },
  APLPNK: {
    metaType: "APL",
    family: 1
  },
  APLPNL: {
    metaType: "APL",
    family: 1
  },
  APLRED: {
    metaType: "APL",
    family: 1
  },
  APRICO: {
    metaType: "APR",
    family: 1
  },
  ARTICH: {
    metaType: "ART",
    family: 2
  },
  APARAG: {
    metaType: "ASP",
    family: 2
  },
  AVOCAD: {
    metaType: "AVO",
    family: 1
  },
  BANCAV: {
    metaType: "BAN",
    family: 1
  },
  BANCAU: {
    metaType: "BAN",
    family: 1
  },
  BANPLA: {
    metaType: "BAN",
    family: 1
  },
  BEANGR: {
    metaType: "BEA",
    family: 2
  },
  BEET: {
    metaType: "BEE",
    family: 2
  },
  BPEGRE: {
    metaType: "BPE",
    family: 2
  },
  BPEORN: {
    metaType: "BPE",
    family: 2
  },
  BPERED: {
    metaType: "BPE",
    family: 2
  },
  BPETRI: {
    metaType: "BPE",
    family: 2
  },
  BPEYEL: {
    metaType: "BPE",
    family: 2
  },
  BLBERY: {
    metaType: "BLB",
    family: 1
  },
  BLUBRY: {
    metaType: "BLU",
    family: 1
  },
  BROCOL: {
    metaType: "BRO",
    family: 2
  },
  BRUSPR: {
    metaType: "BRU",
    family: 2
  },
  CABWHI: {
    metaType: "CAB",
    family: 2
  },
  CABRED: {
    metaType: "CAB",
    family: 2
  },
  CABROM: {
    metaType: "CAB",
    family: 2
  },
  CARROT: {
    metaType: "CAR",
    family: 2
  },
  CARFAN: {
    metaType: "CAR",
    family: 2
  },
  CASSAV: {
    metaType: "CAS",
    family: 2
  },
  CALIFL: {
    metaType: "CAL",
    family: 2
  },
  CALORG: {
    metaType: "CAL",
    family: 2
  },
  CALPRP: {
    metaType: "CAL",
    family: 2
  },
  CELLEF: {
    metaType: "CEL",
    family: 2
  },
  CELIAC: {
    metaType: "CEL",
    family: 2
  },
  CHARD: {
    metaType: "CRD",
    family: 2
  },
  CHAYOT: {
    metaType: "CHA",
    family: 2
  },
  CHAWHT: {
    metaType: "CHA",
    family: 2
  },
  CHERRY: {
    metaType: "CHE",
    family: 1
  },
  CHESTN: {
    metaType: "CHN",
    family: 2
  },
  CBPTSA: {
    metaType: "CHC",
    family: 2
  },
  CBPAKC: {
    metaType: "CHC",
    family: 2
  },
  CLEMEN: {
    metaType: "CLE",
    family: 1
  },
  CLETAN: {
    metaType: "CLE",
    family: 1
  },
  COCONU: {
    metaType: "COC",
    family: 1
  },
  CORIAN: {
    metaType: "COR",
    family: 2
  },
  CORN: {
    metaType: "CRN",
    family: 2
  },
  CCBER: {
    metaType: "CCB",
    family: 2
  },
  CURMAX: {
    metaType: "CCB",
    family: 2
  },
  CURPPO: {
    metaType: "CCB",
    family: 2
  },
  CURRAN: {
    metaType: "CRA",
    family: 1
  },
  DATMED: {
    metaType: "DAT",
    family: 1
  },
  DRAGON: {
    metaType: "DRG",
    family: 1
  },
  EGPLAN: {
    metaType: "EGP",
    family: 2
  },
  ENDIVE: {
    metaType: "END",
    family: 2
  },
  ENDRED: {
    metaType: "END",
    family: 2
  },
  FENNEL: {
    metaType: "FEN",
    family: 2
  },
  FIGFRE: {
    metaType: "FIG",
    family: 1
  },
  GARLIC: {
    metaType: "GAR",
    family: 2
  },
  GARPUR: {
    metaType: "GAR",
    family: 2
  },
  GINGER: {
    metaType: "GIN",
    family: 2
  },
  GRPGLO: {
    metaType: "GRP",
    family: 1
  },
  GRPRSE: {
    metaType: "GRP",
    family: 1
  },
  GRPWTE: {
    metaType: "GRP",
    family: 1
  },
  GRPWCH: {
    metaType: "GRP",
    family: 1
  },
  GRPWMU: {
    metaType: "GRP",
    family: 1
  },
  KIWI: {
    metaType: "KIW",
    family: 1
  },
  KIWYEL: {
    metaType: "KIW",
    family: 1
  },
  KUMQUA: {
    metaType: "KUM",
    family: 1
  },
  LEEK: {
    metaType: "LEE",
    family: 2
  },
  LEMON: {
    metaType: "LEM",
    family: 1
  },
  LETARU: {
    metaType: "LET",
    family: 2
  },
  LETBAT: {
    metaType: "LET",
    family: 2
  },
  LETBUT: {
    metaType: "LET",
    family: 2
  },
  LETICE: {
    metaType: "LET",
    family: 2
  },
  LETLAM: {
    metaType: "LET",
    family: 2
  },
  LETLEA: {
    metaType: "LET",
    family: 2
  },
  LETGEM: {
    metaType: "LET",
    family: 2
  },
  LETLOO: {
    metaType: "LET",
    family: 2
  },
  LETROM: {
    metaType: "LET",
    family: 2
  },
  LME: {
    metaType: "LME",
    family: 1
  },
  LYCHEE: {
    metaType: "LYC",
    family: 1
  },
  MANDAR: {
    metaType: "MDR",
    family: 1
  },
  MANMUR: {
    metaType: "MDR",
    family: 1
  },
  MANORA: {
    metaType: "MDR",
    family: 1
  },
  MANNOV: {
    metaType: "MDR",
    family: 1
  },
  MANORT: {
    metaType: "MDR",
    family: 1
  },
  MNGO: {
    metaType: "MGO",
    family: 1
  },
  MELON: {
    metaType: "MEL",
    family: 1
  },
  MELGRE: {
    metaType: "MEL",
    family: 1
  },
  MGSALA: {
    metaType: "MGS",
    family: 2
  },
  MUSHRO: {
    metaType: "MUS",
    family: 2
  },
  MUSPAR: {
    metaType: "MUS",
    family: 2
  },
  NASHI: {
    metaType: "NAS",
    family: 1
  },
  NECWHI: {
    metaType: "NEC",
    family: 1
  },
  NECYEL: {
    metaType: "NEC",
    family: 1
  },
  OKRA: {
    metaType: "OKR",
    family: 2
  },
  ONION: {
    metaType: "ONI",
    family: 2
  },
  ONIRED: {
    metaType: "ONI",
    family: 2
  },
  ONIWHI: {
    metaType: "ONI",
    family: 2
  },
  ORANGE: {
    metaType: "ORN",
    family: 1
  },
  ORANAV: {
    metaType: "ORN",
    family: 1
  },
  ORASDO: {
    metaType: "ORN",
    family: 1
  },
  PAPAYA: {
    metaType: "PAP",
    family: 1
  },
  PAPGRE: {
    metaType: "PAP",
    family: 1
  },
  PARSLY: {
    metaType: "PSL",
    family: 2
  },
  PARSNI: {
    metaType: "PAR",
    family: 2
  },
  PSFRUI: {
    metaType: "PSF",
    family: 1
  },
  PEHFLA: {
    metaType: "PEH",
    family: 1
  },
  PEHWHI: {
    metaType: "PEH",
    family: 1
  },
  PEHYEL: {
    metaType: "PEH",
    family: 1
  },
  PERABA: {
    metaType: "PEH",
    family: 1
  },
  PERALE: {
    metaType: "PER",
    family: 1
  },
  PERCAR: {
    metaType: "PER",
    family: 1
  },
  PERCON: {
    metaType: "PER",
    family: 1
  },
  PERLIM: {
    metaType: "PER",
    family: 1
  },
  PERROC: {
    metaType: "PER",
    family: 1
  },
  PERWIL: {
    metaType: "PER",
    family: 1
  },
  PEPSTR: {
    metaType: "PEP",
    family: 2
  },
  PEPSWE: {
    metaType: "PEP",
    family: 2
  },
  PSMMON: {
    metaType: "PSM",
    family: 1
  },
  PINFRU: {
    metaType: "PIN",
    family: 1
  },
  PINMD2: {
    metaType: "PIN",
    family: 1
  },
  PINQUE: {
    metaType: "PIN",
    family: 1
  },
  PLUGBA: {
    metaType: "PLU",
    family: 1
  },
  PLUPDE: {
    metaType: "PLU",
    family: 1
  },
  PLURED: {
    metaType: "PLU",
    family: 1
  },
  PLUYEL: {
    metaType: "PLU",
    family: 1
  },
  PGNATE: {
    metaType: "PGN",
    family: 1
  },
  POMELO: {
    metaType: "POL",
    family: 1
  },
  POMPIN: {
    metaType: "POL",
    family: 1
  },
  PTATO: {
    metaType: "PTA",
    family: 2
  },
  PTAGAT: {
    metaType: "PTA",
    family: 2
  },
  PTAGRI: {
    metaType: "PTA",
    family: 2
  },
  PTABAB: {
    metaType: "PTA",
    family: 2
  },
  PTAFRY: {
    metaType: "PTA",
    family: 2
  },
  PTASPU: {
    metaType: "PTA",
    family: 2
  },
  PTASWE: {
    metaType: "PTA",
    family: 2
  },
  PTAWAS: {
    metaType: "PTA",
    family: 2
  },
  PRUNE: {
    metaType: "PRU",
    family: 1
  },
  QUINCE: {
    metaType: "QUI",
    family: 1
  },
  RADBLK: {
    metaType: "RAD",
    family: 2
  },
  RADPIN: {
    metaType: "RAD",
    family: 2
  },
  RAMBUT: {
    metaType: "RAM",
    family: 1
  },
  RSBERY: {
    metaType: "RSB",
    family: 1
  },
  SHALOT: {
    metaType: "SHA",
    family: 2
  },
  SOURSO: {
    metaType: "SOR",
    family: 1
  },
  SPRONI: {
    metaType: "SPO",
    family: 2
  },
  SQUASH: {
    metaType: "SQU",
    family: 2
  },
  STRAWB: {
    metaType: "STB",
    family: 1
  },
  STRGAR: {
    metaType: "STB",
    family: 1
  },
  SUGAPP: {
    metaType: "SUA",
    family: 1
  },
  TAMARI: {
    metaType: "TAM",
    family: 1
  },
  TANGEL: {
    metaType: "TNG",
    family: 1
  },
  TARO: {
    metaType: "TAR",
    family: 2
  },
  THYME: {
    metaType: "THY",
    family: 2
  },
  TOMCHE: {
    metaType: "TOM",
    family: 2
  },
  TOMELI: {
    metaType: "TOM",
    family: 2
  },
  TOMROM: {
    metaType: "TOM",
    family: 2
  },
  TOMRND: {
    metaType: "TOM",
    family: 2
  },
  TOMRVI: {
    metaType: "TOM",
    family: 2
  },
  TURMER: {
    metaType: "TUR",
    family: 2
  },
  TURNIP: {
    metaType: "TRN",
    family: 2
  },
  WALFRA: {
    metaType: "WAL",
    family: 1
  },
  WATMLN: {
    metaType: "WAT",
    family: 1
  },
  YAMTRI: {
    metaType: "YAM",
    family: 2
  },
  YAMWHI: {
    metaType: "YAM",
    family: 2
  },
  YAMYEL: {
    metaType: "YAM",
    family: 2
  },
  ZUCCHI: {
    metaType: "ZUC",
    family: 2
  },
  ZUCYEL: {
    metaType: "ZUC",
    family: 2
  },
  UKN: {
    metaType: "UKN",
    family: 2
  },
}

export const countryTimezones = {
  AD: [
    'Europe/Andorra'
  ],
  AE: [
    'Asia/Dubai'
  ],
  AF: [
    'Asia/Kabul'
  ],
  AG: [
    'America/Antigua'
  ],
  AI: [
    'America/Anguilla'
  ],
  AL: [
    'Europe/Tirane'
  ],
  AM: [
    'Asia/Yerevan'
  ],
  AO: [
    'Africa/Luanda'
  ],
  AQ: [
    'Antarctica/Casey','Antarctica/Davis','Antarctica/DumontDUrville','Antarctica/Mawson','Antarctica/McMurdo','Antarctica/Palmer','Antarctica/Rothera','Antarctica/South_Pole','Antarctica/Syowa','Antarctica/Troll','Antarctica/Vostok'
  ],
  AR: [
    'America/Argentina/Buenos_Aires','America/Argentina/Catamarca','America/Argentina/ComodRivadavia','America/Argentina/Cordoba','America/Argentina/Jujuy','America/Argentina/La_Rioja','America/Argentina/Mendoza','America/Argentina/Rio_Gallegos','America/Argentina/Salta','America/Argentina/San_Juan','America/Argentina/San_Luis','America/Argentina/Tucuman','America/Argentina/Ushuaia','America/Buenos_Aires','America/Catamarca','America/Cordoba','America/Jujuy','America/Mendoza','America/Rosario'
  ],
  AS: [
    'Pacific/Pago_Pago','Pacific/Samoa','US/Samoa'
  ],
  AT: [
    'Europe/Vienna'
  ],
  AU: [
    'Antarctica/Macquarie','Australia/ACT','Australia/Adelaide','Australia/Brisbane','Australia/Broken_Hill','Australia/Canberra','Australia/Currie','Australia/Darwin','Australia/Eucla','Australia/Hobart','Australia/LHI','Australia/Lindeman','Australia/Lord_Howe','Australia/Melbourne','Australia/NSW','Australia/North','Australia/Perth','Australia/Queensland','Australia/South','Australia/Sydney','Australia/Tasmania','Australia/Victoria','Australia/West','Australia/Yancowinna'
  ],
  AW: [
    'America/Aruba'
  ],
  AX: [
    'Europe/Mariehamn'
  ],
  AZ: [
    'Asia/Baku'
  ],
  BA: [
    'Europe/Sarajevo'
  ],
  BB: [
    'America/Barbados'
  ],
  BD: [
    'Asia/Dacca','Asia/Dhaka'
  ],
  BE: [
    'Europe/Brussels'
  ],
  BF: [
    'Africa/Ouagadougou'
  ],
  BG: [
    'Europe/Sofia'
  ],
  BH: [
    'Asia/Bahrain'
  ],
  BI: [
    'Africa/Bujumbura'
  ],
  BJ: [
    'Africa/Porto-Novo'
  ],
  BL: [
    'America/St_Barthelemy'
  ],
  BM: [
    'Atlantic/Bermuda'
  ],
  BN: [
    'Asia/Brunei'
  ],
  BO: [
    'America/La_Paz'
  ],
  BQ: [
    'America/Kralendijk'
  ],
  BR: [
    'America/Araguaina','America/Bahia','America/Belem','America/Boa_Vista','America/Campo_Grande','America/Cuiaba','America/Eirunepe','America/Fortaleza','America/Maceio','America/Manaus','America/Noronha','America/Porto_Acre','America/Porto_Velho','America/Recife','America/Rio_Branco','America/Santarem','America/Sao_Paulo','Brazil/Acre','Brazil/DeNoronha','Brazil/East','Brazil/West'
  ],
  BS: [
    'America/Nassau'
  ],
  BT: [
    'Asia/Thimbu','Asia/Thimphu'
  ],
  BW: [
    'Africa/Gaborone'
  ],
  BY: [
    'Europe/Minsk'
  ],
  BZ: [
    'America/Belize'
  ],
  CA: [
    'America/Atikokan','America/Blanc-Sablon','America/Cambridge_Bay','America/Coral_Harbour','America/Creston','America/Dawson','America/Dawson_Creek','America/Edmonton','America/Fort_Nelson','America/Glace_Bay','America/Goose_Bay','America/Halifax','America/Inuvik','America/Iqaluit','America/Moncton','America/Montreal','America/Nipigon','America/Pangnirtung','America/Rainy_River','America/Rankin_Inlet','America/Regina','America/Resolute','America/St_Johns','America/Swift_Current','America/Thunder_Bay','America/Toronto','America/Vancouver','America/Whitehorse','America/Winnipeg','America/Yellowknife','Canada/Atlantic','Canada/Central','Canada/Eastern','Canada/Mountain','Canada/Newfoundland','Canada/Pacific','Canada/Saskatchewan','Canada/Yukon'
  ],
  CC: [
    'Indian/Cocos'
  ],
  CD: [
    'Africa/Kinshasa','Africa/Lubumbashi'
  ],
  CF: [
    'Africa/Bangui'
  ],
  CG: [
    'Africa/Brazzaville'
  ],
  CH: [
    'Europe/Zurich'
  ],
  CI: [
    'Africa/Abidjan','Iceland'
  ],
  CK: [
    'Pacific/Rarotonga'
  ],
  CL: [
    'America/Punta_Arenas','America/Santiago','Chile/Continental','Chile/EasterIsland','Pacific/Easter'
  ],
  CM: [
    'Africa/Douala'
  ],
  CN: [
    'Asia/Chongqing','Asia/Chungking','Asia/Harbin','Asia/Kashgar','Asia/Shanghai','Asia/Urumqi','PRC'
  ],
  CO: [
    'America/Bogota'
  ],
  CR: [
    'America/Costa_Rica'
  ],
  CU: [
    'America/Havana','Cuba'
  ],
  CV: [
    'Atlantic/Cape_Verde'
  ],
  CW: [
    'America/Curacao'
  ],
  CX: [
    'Indian/Christmas'
  ],
  CY: [
    'Asia/Famagusta','Asia/Nicosia','Europe/Nicosia'
  ],
  CZ: [
    'Europe/Prague'
  ],
  DE: [
    'Europe/Berlin','Europe/Busingen'
  ],
  DJ: [
    'Africa/Djibouti'
  ],
  DK: [
    'Europe/Copenhagen'
  ],
  DM: [
    'America/Dominica'
  ],
  DO: [
    'America/Santo_Domingo'
  ],
  DZ: [
    'Africa/Algiers'
  ],
  EC: [
    'America/Guayaquil','Pacific/Galapagos'
  ],
  EE: [
    'Europe/Tallinn'
  ],
  EG: [
    'Africa/Cairo','Egypt'
  ],
  EH: [
    'Africa/El_Aaiun'
  ],
  ER: [
    'Africa/Asmara','Africa/Asmera'
  ],
  ES: [
    'Africa/Ceuta','Atlantic/Canary','Europe/Madrid'
  ],
  ET: [
    'Africa/Addis_Ababa'
  ],
  FI: [
    'Europe/Helsinki'
  ],
  FJ: [
    'Pacific/Fiji'
  ],
  FK: [
    'Atlantic/Stanley'
  ],
  FM: [
    'Pacific/Chuuk','Pacific/Kosrae','Pacific/Pohnpei','Pacific/Ponape','Pacific/Truk','Pacific/Yap'
  ],
  FO: [
    'Atlantic/Faeroe','Atlantic/Faroe'
  ],
  FR: [
    'Europe/Paris'
  ],
  GA: [
    'Africa/Libreville'
  ],
  GB: [
    'Europe/Belfast','Europe/London','GB','GB-Eire'
  ],
  GD: [
    'America/Grenada'
  ],
  GE: [
    'Asia/Tbilisi'
  ],
  GF: [
    'America/Cayenne'
  ],
  GG: [
    'Europe/Guernsey'
  ],
  GH: [
    'Africa/Accra'
  ],
  GI: [
    'Europe/Gibraltar'
  ],
  GL: [
    'America/Danmarkshavn','America/Godthab','America/Nuuk','America/Scoresbysund','America/Thule'
  ],
  GM: [
    'Africa/Banjul'
  ],
  GN: [
    'Africa/Conakry'
  ],
  GP: [
    'America/Guadeloupe'
  ],
  GQ: [
    'Africa/Malabo'
  ],
  GR: [
    'Europe/Athens'
  ],
  GS: [
    'Atlantic/South_Georgia'
  ],
  GT: [
    'America/Guatemala'
  ],
  GU: [
    'Pacific/Guam'
  ],
  GW: [
    'Africa/Bissau'
  ],
  GY: [
    'America/Guyana'
  ],
  HK: [
    'Asia/Hong_Kong','Hongkong'
  ],
  HN: [
    'America/Tegucigalpa'
  ],
  HR: [
    'Europe/Zagreb'
  ],
  HT: [
    'America/Port-au-Prince'
  ],
  HU: [
    'Europe/Budapest'
  ],
  ID: [
    'Asia/Jakarta','Asia/Jayapura','Asia/Makassar','Asia/Pontianak','Asia/Ujung_Pandang'
  ],
  IE: [
    'Eire','Europe/Dublin'
  ],
  IL: [
    'Asia/Jerusalem','Asia/Tel_Aviv','Israel'
  ],
  IM: [
    'Europe/Isle_of_Man'
  ],
  IN: [
    'Asia/Calcutta','Asia/Kolkata'
  ],
  IO: [
    'Indian/Chagos'
  ],
  IQ: [
    'Asia/Baghdad'
  ],
  IR: [
    'Asia/Tehran','Iran'
  ],
  IS: [
    'Atlantic/Reykjavik'
  ],
  IT: [
    'Europe/Rome'
  ],
  JE: [
    'Europe/Jersey'
  ],
  JM: [
    'America/Jamaica','Jamaica'
  ],
  JO: [
    'Asia/Amman'
  ],
  JP: [
    'Asia/Tokyo','Japan'
  ],
  KE: [
    'Africa/Nairobi'
  ],
  KG: [
    'Asia/Bishkek'
  ],
  KH: [
    'Asia/Phnom_Penh'
  ],
  KI: [
    'Pacific/Enderbury','Pacific/Kanton','Pacific/Kiritimati','Pacific/Tarawa'
  ],
  KM: [
    'Indian/Comoro'
  ],
  KN: [
    'America/St_Kitts'
  ],
  KP: [
    'Asia/Pyongyang'
  ],
  KR: [
    'Asia/Seoul','ROK'
  ],
  KW: [
    'Asia/Kuwait'
  ],
  KY: [
    'America/Cayman'
  ],
  KZ: [
    'Asia/Almaty','Asia/Aqtau','Asia/Aqtobe','Asia/Atyrau','Asia/Oral','Asia/Qostanay','Asia/Qyzylorda'
  ],
  LA: [
    'Asia/Vientiane'
  ],
  LB: [
    'Asia/Beirut'
  ],
  LC: [
    'America/St_Lucia'
  ],
  LI: [
    'Europe/Vaduz'
  ],
  LK: [
    'Asia/Colombo'
  ],
  LR: [
    'Africa/Monrovia'
  ],
  LS: [
    'Africa/Maseru'
  ],
  LT: [
    'Europe/Vilnius'
  ],
  LU: [
    'Europe/Luxembourg'
  ],
  LV: [
    'Europe/Riga'
  ],
  LY: [
    'Africa/Tripoli','Libya'
  ],
  MA: [
    'Africa/Casablanca'
  ],
  MC: [
    'Europe/Monaco'
  ],
  MD: [
    'Europe/Chisinau','Europe/Tiraspol'
  ],
  ME: [
    'Europe/Podgorica'
  ],
  MF: [
    'America/Marigot'
  ],
  MG: [
    'Indian/Antananarivo'
  ],
  MH: [
    'Kwajalein','Pacific/Kwajalein','Pacific/Majuro'
  ],
  MK: [
    'Europe/Skopje'
  ],
  ML: [
    'Africa/Bamako','Africa/Timbuktu'
  ],
  MM: [
    'Asia/Rangoon','Asia/Yangon'
  ],
  MN: [
    'Asia/Choibalsan','Asia/Hovd','Asia/Ulaanbaatar','Asia/Ulan_Bator'
  ],
  MO: [
    'Asia/Macao','Asia/Macau'
  ],
  MP: [
    'Pacific/Saipan'
  ],
  MQ: [
    'America/Martinique'
  ],
  MR: [
    'Africa/Nouakchott'
  ],
  MS: [
    'America/Montserrat'
  ],
  MT: [
    'Europe/Malta'
  ],
  MU: [
    'Indian/Mauritius'
  ],
  MV: [
    'Indian/Maldives'
  ],
  MW: [
    'Africa/Blantyre'
  ],
  MX: [
    'America/Bahia_Banderas','America/Cancun','America/Chihuahua','America/Ciudad_Juarez','America/Ensenada','America/Hermosillo','America/Matamoros','America/Mazatlan','America/Merida','America/Mexico_City','America/Monterrey','America/Ojinaga','America/Santa_Isabel','America/Tijuana','Mexico/BajaNorte','Mexico/BajaSur','Mexico/General'
  ],
  MY: [
    'Asia/Kuala_Lumpur','Asia/Kuching'
  ],
  MZ: [
    'Africa/Maputo'
  ],
  NA: [
    'Africa/Windhoek'
  ],
  NC: [
    'Pacific/Noumea'
  ],
  NE: [
    'Africa/Niamey'
  ],
  NF: [
    'Pacific/Norfolk'
  ],
  NG: [
    'Africa/Lagos'
  ],
  NI: [
    'America/Managua'
  ],
  NL: [
    'Europe/Amsterdam'
  ],
  NO: [
    'Europe/Oslo'
  ],
  NP: [
    'Asia/Kathmandu','Asia/Katmandu'
  ],
  NR: [
    'Pacific/Nauru'
  ],
  NU: [
    'Pacific/Niue'
  ],
  NZ: [
    'NZ','NZ-CHAT','Pacific/Auckland','Pacific/Chatham'
  ],
  OM: [
    'Asia/Muscat'
  ],
  PA: [
    'America/Panama'
  ],
  PE: [
    'America/Lima'
  ],
  PF: [
    'Pacific/Gambier','Pacific/Marquesas','Pacific/Tahiti'
  ],
  PG: [
    'Pacific/Bougainville','Pacific/Port_Moresby'
  ],
  PH: [
    'Asia/Manila'
  ],
  PK: [
    'Asia/Karachi'
  ],
  PL: [
    'Europe/Warsaw','Poland'
  ],
  PM: [
    'America/Miquelon'
  ],
  PN: [
    'Pacific/Pitcairn'
  ],
  PR: [
    'America/Puerto_Rico'
  ],
  PS: [
    'Asia/Gaza','Asia/Hebron'
  ],
  PT: [
    'Atlantic/Azores','Atlantic/Madeira','Europe/Lisbon','Portugal'
  ],
  PW: [
    'Pacific/Palau'
  ],
  PY: [
    'America/Asuncion'
  ],
  QA: [
    'Asia/Qatar'
  ],
  RE: [
    'Indian/Reunion'
  ],
  RO: [
    'Europe/Bucharest'
  ],
  RS: [
    'Europe/Belgrade'
  ],
  RU: [
    'Asia/Anadyr','Asia/Barnaul','Asia/Chita','Asia/Irkutsk','Asia/Kamchatka','Asia/Khandyga','Asia/Krasnoyarsk','Asia/Magadan','Asia/Novokuznetsk','Asia/Novosibirsk','Asia/Omsk','Asia/Sakhalin','Asia/Srednekolymsk','Asia/Tomsk','Asia/Ust-Nera','Asia/Vladivostok','Asia/Yakutsk','Asia/Yekaterinburg','Europe/Astrakhan','Europe/Kaliningrad','Europe/Kirov','Europe/Moscow','Europe/Samara','Europe/Saratov','Europe/Ulyanovsk','Europe/Volgograd','W-SU'
  ],
  RW: [
    'Africa/Kigali'
  ],
  SA: [
    'Asia/Riyadh'
  ],
  SB: [
    'Pacific/Guadalcanal'
  ],
  SC: [
    'Indian/Mahe'
  ],
  SD: [
    'Africa/Khartoum'
  ],
  SE: [
    'Europe/Stockholm'
  ],
  SG: [
    'Asia/Singapore','Singapore'
  ],
  SH: [
    'Atlantic/St_Helena'
  ],
  SI: [
    'Europe/Ljubljana'
  ],
  SJ: [
    'Arctic/Longyearbyen','Atlantic/Jan_Mayen'
  ],
  SK: [
    'Europe/Bratislava'
  ],
  SL: [
    'Africa/Freetown'
  ],
  SM: [
    'Europe/San_Marino'
  ],
  SN: [
    'Africa/Dakar'
  ],
  SO: [
    'Africa/Mogadishu'
  ],
  SR: [
    'America/Paramaribo'
  ],
  SS: [
    'Africa/Juba'
  ],
  ST: [
    'Africa/Sao_Tome'
  ],
  SV: [
    'America/El_Salvador'
  ],
  SX: [
    'America/Lower_Princes'
  ],
  SY: [
    'Asia/Damascus'
  ],
  SZ: [
    'Africa/Mbabane'
  ],
  TC: [
    'America/Grand_Turk'
  ],
  TD: [
    'Africa/Ndjamena'
  ],
  TF: [
    'Indian/Kerguelen'
  ],
  TG: [
    'Africa/Lome'
  ],
  TH: [
    'Asia/Bangkok'
  ],
  TJ: [
    'Asia/Dushanbe'
  ],
  TK: [
    'Pacific/Fakaofo'
  ],
  TL: [
    'Asia/Dili'
  ],
  TM: [
    'Asia/Ashgabat','Asia/Ashkhabad'
  ],
  TN: [
    'Africa/Tunis'
  ],
  TO: [
    'Pacific/Tongatapu'
  ],
  TR: [
    'Asia/Istanbul','Europe/Istanbul','Turkey'
  ],
  TT: [
    'America/Port_of_Spain'
  ],
  TV: [
    'Pacific/Funafuti'
  ],
  TW: [
    'Asia/Taipei'
  ],
  TZ: [
    'Africa/Dar_es_Salaam'
  ],
  UA: [
    'Europe/Kiev','Europe/Kyiv','Europe/Simferopol','Europe/Uzhgorod','Europe/Zaporozhye'
  ],
  UG: [
    'Africa/Kampala'
  ],
  UM: [
    'Pacific/Johnston','Pacific/Midway','Pacific/Wake'
  ],
  US: [
    'America/Adak','America/Anchorage','America/Atka','America/Boise','America/Chicago','America/Denver','America/Detroit','America/Fort_Wayne','America/Indiana/Indianapolis','America/Indiana/Knox','America/Indiana/Marengo','America/Indiana/Petersburg','America/Indiana/Tell_City','America/Indiana/Vevay','America/Indiana/Vincennes','America/Indiana/Winamac','America/Indianapolis','America/Juneau','America/Kentucky/Louisville','America/Kentucky/Monticello','America/Knox_IN','America/Los_Angeles','America/Louisville','America/Menominee','America/Metlakatla','America/New_York','America/Nome','America/North_Dakota/Beulah','America/North_Dakota/Center','America/North_Dakota/New_Salem','America/Phoenix','America/Shiprock','America/Sitka','America/Yakutat','Navajo','Pacific/Honolulu','US/Alaska','US/Aleutian','US/Arizona','US/Central','US/East-Indiana','US/Eastern','US/Hawaii','US/Indiana-Starke','US/Michigan','US/Mountain','US/Pacific'
  ],
  UY: [
    'America/Montevideo'
  ],
  UZ: [
    'Asia/Samarkand','Asia/Tashkent'
  ],
  VA: [
    'Europe/Vatican'
  ],
  VC: [
    'America/St_Vincent'
  ],
  VE: [
    'America/Caracas'
  ],
  VG: [
    'America/Tortola'
  ],
  VI: [
    'America/St_Thomas','America/Virgin'
  ],
  VN: [
    'Asia/Ho_Chi_Minh','Asia/Saigon'
  ],
  VU: [
    'Pacific/Efate'
  ],
  WF: [
    'Pacific/Wallis'
  ],
  WS: [
    'Pacific/Apia'
  ],
  YE: [
    'Asia/Aden'
  ],
  YT: [
    'Indian/Mayotte'
  ],
  ZA: [
    'Africa/Johannesburg'
  ],
  ZM: [
    'Africa/Lusaka'
  ],
  ZW: [
    'Africa/Harare'
  ],
}

