import {toNumber} from "lodash";
import {useParams} from "react-router-dom";
import {useOrderWrapper} from "../CommonOrderDetailsContext";
import React from "react";
import {useIntl} from "react-intl";
import './container-details.css'
import {YlbIntlLabel} from "../../../../../../_ylb/i18n/YlbIntlLabel";

export const CommonOrderDetailsContainersDocuments = () => {
  const intl = useIntl();
  const {containerIndex} = useParams();
  const orderWrapper = useOrderWrapper();
  const orderDetails = orderWrapper.order;

  const container = orderDetails.containers[toNumber(containerIndex)];

  return (<>
        <div className="card ylb-mini-card h-xl-100">
          <div className="card-header align-items-center border-0 px-5">
            <h3 className="card-title align-items-start flex-column">
          <span className="fw-bold mb-2 text-gray-900">
            <YlbIntlLabel id="CustomerOrderDetails.containerDocumentHeader" doCapitalize={true}/>
          </span>
            </h3>
          </div>

          <div className="card-body p-5 pt-0">
            <span className="text-muted fw-semibold fs-7">
              <YlbIntlLabel id="CustomerOrderDetails.containerDocumentNone" doCapitalize={true}/>.
            </span>
          </div>
        </div>
      </>
  )
}