import {FieldConfig, FormikHelpers} from "formik";
import {YApiErrorResponsePayload} from "../api/YApiResponse";
import _ from "lodash";

// Had to fix formik declaration due to TS error. See https://github.com/jaredpalmer/formik/issues/2127
export type CommonFieldProps<U extends 'input' | 'select' | 'textarea'> =
    JSX.IntrinsicElements[U]
    & FieldConfig<string>
    & {

  // WARNING, if adding a property here, make sur to capture it in custom component before reaching
  // React base components, otherwise React will complain
  label: string
  containerClass?: string
  required?: boolean | undefined
  displaySize?: "small" | "large"
};

export const handleFormErrors = (error: YApiErrorResponsePayload, values: any, actions: FormikHelpers<any>, nameMapper?: (fieldNme: string) => string) => {
  let globalErrors = [];
  for (let fieldName in error.fieldErrors) {
    const errorsForDisplay = error.fieldErrors[fieldName].join(" | ");
    if (nameMapper) {
      fieldName = nameMapper(fieldName);
    }
    if (_.has(values, fieldName)) {
      actions.setFieldError(fieldName, errorsForDisplay);
    } else {
      globalErrors.push("(" + fieldName + ") " + errorsForDisplay);
    }
  }

  // global error handling
  if (error.globalErrors && error.globalErrors.length > 0) {
    globalErrors.push(error.globalErrors);
  }
  actions.setStatus(globalErrors.join(" | "));
}