import React, {PropsWithChildren, useEffect, useState} from "react";
import {NotYetFetched, YFetched} from "../../../../../_ylb/api/YFetched";
import {useParams} from "react-router-dom";
import {createStrictContext} from "../../../../../_ylb/context/ContextHelper";
import {
  adminOrderDetailsEndpoint,
  customerOrderDetailsEndpoint,
  urlEnrich
} from "../../../../../_ylb/api/BackendEndpoints";
import {backendApiRequest} from "../../../../../_ylb/api/YBackendApiClient";
import {YlbIntlLabel} from "../../../../../_ylb/i18n/YlbIntlLabel";
import {isAdmin, useAuth} from "../../../auth";
import {
  ConsumableProductCategory,
  Currency, OrderContainerVoyageStatus,
  OrderPaymentStatus,
  OrderStatus,
  ProductPacking,
  ProductType
} from "../../../../../_ylb/model/BackendReferential";

export type OrderDetailsDto = CustomerOrderDetailsDto & AdminOrderDetailsDto

type CustomerOrderDetailsDto = {
  token: string
  providerDisplayName: string
  status: OrderStatus
  paymentStatus: OrderPaymentStatus
  products: OrderProductDto[]
  containers: OrderContainerDto[]
  currency: Currency
  preOrderPaymentPercent: number
  totalPriceNoTax: number
  totalPriceWithTax: number
  unpaidPreOrderAmountCents: number
  unpaidTotalAmountCents: number
  shippingFrom: string
  shippingTo: string
  departureDate?: Date
  createdAt: Date
}

type AdminOrderDetailsDto = {
  providerToken?: string
  providerName?: string
  nextStatuses?: OrderPaymentStatus[],
  hasProviderProforma?: boolean
}

type OrderProductDto = {
  priceToken: string
  productType: ProductType
  category: ConsumableProductCategory
  caliber: string
  packing: ProductPacking
  packingWeightGrams: number
  pricePerKgCents: number
  totalPriceNoTaxCents: number
  palletCount: number
  boxPerPallet: number
  totalWeightGrams: number
}

type OrderContainerDto = {
  voyageStatus: OrderContainerVoyageStatus
  items: OrderContainerItemDto[]
}

export type OrderContainerItemDto = {
  priceToken: string
  palletCount: number
}

type OrderWrapper = {
  order: OrderDetailsDto,
  reloadOrder: () => void
}

const [CommonOrderDetailsProvider, useOrderWrapper] = createStrictContext<OrderWrapper>();

const CommonOrderDetailsContext = ({children}: PropsWithChildren<{}>) => {
  const [orderReload, setOrderReload] = useState(0);
  const [orderDetails, setOrderDetails] = useState<YFetched<OrderDetailsDto>>(NotYetFetched);
  const {orderToken} = useParams();
  const {currentUser} = useAuth()

  const isCurrentUserAdmin = isAdmin(currentUser!);

  useEffect(() => {
    backendApiRequest<OrderDetailsDto>({
      url: urlEnrich({
        url: isCurrentUserAdmin ? adminOrderDetailsEndpoint : customerOrderDetailsEndpoint,
        orderToken: orderToken
      }),
      onSuccess: (res) => {
        let order = res.data.data!;
        setOrderDetails({
          data: res.data.data!,
          isFetched: true
        })
      }
    });
  }, [orderReload]);

  return <>{
    orderDetails.isFetched ?
        <CommonOrderDetailsProvider value={{
          order: orderDetails.data!,
          reloadOrder: () => setOrderReload(orderReload + 1)
        }}>
          {children}
        </CommonOrderDetailsProvider>
        : <><YlbIntlLabel id="CommonLabels.loading" doCapitalize={true}/>...</>
  }
  </>
}
export {CommonOrderDetailsContext, useOrderWrapper}
