import {OrderStatus} from "../../../../../_ylb/model/BackendReferential";
import {intlReferential} from "../../../../../_ylb/i18n/YlbIntlLabel";
import {useIntl} from "react-intl";

export const CustomerOrderStatusBadge = ({status}: { status: OrderStatus }) => {
  const intl = useIntl();
  const style = (function () {
    switch (status) {
      case "COM":
        return "success";
      case "DIR":
      case "DIS":
        return "warning";
      case "ALL":
      case "CAC":
      case "CRE":
      case "FAD":
      case "FDP":
      case "PAC":
      case "PCR":
      case "PPG":
        return "primary";
      case "CNC":
        return "danger";
      default:
        return "dark";
    }
  })();

  return (<>
    <div className={"badge badge-light-" + style}>{
      intlReferential({
        referential: "CustomerOrderStatusBadge",
        id: status,
        doCapitalize: true,
        intl: intl
      })}
    </div>
  </>)
}