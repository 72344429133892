import React, {useEffect, useState} from "react";
import {backendApiRequest} from "../../../../../_ylb/api/YBackendApiClient";
import {adminOrderListEndpoint} from "../../../../../_ylb/api/BackendEndpoints";
import {KTCard, KTCardBody} from "../../../../../_metronic/helpers";
import {
  Country,
  Currency,
  OrderPaymentStatus,
  OrderStatus
} from "../../../../../_ylb/model/BackendReferential";
import {NotYetFetched, YFetched} from "../../../../../_ylb/api/YFetched";
import {createColumnHelper} from "@tanstack/react-table";
import {YTable} from "../../../../../_ylb/table/YTable";
import moment from "moment";
import {CountryDisplay} from "../../../../../_ylb/helpers/CountryDisplay";
import {numberCentsToString} from "../../../../../_ylb/helpers/numerics";
import {YlbIntlLabel} from "../../../../../_ylb/i18n/YlbIntlLabel";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import {CustomerOrderStatusBadge} from "../../customer/order/CustomerOrderStatusBadge";
import {OrderPaymentStatusBadge} from "../../customer/order/OrderPaymentStatusBadge";

type AdminOrderListItemDto = {
  token: string
  createdAt: Date
  status: OrderStatus
  paymentStatus: OrderPaymentStatus
  providerName: string
  providerDisplayName: string
  customerName: string
  shippingFrom: Country
  shippingTo: Country
  departureDate?: Date
  totalPriceCentsNoTax: number
  currency: Currency
}

type AdminOrderListDto = {
  orders: AdminOrderListItemDto[]
}

export const AdminOrderList = () => {
  const [orderList, setOrderList] = useState<YFetched<AdminOrderListDto>>(NotYetFetched);
  const intl = useIntl();
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<AdminOrderListItemDto>();
  const columns = [
    columnHelper.accessor("createdAt",
        {
          header: () => <span><YlbIntlLabel id="CustomerOrderList.creationDate"
                                            doCapitalize={true}/></span>,
          cell: (context) => moment(context.getValue()).format("DD/MM/yyyy"),
          meta: {
            getCellContext: (context) => {
              return {
                key: context.cell.id,
                className: 'text-center',
              }
            },
          }
        }),
    columnHelper.accessor("status",
        {
          header: () => <span><YlbIntlLabel id="CustomerOrderList.status"
                                            doCapitalize={true}/></span>,
          cell: (context) => <CustomerOrderStatusBadge status={context.getValue()}/>,
          meta: {
            getCellContext: (context) => {
              return {
                key: context.cell.id,
                className: 'text-center',
              }
            },
          }
        }),
    columnHelper.accessor("paymentStatus",
        {
          header: () => <span><YlbIntlLabel id="AdminOrderList.paymentStatus"
                                            doCapitalize={true}/></span>,
          cell: (context) => <OrderPaymentStatusBadge status={context.getValue()}/>,
          meta: {
            getCellContext: (context) => {
              return {
                key: context.cell.id,
                className: 'text-center',
              }
            },
          }
        }),
    columnHelper.accessor("providerName",
        {
          header: () => <span><YlbIntlLabel id="AdminOrderList.providerName"
                                            doCapitalize={true}/></span>,
          cell: (context) => <>{context.getValue()} ({context.row.original.providerDisplayName})</>,
          meta: {
            getCellContext: (context) => {
              return {
                key: context.cell.id,
                className: 'text-start',
              }
            },
            getHeaderContext: (context) => {
              return {
                key: context.header.id,
                className: 'text-start',
              }
            },
          }
        }),
    columnHelper.accessor("customerName",
        {
          header: () => <span><YlbIntlLabel id="AdminOrderList.customerName"
                                            doCapitalize={true}/></span>,
          cell: (context) => context.getValue(),
          meta: {
            getCellContext: (context) => {
              return {
                key: context.cell.id,
                className: 'text-start',
              }
            },
            getHeaderContext: (context) => {
              return {
                key: context.header.id,
                className: 'text-start',
              }
            },
          }
        }),
    columnHelper.accessor("shippingFrom",
        {
          header: () => <span><YlbIntlLabel id="CustomerOrderList.shippingFrom"
                                            doCapitalize={true}/></span>,
          cell: (context) => <CountryDisplay country={context.getValue()}/>,
          meta: {
            getCellContext: (context) => {
              return {
                key: context.cell.id,
                className: 'text-start',
              }
            },
            getHeaderContext: (context) => {
              return {
                key: context.header.id,
                className: 'text-start',
              }
            },
          }
        }),
    columnHelper.accessor("shippingTo",
        {
          header: () => <span><YlbIntlLabel id="AdminOrderList.shippingTo"
                                            doCapitalize={true}/></span>,
          cell: (context) => <CountryDisplay country={context.getValue()}/>,
          meta: {
            getCellContext: (context) => {
              return {
                key: context.cell.id,
                className: 'text-start',
              }
            },
            getHeaderContext: (context) => {
              return {
                key: context.header.id,
                className: 'text-start',
              }
            },
          }
        }),
    columnHelper.accessor("totalPriceCentsNoTax",
        {
          header: () => <span><YlbIntlLabel id="CustomerOrderList.totalNoTax" doCapitalize={true}/></span>,
          cell: (context) =>
              <>{numberCentsToString(context.getValue())}&nbsp;{context.row.original.currency}</>,
          meta: {
            getCellContext: (context) => {
              return {
                key: context.cell.id,
                className: 'text-end',
              }
            },
            getHeaderContext: (context) => {
              return {
                key: context.header.id,
                className: 'text-end',
              }
            },
          }
        }),
  ];

  useEffect(() => {
    backendApiRequest<AdminOrderListDto>({
      url: adminOrderListEndpoint,
      onSuccess: (response) => setOrderList({
        isFetched: true,
        data: response.data.data!
      })
    });
  }, []);

  return (
      <KTCard>
        <KTCardBody className='py-4'> {
          orderList.isFetched ? orderList.data!.orders.length > 0 ? (
              <>
                <YTable data={orderList.data!.orders} columns={columns}
                        onClickRows={(e, row) =>
                            navigate("/admin/order/" + row.original.token)}/>
              </>) : (
              <>
                <YlbIntlLabel id="CustomerOrderList.noOrders" doCapitalize={true}/>.
              </>
          ) : (
              <>
                <YlbIntlLabel id="CommonLabels.loading" doCapitalize={true}/>...
              </>
          )
        }
        </KTCardBody>
      </KTCard>
  )

}