import {toNumber} from "lodash";
import {useParams} from "react-router-dom";
import {
  OrderContainerItemDto,
  useOrderWrapper
} from "../CommonOrderDetailsContext";
import React from "react";
import {createColumnHelper} from "@tanstack/react-table";
import {YlbIntlLabel} from "../../../../../../_ylb/i18n/YlbIntlLabel";
import {productDescription} from "../../../../../../_ylb/helpers/ProductDescription";
import {useIntl} from "react-intl";
import {YTable} from "../../../../../../_ylb/table/YTable";
import './container-details.css'

export const CommonOrderDetailsContainersProduct = () => {
  const intl = useIntl();
  const {containerIndex} = useParams();
  const orderWrapper = useOrderWrapper();
  const orderDetails = orderWrapper.order;

  const container = orderDetails.containers[toNumber(containerIndex)];
  const columnHelper = createColumnHelper<OrderContainerItemDto>();

  const columns = [
    columnHelper.display({
      id: 'description',
      header: () => <span>
        <YlbIntlLabel id="CustomerOrderDetails.containerProductTableHeaderProduct"
                      doCapitalize={true}/>
      </span>,
      cell: context => {
        const product = orderDetails.products.filter(product => product.priceToken === context.row.original.priceToken).find(x => x !== undefined);
        return productDescription(intl,
            product!.productType,
            product!.category,
            product!.caliber,
            product!.packing,
            product!.packingWeightGrams)
      },
    }),
    columnHelper.accessor("palletCount",
        {
          header: () => <span>
        <YlbIntlLabel id="CustomerOrderDetails.palletCount" doCapitalize={true}/>
      </span>,
          cell: (context) => context.getValue(),
          meta: {
            getCellContext: (context) => {
              return {
                key: context.cell.id,
                className: 'text-end',
              }
            },
            getHeaderContext: (context) => {
              return {
                key: context.header.id,
                className: 'text-end',
              }
            },
          }
        }),
  ];

  return (<>
    <div className="card ylb-mini-card h-xl-100">
      <div className="card-header align-items-center border-0 px-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="fw-bold mb-2 text-gray-900">
            <YlbIntlLabel id="CustomerOrderDetails.containerProductHeader" doCapitalize={true}/>
          </span>
        </h3>
      </div>

      <div className="card-body p-0 pb-5">
        <YTable data={container.items} columns={columns}/>
      </div>
    </div>
  </>)
}