import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {NotYetFetched, YFetched} from "../../../../../../../_ylb/api/YFetched";
import {ProviderProductData} from "./AdminProviderProductUpdate";
import {
  adminProviderShowProviderProductEndpoint,
  urlEnrich
} from "../../../../../../../_ylb/api/BackendEndpoints";
import {mapProductDtoToInternal, ProductDetailsDto} from "./AdminProviderProductEdit";
import {ProvideRecapHeader} from "../ProviderRecapHeader";
import {backendApiRequest} from "../../../../../../../_ylb/api/YBackendApiClient";
import {useIntl} from "react-intl";

export const AdminProviderProductShow = () => {
  const {providerToken, productToken} = useParams();
  const [productData, setProductData] = useState<YFetched<ProviderProductData>>(NotYetFetched);
  const intl = useIntl();

  useEffect(() => {
        backendApiRequest<ProductDetailsDto>({
          url: urlEnrich({
            url: adminProviderShowProviderProductEndpoint,
            providerToken: providerToken,
            productToken: productToken
          }),
          onSuccess: (res) => {
            const productDetailsDto = res.data.data!;
            setProductData({
              data: mapProductDtoToInternal(productDetailsDto, intl),
              isFetched: true
            })
          }
        });
      }, [productToken]
  );

  return (
      <>{
        productData.isFetched ?
            <>
              <ProvideRecapHeader/>
              <div className="row">
                <div className="col-4">
                  <div className="card-rounded bg-body position-relative card-px py-10">
                    <div className="row g-0 mb-7">
                      <div className="col mx-5">
                        <div className="fs-6 text-gray-400">
                          Avarage Sale
                        </div>
                        <div className="fs-2 fw-bold text-gray-800">
                          XXX
                        </div>
                      </div>
                      <div className="col mx-5">
                        <div className="fs-6 text-gray-400">
                          Comissions
                        </div>
                        <div className="fs-2 fw-bold text-gray-800">
                          XXX
                        </div>
                      </div>
                    </div>
                    <div className="row g-0">
                      <div className="col mx-5">
                        <div className="fs-6 text-gray-400">
                          Revenue
                        </div>
                        <div className="fs-2 fw-bold text-gray-800">
                          XXX
                        </div>
                      </div>
                      <div className="col mx-5">
                        <div className="fs-6 text-gray-400">
                          Expenses
                        </div>
                        <div className="fs-2 fw-bold text-gray-800">
                          XXX
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
            : <></>
      }</>
  )
}