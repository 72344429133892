import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {AdminCustomerUpdate, CustomerData} from "./AdminCustomerUpdate";
import {NotYetFetched, YFetched} from "../../../../../_ylb/api/YFetched";
import {backendApiRequest} from "../../../../../_ylb/api/YBackendApiClient";
import {adminCustomerDetailsEndpoint, urlEnrich} from "../../../../../_ylb/api/BackendEndpoints";

export const AdminCustomerEdit = () => {
  const {customerToken} = useParams();
  const [customerData, setCustomerData] = useState<YFetched<CustomerData>>(NotYetFetched);

  useEffect(() => {
        backendApiRequest<CustomerData>({
          url: urlEnrich({
            url: adminCustomerDetailsEndpoint,
            customerToken: customerToken
          }),
          onSuccess: (res) => {
            setCustomerData({
              data: res.data.data!,
              isFetched: true
            })
          }
        });
      }, [customerToken]
  );

  return <>{
    customerData.isFetched ?
        <>
          <AdminCustomerUpdate customerData={customerData.data}/>
        </>
        : <></>
  }</>
}