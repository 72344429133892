import React from "react";
import clsx from "clsx";
import {YlbIntlLabel} from "../i18n/YlbIntlLabel";

type Props = {
  label: string
  className?: string | undefined
  required?: boolean | undefined
};

const YFormLabel = ({label, required}: Props) => {
  return (
      <>
        <label className={clsx('form-label fs-6 fw-bold',
            required ? 'required' : '')}>
          <YlbIntlLabel id={label} doCapitalize={true}/>
        </label>
      </>
  );
}

export default YFormLabel;