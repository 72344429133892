import {Country, PlatformAsset} from "../../../../../_ylb/model/BackendReferential";
import {in7Days} from "../../../../../_ylb/helpers/date";
import {stringToCents} from "../../../../../_ylb/helpers/numerics";
import {backendApiRequest} from "../../../../../_ylb/api/YBackendApiClient";
import {adminConfigMarginRuleUpdateEndpoint} from "../../../../../_ylb/api/BackendEndpoints";
import {toast} from "react-toastify";
import {KTCard, KTCardBody} from "../../../../../_metronic/helpers";
import {YSelectCountries} from "../../../../../_ylb/form/YSelectCountries";
import {YSelectReferential} from "../../../../../_ylb/form/YSelectReferential";
import {YInputGroup} from "../../../../../_ylb/form/YInputGroup";
import {YInputDatePicker} from "../../../../../_ylb/form/YInputDatePicker";
import YForm from "../../../../../_ylb/form/YForm";
import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {YSelectObjectBase} from "../../../../../_ylb/form/YSelectObjectBase";
import {useFormikContext} from "formik";
import {getClosestUri} from "../../../../../_ylb/routing/YRoutingHelper";
import {handleFormErrors} from "../../../../../_ylb/form/_form_helpers";

type MarginType = "GLOBAL" | "COUNTRY"

export type MarginRuleData = {
  id?: number
  type: MarginType
  destinationCountry?: Country
  assetType: PlatformAsset
  assetValue: string
  marginPercentDisplay: string
  expiresAt: Date
}

const marginRuleInitialValues: MarginRuleData = {
  id: undefined,
  type: "GLOBAL",
  destinationCountry: undefined,
  assetType: "PFA",
  assetValue: "1",
  marginPercentDisplay: "0",
  expiresAt: in7Days()
}

const DestinationCountrySelector = ({isExistingMarginRule}: { isExistingMarginRule: boolean }) => {
  const {values} = useFormikContext<MarginRuleData>();

  return (<>
        {
          values.type === "COUNTRY" ?
              <YSelectCountries label="Destination country"
                                name="destinationCountry"
                                containerClass="col-6"
                                disabled={isExistingMarginRule}
                                showDefault={true}/> : <></>
        }</>
  )
}

const AssetValueSelector = ({isExistingMarginRule}: { isExistingMarginRule: boolean }) => {
  const {values, setFieldValue} = useFormikContext<MarginRuleData>();

  return (<>
        {
          values.assetType === "PFA" ?
              <YSelectReferential label="Target value"
                                  name="assetValue"
                                  containerClass="col-6"
                                  required={true}
                                  disabled={isExistingMarginRule}
                                  referential="ProductFamily"/> : <></>
        }
        {
          values.assetType === "PMT" ?
              <YSelectReferential label="Target value"
                                  name="assetValue"
                                  containerClass="col-6"
                                  required={true}
                                  disabled={isExistingMarginRule}
                                  referential="ProductMetaType"/> : <></>
        }
        {
          values.assetType === "PTY" ?
              <YSelectReferential label="Target value"
                                  name="assetValue"
                                  containerClass="col-6"
                                  required={true}
                                  disabled={isExistingMarginRule}
                                  referential="ProductType"/> : <></>
        }
      </>
  )
}

export const AdminConfigMarginRuleUpdate = ({marginRuleData}: {
  marginRuleData?: MarginRuleData
}) => {
  const {marginRuleId} = useParams();
  const navigate = useNavigate();

  let isExistingMarginRule = false;
  if (marginRuleData) {
    isExistingMarginRule = true;
  }

  const marginRuleValues = marginRuleData ?? marginRuleInitialValues;

  return (<>
    <YForm initialValues={marginRuleValues}
           onSubmit={(values, actions) => {
             let payload = {
               ...values,
               marginPercentInCents: stringToCents(values.marginPercentDisplay)
             };

             backendApiRequest<string>({
               url: adminConfigMarginRuleUpdateEndpoint,
               method: "POST",
               data: payload,
               onSuccess: response => {
                 actions.setSubmitting(false);
                 toast.success("Margin rule successfully saved.");
                 navigate(getClosestUri(window.location.pathname, "/marginrule"));
               },
               onError: error => {
                 handleFormErrors(error.response?.data.payload!, values, actions,
                     fieldName => {
                       if (fieldName === "marginPercentInCents") {
                         return "marginPercentDisplay"
                       }
                       return fieldName;
                     });
                 actions.setSubmitting(false);
               }
             });
           }}>
      <div className="row">
        <div className="col-6">
          <KTCard>
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold text-dark">
                      {isExistingMarginRule ? "Editing margin rule: " + marginRuleId : "New margin rule"}
                    </span>
              </h3>
            </div>
            <KTCardBody>
              <div className="row pb-5">
                <YSelectObjectBase label="Margin type"
                                   name="type"
                                   options={[{
                                     label: "For all countries",
                                     value: "GLOBAL"
                                   }, {
                                     label: "For a specific country",
                                     value: "COUNTRY"
                                   }]}
                                   containerClass="col-6"
                                   disabled={isExistingMarginRule}
                                   required={true}/>
                <DestinationCountrySelector isExistingMarginRule={isExistingMarginRule}/>
              </div>
              <div className="row pb-5">
                <YSelectReferential label="Target type"
                                    name="assetType"
                                    containerClass="col-6"
                                    referential="PlatformAsset"
                                    required={true}
                                    disabled={isExistingMarginRule}
                                    keepOnly={["PFA", "PMT", "PTY"]}/>

                <AssetValueSelector isExistingMarginRule={isExistingMarginRule}/>
              </div>
              {isExistingMarginRule ?
                  <div
                      className="row py-3 mb-5 bg-light-warning border-warning border border-dashed d-flex align-items-center text-center">
                        <span>
                          <i className='fas fa-regular fa-up-long ms-2 fs-3'></i>&nbsp;
                          Attributes above cannot be modified after margin creation.
                          <i className='fas fa-regular fa-up-long ms-2 fs-3'></i>
                        </span>
                  </div>
                  : <></>}
              <div className="row">
                <YInputGroup
                    rightLabel="%"
                    label="Margin percent"
                    name="marginPercentDisplay"
                    type="text"
                    containerClass="col-md-6"
                    className="text-end"
                    required={true}/>
                <YInputDatePicker name="expiresAt"
                                  label="Margin expires at"
                                  containerClass="col-md-6"
                                  required={true}/>
              </div>
            </KTCardBody>
          </KTCard>
          <div className="d-flex justify-content-end mt-10 mb-15">
            <button type="button" className="btn btn-light me-5"
                    onClick={() => navigate(-1)}>
              Cancel
            </button>
            <button type="submit" className="btn btn-primary"
                    data-kt-users-modal-action="submit">
                  <span className="indicator-label">
                    <i className='fas fa-floppy-disk ms-2 fs-5'></i> Save
                  </span>
            </button>
          </div>
        </div>
      </div>
    </YForm>
  </>)
}