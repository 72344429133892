import {FC} from 'react'
import {IntlProvider} from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/de'
import '@formatjs/intl-relativetimeformat/locale-data/es'
import '@formatjs/intl-relativetimeformat/locale-data/fr'
import '@formatjs/intl-relativetimeformat/locale-data/ja'
import '@formatjs/intl-relativetimeformat/locale-data/zh'

import enMessages from './messages/en_messages.json'
import frMessages from './messages/fr_messages.json'
import frCountries from './messages/fr_countries.json'
import {WithChildren} from "../../_metronic/helpers";
import {useLang} from "./YlbI18n";

const allMessages = {
  en: enMessages,
  es: enMessages,
  fr: {
    ...frMessages,
    ...frCountries
  },
}

const YlbI18nProvider: FC<WithChildren> = ({children}) => {
  const locale = useLang()
  const messages = allMessages[locale]

  return (
      <IntlProvider locale={locale} messages={messages}
                    // we ignore errors from missing labels
                    onError={()=>{}}>
        {children}
      </IntlProvider>
  )
}

export {YlbI18nProvider}
