import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {YlbI18nProvider} from "../_ylb/i18n/YlbI18nProvider";
import {YNotificationManager} from "../_ylb/page/YNotificationManager";

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <YlbI18nProvider>
        <LayoutProvider>
          <YNotificationManager/>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </YlbI18nProvider>
    </Suspense>
  )
}

export {App}
