import React, {useEffect, useState} from "react";
import {KTCard, KTCardBody} from "../../../../../../_metronic/helpers";
import {adminUserListEndpoint} from "../../../../../../_ylb/api/BackendEndpoints";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {backendApiRequest} from "../../../../../../_ylb/api/YBackendApiClient";
import {YTable} from "../../../../../../_ylb/table/YTable";
import {createColumnHelper} from "@tanstack/react-table";
import {getClosestUri} from "../../../../../../_ylb/routing/YRoutingHelper";
import {NotYetFetched, YFetched} from "../../../../../../_ylb/api/YFetched";

type UserListItem = {
  token: string
  email: string
  firstName: string
  lastName: string
}

const columnHelper = createColumnHelper<UserListItem>();

export const PlatformUserList = () => {
  const [userList, setUserList] = useState<YFetched<UserListItem[]>>(NotYetFetched);
  const navigate = useNavigate();
  const location = useLocation();
  const {customerToken, providerToken} = useParams();
  const queryParam = (customerToken !== undefined ? ['customerToken', customerToken!] : ['providerToken', providerToken!]);

  const columns = [
    columnHelper.accessor("email",
        {
          header: () => <span>Email</span>,
          cell: (context) => context.getValue(),
          meta: {
            getCellContext: (context) => {
              return {
                key: context.cell.id,
                className: 'text-start',
              }
            },
            getHeaderContext: (context) => {
              return {
                key: context.header.id,
                className: 'text-start',
              }
            },
          }
        }),
    columnHelper.accessor("firstName",
        {
          header: () => <span>First name</span>,
          cell: (context) => context.getValue(),
          meta: {
            getCellContext: (context) => {
              return {
                key: context.cell.id,
                className: 'text-start',
              }
            },
            getHeaderContext: (context) => {
              return {
                key: context.header.id,
                className: 'text-start',
              }
            },
          }
        }),
    columnHelper.accessor("lastName",
        {
          header: () => <span>Last name</span>,
          cell: (context) => context.getValue(),
          meta: {
            getCellContext: (context) => {
              return {
                key: context.cell.id,
                className: 'text-start',
              }
            },
            getHeaderContext: (context) => {
              return {
                key: context.header.id,
                className: 'text-start',
              }
            },
          }
        })
  ];

  useEffect(() => {
    backendApiRequest<UserListItem[]>({
      url: adminUserListEndpoint + "?" + new URLSearchParams([queryParam]).toString(),
      onSuccess: (response) => setUserList({
        data: response.data.data!,
        isFetched: true
      })
    });
  }, []);

  return (
      <KTCard>
        <KTCardBody className='py-4'>
          {userList.isFetched ? <>
                {userList.data!.length > 0 ?
                    <>
                      <YTable
                          data={userList.data!}
                          columns={columns}
                          onClickRows={(e, row) => {
                            navigate(getClosestUri(location.pathname, "/user") + "/" + row.original.token)
                          }}/>
                    </>
                    : <>
                      No user found.
                      <br/>
                    </>}
                <button className="btn btn-primary btn-sm mt-10" onClick={() => {
                  navigate("new");
                }}>
                  <i className="las la-plus-circle fs-4 me-2"></i> New user
                </button>
              </>
              : <>
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>&nbsp;
                Loading...
              </>}
        </KTCardBody>
      </KTCard>
  )
}