import {YlbIntlLabel} from "../i18n/YlbIntlLabel";
import {CountryFlag} from "./CountryFlag";
import React from "react";
import {Country} from "../model/BackendReferential";

export const CountryDisplay = ({country, showLabel = true, showFlag = true}: {
  country: Country
  showLabel?: boolean
  showFlag?: boolean
}) => {
  return (
      <>
        {showLabel && <YlbIntlLabel referential="country" id={country}/>}
        {showFlag && <>{showLabel && <>&nbsp;&nbsp;</>} <CountryFlag country={country}/></>}
      </>
  )
}