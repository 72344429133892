import _ from "lodash";
import {YOption, YSelect, YSelectBaseProps} from "./YSelect";

export type YSelectObjectBaseProps = YSelectBaseProps & {
  atFirst?: string[]
  keepOnly?: string[]
  additionalOptions?: YOption[]
}

export const YSelectObjectBase = ({
                                    options,
                                    atFirst,
                                    additionalOptions,
                                    keepOnly,
                                    ...props
                                  }: YSelectObjectBaseProps & {
  options: YOption[]
}) => {
  let mergedOptions = [...options];
  // remove first to avoid duplicates
  let additionalOptionValues = (additionalOptions ?? []).map(option => option.value);
  mergedOptions = mergedOptions.filter(option => !additionalOptionValues.includes(option.value));
  mergedOptions = mergedOptions.concat(additionalOptions ?? []);
  mergedOptions.sort((x, y) => x.label.localeCompare(y.label));
  if (atFirst && atFirst.length > 0) {
    let optionMap = _.keyBy(mergedOptions, option => option.value);
    mergedOptions = atFirst.map(val => optionMap[val]).concat(
        mergedOptions.filter(option => !atFirst.includes(option.value)));
  }
  if (keepOnly && keepOnly.length > 0) {
    mergedOptions = mergedOptions.filter(option => keepOnly.includes(option.value));
  }

  return (
      <YSelect options={mergedOptions} {...props}/>
  );
}