import {Navigate, Outlet, Route, Routes} from "react-router-dom";
import {CommonOrderDetailsHeader} from "./CommonOrderDetailsHeader";
import {CustomerOrderDetailsOverview} from "./CustomerOrderDetailsOverview";
import {CommonOrderDetailsBilling} from "./CommonOrderDetailsBilling";
import {CommonOrderDetailsContext} from "./CommonOrderDetailsContext";
import CommonOrderDetailsContainerRoutes from "./container/CommonOrderDetailsContainerRoutes";
import {AdminOrderDetailsOverview} from "./AdminOrderDetailsOverview";
import {isAdmin, useAuth} from "../../../auth";

const CommonOrderDetailsRoutes: React.FC = () => {
  const {currentUser} = useAuth()

  const isCurrentUserAdmin = isAdmin(currentUser!);

  return (
      <Routes>
        <Route
            element={
              <>
                <CommonOrderDetailsContext>
                  <CommonOrderDetailsHeader/>
                  <Outlet/>
                </CommonOrderDetailsContext>
              </>
            }
        >
          {isCurrentUserAdmin && <Route
              path='admin-overview'
              element={
                <>
                  <AdminOrderDetailsOverview/>
                </>
              }
          />}
          <Route
              path='overview'
              element={
                <>
                  <CustomerOrderDetailsOverview/>
                </>
              }
          />
          <Route
              path='containers/:containerIndex/*'
              element={
                <>
                  <CommonOrderDetailsContainerRoutes/>
                </>
              }
          />
          <Route
              path='billing'
              element={
                <>
                  <CommonOrderDetailsBilling/>
                </>
              }
          />
          {isCurrentUserAdmin ? <Route index element={<Navigate to='admin-overview'/>}/>
              : <Route index element={<Navigate to='overview'/>}/>}
        </Route>
      </Routes>
  )
}

export default CommonOrderDetailsRoutes