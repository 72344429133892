import {UserModel} from "../../app/modules/auth";

export const getClosestUri = (location: string, uriStub: string) => {
  const uriIndex = location.lastIndexOf(uriStub);
  if (uriIndex >= 0) {
    return location.substring(0, uriIndex) + uriStub;
  }
  return "/";
}

export const getHomePageUri = (currentUser?: UserModel) => {
  if (currentUser) {
    if (currentUser.scopes.includes("ROLE_PROVIDER")) {
      return '/notyet';
    } else if (currentUser.scopes.includes("ROLE_CUSTOMER")) {
      return '/customer/product';
    } else if (currentUser.scopes.includes("ROLE_ADMIN")) {
      return '/admin/provider';
    }
  }
  return "404";
}