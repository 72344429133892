import {Country, PlatformAsset} from "../../../../../_ylb/model/BackendReferential";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {NotYetFetched, YFetched} from "../../../../../_ylb/api/YFetched";
import {backendApiRequest} from "../../../../../_ylb/api/YBackendApiClient";
import {
  adminConfigMarginRuleDetailsEndpoint,
  urlEnrich
} from "../../../../../_ylb/api/BackendEndpoints";
import {numberCentsToString} from "../../../../../_ylb/helpers/numerics";
import {AdminConfigMarginRuleUpdate, MarginRuleData} from "./AdminConfigMarginRuleUpdate";

type MarginRuleDto = {
  id: number
  destinationCountry?: Country
  assetType: PlatformAsset
  assetValue: string
  marginPercentInCents: number
  expiresAt: Date
}

export const AdminConfigMarginRuleEdit = () => {
  const {marginRuleId} = useParams();
  const [marginRuleData, setMarginRuleData] = useState<YFetched<MarginRuleData>>(NotYetFetched);

  useEffect(() => {
        backendApiRequest<MarginRuleDto>({
          url: urlEnrich({
            url: adminConfigMarginRuleDetailsEndpoint,
            marginRuleId: parseInt(marginRuleId!, 10)
          }),
          onSuccess: (res) => {
            const marginRuleDtoData = res.data.data!;
            setMarginRuleData({
              data: {
                ...marginRuleDtoData,
                type: marginRuleDtoData.destinationCountry ? "COUNTRY" : "GLOBAL",
                marginPercentDisplay: numberCentsToString(marginRuleDtoData.marginPercentInCents)
              },
              isFetched: true
            })
          }
        });
      }, [marginRuleId]
  );

  return <>{
      marginRuleData.isFetched &&
      <AdminConfigMarginRuleUpdate marginRuleData={marginRuleData.data}/>
  }</>
}