import {Carrier, Country, TransportHub} from "../../../../../_ylb/model/BackendReferential";
import React, {useEffect, useState} from "react";
import {NotYetFetched, YFetched} from "../../../../../_ylb/api/YFetched";
import {backendApiRequest} from "../../../../../_ylb/api/YBackendApiClient";
import {adminConfigTransportQuotationListEndpoint} from "../../../../../_ylb/api/BackendEndpoints";
import {KTCard, KTCardBody} from "../../../../../_metronic/helpers";
import {YTable} from "../../../../../_ylb/table/YTable";
import {createColumnHelper} from "@tanstack/react-table";
import {CountryDisplay} from "../../../../../_ylb/helpers/CountryDisplay";
import {intlReferential} from "../../../../../_ylb/i18n/YlbIntlLabel";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import {numberCentsToString} from "../../../../../_ylb/helpers/numerics";
import moment from "moment";

type TransportQuotationListDto = {
  quotations: TransportQuotationListItemDto[]
}

type TransportQuotationListItemDto = {
  id: number
  carrier: Carrier
  fromHub: TransportHub
  toHub: TransportHub
  pricePerContainerEurCents: number
  pricePerContainerUsdCents: number
  expiresAt: Date
}

const columnHelper = createColumnHelper<TransportQuotationListItemDto>();

export const AdminConfigTransportQuotationList = () => {
  const [transportQuotationList, setTransportQuotationList] = useState<YFetched<TransportQuotationListDto>>(NotYetFetched);
  const intl = useIntl();
  const navigate = useNavigate();

  const refreshTransportQuotations = () => {
    backendApiRequest<TransportQuotationListDto>({
      url: adminConfigTransportQuotationListEndpoint,
      onSuccess: (response) => {
        response.data.data!.quotations.sort((a, b) => {
          if (a.fromHub === b.fromHub) {
            return a.toHub.localeCompare(b.toHub)
          } else {
            return a.fromHub.localeCompare(b.toHub)
          }
        });

        setTransportQuotationList({
          isFetched: true,
          data: response.data.data!
        });
      }
    });
  }

  const tableColumns = [
    columnHelper.accessor("carrier",
        {
          header: () => <span>Carrier</span>,
          cell: (context) => intlReferential({
            referential: "Carrier",
            id: context.getValue(),
            intl: intl
          }),
          meta: {
            getCellContext: (context) => {
              return {
                key: context.cell.id,
                className: 'text-center',
              }
            },
            getHeaderContext: (context) => {
              return {
                key: context.header.id,
                className: 'text-center',
              }
            },
          }
        }),
    columnHelper.accessor("fromHub",
        {
          header: () => <span>From</span>,
          cell: (context) => <><CountryDisplay
              country={context.getValue().substring(0, 2) as Country}/>&nbsp;({context.getValue()})</>,
          meta: {
            getCellContext: (context) => {
              return {
                key: context.cell.id,
                className: 'text-start',
              }
            },
            getHeaderContext: (context) => {
              return {
                key: context.header.id,
                className: 'text-start',
              }
            },
          }
        }),
    columnHelper.accessor("toHub",
        {
          header: () => <span>To</span>,
          cell: (context) => <><CountryDisplay
              country={context.getValue().substring(0, 2) as Country}/>&nbsp;({context.getValue()})</>,
          meta: {
            getCellContext: (context) => {
              return {
                key: context.cell.id,
                className: 'text-start',
              }
            },
            getHeaderContext: (context) => {
              return {
                key: context.header.id,
                className: 'text-start',
              }
            },
          }
        }),
    columnHelper.accessor("pricePerContainerUsdCents",
        {
          header: () => <span>Container price (USD)</span>,
          cell: (context) => numberCentsToString(context.getValue(), ",", false) + " USD",
          meta: {
            getCellContext: (context) => {
              return {
                key: context.cell.id,
                className: 'text-end',
              }
            },
            getHeaderContext: (context) => {
              return {
                key: context.header.id,
                className: 'text-end',
              }
            },
          }
        }),
    columnHelper.accessor("pricePerContainerEurCents",
        {
          header: () => <span>Container price (EUR)</span>,
          cell: (context) => numberCentsToString(context.getValue(), ",", false) + " EUR",
          meta: {
            getCellContext: (context) => {
              return {
                key: context.cell.id,
                className: 'text-end',
              }
            },
            getHeaderContext: (context) => {
              return {
                key: context.header.id,
                className: 'text-end',
              }
            },
          }
        }),
    columnHelper.accessor("expiresAt",
        {
          header: () => <span>Expiration</span>,
          cell: (context) => moment(context.getValue()).format("DD/MM/yyyy"),
          meta: {
            getCellContext: (context) => {
              return {
                key: context.cell.id,
                className: 'text-start',
              }
            },
            getHeaderContext: (context) => {
              return {
                key: context.header.id,
                className: 'text-start',
              }
            },
          }
        })
  ];

  useEffect(refreshTransportQuotations, []);

  return (
      <>
        <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
          <KTCard>
            <div className="card-header">
              <div className="card-title">
                <h2>Transport quotations</h2>
              </div>
            </div>
            <KTCardBody className='py-4'> {
              transportQuotationList.isFetched ? transportQuotationList.data!.quotations.length > 0 ?
                      <>
                        <YTable
                            data={transportQuotationList.data!.quotations}
                            columns={tableColumns}/>
                      </>
                      : <>
                        No transport quotation found.
                      </>
                  : <>
              <span
                  className='spinner-border spinner-border-sm align-middle ms-2'></span>&nbsp;
                    Loading...
                  </>
            }
            </KTCardBody>
          </KTCard>
        </div>
      </>
  )
}