import React, {useEffect, useState} from "react";
import {KTCard, KTCardBody} from "../../../../../_metronic/helpers";
import {Country, Incoterm, ProviderStatus} from "../../../../../_ylb/model/BackendReferential";
import {adminProviderListEndpoint} from "../../../../../_ylb/api/BackendEndpoints";
import {Link, useNavigate} from "react-router-dom";
import {ProviderStatusDisplay} from "../../../../../_ylb/model/provider/ProviderStatusDisplay";
import {backendApiRequest} from "../../../../../_ylb/api/YBackendApiClient";
import {CountryDisplay} from "../../../../../_ylb/helpers/CountryDisplay";
import {NotYetFetched, YFetched} from "../../../../../_ylb/api/YFetched";
import {createColumnHelper} from "@tanstack/react-table";
import {intlReferential} from "../../../../../_ylb/i18n/YlbIntlLabel";
import {useIntl} from "react-intl";
import {YTable} from "../../../../../_ylb/table/YTable";

type ProviderList = {
  providers: ProviderListItem[]
}

type ProviderListItem = {
  token: string
  name: string
  displayName: string
  incoterm: Incoterm
  status: ProviderStatus
  country: Country
  activeProducts: number
}

export const AdminProviderList = () => {
  const [providerList, setProviderList] = useState<YFetched<ProviderList>>(NotYetFetched);
  const navigate = useNavigate();
  const intl = useIntl();

  const columnHelper = createColumnHelper<ProviderListItem>();
  const columns = [
    columnHelper.accessor("status",
        {
          header: () => "Status",
          cell: (context) => <ProviderStatusDisplay status={context.getValue()}/>,
        }),
    columnHelper.accessor((originalRow) =>
            intlReferential({referential: "country", id: originalRow.country, intl: intl}),
        {
          id: "country",
          header: () => "Country",
          cell: (context) =>
              <CountryDisplay country={context.row.original.country}/>,
          meta: {
            getCellContext: (context) => {
              return {
                key: context.cell.id,
                className: 'text-start',
              }
            },
            getHeaderContext: (context) => {
              return {
                key: context.header.id,
                className: 'text-start',
              }
            },
          }
        }),
    columnHelper.accessor("name",
        {
          header: () => "Real name",
          cell: (context) => context.getValue(),
          meta: {
            getCellContext: (context) => {
              return {
                key: context.cell.id,
                className: 'text-start',
              }
            },
            getHeaderContext: (context) => {
              return {
                key: context.header.id,
                className: 'text-start',
              }
            },
          }
        }),
    columnHelper.accessor("displayName",
        {
          header: () => "Display name",
          cell: (context) => context.getValue(),
          meta: {
            getCellContext: (context) => {
              return {
                key: context.cell.id,
                className: 'text-start',
              }
            },
            getHeaderContext: (context) => {
              return {
                key: context.header.id,
                className: 'text-start',
              }
            },
          }
        }),
    columnHelper.accessor("incoterm",
        {
          header: () => "Default incoterm",
          cell: (context) => context.getValue(),
        }),
    columnHelper.accessor("activeProducts",
        {
          header: () => "Active products",
          cell: (context) => context.getValue(),
        }),
    columnHelper.display({
      id: 'actions',
      header: () => "Actions",
      cell: context => <>
        <Link to={`${context.row.original.token}/edit`}>
          <i
              className='fas fa-regular fa-pen-to-square ms-2 fs-3'
              data-bs-toggle='tooltip'
              title='Edit provider'
          ></i>
        </Link>
        <Link to={`${context.row.original.token}/product`}>
          <i
              className='fas fa-border fa-barcode ms-2 fs-3'
              data-bs-toggle='tooltip'
              title='List products'
          ></i>
        </Link>
      </>,
    }),
  ];

  useEffect(() => {
    backendApiRequest<ProviderList>({
      url: adminProviderListEndpoint,
      onSuccess: (response) => setProviderList({
        data: response.data.data!,
        isFetched: true
      })
    });
  }, []);

  return (
      <KTCard>
        <KTCardBody className='py-4'>
          {providerList.isFetched ?
              <>
                {providerList.data!.providers.length > 0 ?
                    <YTable data={providerList.data!.providers} columns={columns}/>
                    : <>
                      No provider found!
                    </>}
                <button className="btn btn-primary btn-sm" onClick={() => {
                  navigate("../provider/new");
                }}>
                  <i className="las la-plus-circle fs-4 me-2"></i> New provider
                </button>
              </>
              : <>
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>&nbsp;
                Loading...
              </>}
        </KTCardBody>
      </KTCard>
  )
}