import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
import {YApiErrorResponse, YApiResponse, YApiSuccessResponse} from "./YApiResponse";
import {toast} from "react-toastify";

type BackendApiRequest<RESPONSE, REQUEST> = {
  method?: "GET" | "POST" | "DELETE"
  url: string
  data?: REQUEST
  initialTimeout?: number
  onSuccess?: (response: AxiosResponse<YApiSuccessResponse<RESPONSE>>) => void
  onError?: (error: AxiosError<YApiErrorResponse>) => void
}

/**
 * response is before request as there is always a response type but not always a body
 */
export const backendApiRequest = <RESPONSE, REQUEST = any>({
                                                             method = "GET",
                                                             url,
                                                             data,
                                                             initialTimeout = 400,
                                                             onSuccess,
                                                             onError
                                                           }: BackendApiRequest<RESPONSE, REQUEST>) => {
  const onErr = onError ?? ((error: AxiosError<YApiErrorResponse>) => {
    toast.error("Error while contacting server.");
  });

  const onSuccessWrapper = (response: AxiosResponse<YApiResponse<RESPONSE>>) => {
    if (!response.data.hasErrors) {
      onSuccess && onSuccess(response as AxiosResponse<YApiSuccessResponse<RESPONSE>>);
    } else {
      onErr && onErr({
        name: "",
        message: "",
        config: response.config,
        request: response.request,
        // response: response,
        isAxiosError: true,
        toJSON: () => {
          return {}
        }
      });
    }
  }

  setTimeout(() => {
    switch (method) {
      case "POST":
        axios.post<YApiResponse<RESPONSE>, AxiosResponse<YApiResponse<RESPONSE>>, REQUEST>(url, data)
        .then(onSuccessWrapper)
        .catch(onErr);
        break;
      case "GET":
        axios.get<YApiResponse<RESPONSE>>(url)
        .then(onSuccessWrapper)
        .catch(onErr);
        break;
      case "DELETE":
        axios.delete<YApiResponse<RESPONSE>>(url)
        .then(onSuccessWrapper)
        .catch(onErr);
        break;
      default:
        throw new Error("Unsupported method for api: " + method);
    }
  }, initialTimeout);
}