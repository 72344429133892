import {IntlShape, useIntl} from "react-intl";
import React from "react";
import {capitalize} from "../helpers/StringHelpers";

export const intlLabel = (id: string, intl: IntlShape) => {
  return intl.formatMessage({
    id: id,
    defaultMessage: id
  });
}

export const intlReferential = ({referential, id, doCapitalize = false, intl}: {
  referential?: string,
  id: string,
  doCapitalize?: boolean,
  intl: IntlShape
}) => {
  let messageKey = id;
  let defaultMessage = id;
  if (referential) {
    messageKey = referential + '.' + id;
  } else if (id.indexOf(".") > 0) {
    defaultMessage = id.replaceAll(id.split(".")[0] + ".", "");
  }

  let message = intl.formatMessage({
    id: messageKey,
    defaultMessage: defaultMessage
  });

  if (doCapitalize) {
    message = capitalize(message);
  }

  return message;
}

/**
 * We separate referential (prefix) from id to allow a more readable defaulting in case of missing translation.
 */
export const YlbIntlLabel = (params: {
  referential?: string,
  id: string,
  doCapitalize?: boolean
}) => {
  const intl = useIntl();

  return <>{intlReferential({...params, intl})}</>
}