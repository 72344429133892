import {CommonFieldProps} from "./_form_helpers";
import {allProductType, ProductType} from "../model/BackendReferential";
import {useIntl} from "react-intl";
import {capitalize} from "../helpers/StringHelpers";
import {YSelect} from "./YSelect";

export const YSelectProducts = (props: CommonFieldProps<'select'>) => {
  const intl = useIntl();

  let itemDictionary: { [key: string]: { productType: ProductType, label: string }[] } = {};
  let currentPrefix = "";
  let currentValues: { productType: ProductType, label: string }[] = new Array<{
    productType: ProductType,
    label: string
  }>();
  allProductType.forEach(productType => {
    const productMetaCode = productType.substring(0, 3);
    if (productMetaCode != currentPrefix) {
      if (currentPrefix.length > 0) {
        // save here
        currentValues.sort((x, y) => x.label.localeCompare(y.label));
        itemDictionary[intl.formatMessage({
          id: 'ProductMetaType.' + currentPrefix,
          defaultMessage: currentPrefix
        })] = currentValues;
      }
      currentPrefix = productMetaCode;
      currentValues = new Array<{ productType: ProductType, label: string }>();
    }
    currentValues.push({
      productType: productType,
      label: capitalize(intl.formatMessage({
        id: 'ProductType.' + productType,
        defaultMessage: productType
      }))
    });
  });
  if (currentValues.length > 0) {
    currentValues.sort((x, y) => x.label.localeCompare(y.label));
    itemDictionary[intl.formatMessage({
      id: 'ProductMetaType.' + currentPrefix,
      defaultMessage: currentPrefix
    })] = currentValues;
  }

  // we cannot use an advanced select so we sort by meta translation
  let arr = Object.entries(itemDictionary);
  arr.sort((a, b) => a[0].localeCompare(b[0]));

  return (
      <YSelect
          advancedMode={true}
          {...props}
          options={arr.flatMap(([key, value], index) => value.map(product =>
              ({
                label: product.label,
                value: product.productType
              })
          )
      )}/>
  );
}