import React from 'react';
import clsx from "clsx";
import {useField} from "formik";
import YFormLabel from "./YFormLabel";
import {CommonFieldProps} from "./_form_helpers";
import _ from "lodash";

// we must not pollute props with non-DOM attributes, see https://legacy.reactjs.org/warnings/unknown-prop.html
export const YInputGroup = ({
                              label,
                              containerClass,
                              required,
                              leftLabel,
                              rightLabel,
                              ...props
                            }: CommonFieldProps<'input'> & {
  leftLabel?: string,
  rightLabel?: string
}) => {
  const [field, meta] = useField({...props});

  // removing "0" values that can cause user typing issues
  let inputValue = field.value;
  if (_.isNumber(inputValue)) {
    if (inputValue === 0) {
      inputValue = "";
    }
  } else if (inputValue && /^0/.test(inputValue)) {
    inputValue = inputValue.replaceAll("0.00", "").replace(/^0/, "")
  }

  return (
      <div className={clsx(containerClass)}>
        <YFormLabel label={label} required={required}/>
        <div className="input-group">
          {leftLabel && <span className="input-group-text">{leftLabel}</span>}
          <input
              {...field} {...props}
              className={clsx(
                  'form-control',
                  {'is-invalid': meta.touched && meta.error},
                  props.className
              )}
              value={inputValue}
          />
          {rightLabel && <span className="input-group-text">{rightLabel}</span>}
        </div>
        {meta.error && <div className='fv-plugins-message-container invalid-feedback'>
          {meta.error}
        </div>}
      </div>
  );
}