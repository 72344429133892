import {intlReferential, YlbIntlLabel} from "../../../../../_ylb/i18n/YlbIntlLabel";
import {numberCentsToString, numberMillisToString} from "../../../../../_ylb/helpers/numerics";
import React from "react";
import {caliberDisplay, productDescription} from "../../../../../_ylb/helpers/ProductDescription";
import {
  ConsumableProductCategory,
  Currency,
  ProductPacking,
  ProductType
} from "../../../../../_ylb/model/BackendReferential";
import {useIntl} from "react-intl";

export type OrderContentsData = {
  products: OrderContentsProductData[];
  currency: Currency
  totalPriceNoTax: number
  totalPriceWithTax: number
}

export type OrderContentsProductData = {
  priceToken: string
  productType: ProductType
  category: ConsumableProductCategory
  caliber: string
  packing: ProductPacking
  packingWeightGrams: number
  pricePerKgCents: number
  totalPriceNoTaxCents: number
  palletCount: number
  boxPerPallet: number
  totalWeightGrams: number
}

export const OrderContents = (order: OrderContentsData) => {
  const intl = useIntl();

  const colspanForTotal = 7;

  return <div className="table-responsive">
    <table className="table align-middle table-row-dashed fs-6 gy-2 mb-0">
      <thead>
      <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
        <th className="min-w-100px">
          <YlbIntlLabel id="CustomerProductSearch.productType" doCapitalize={true}/>
        </th>
        <th className="min-w-100px">
          <YlbIntlLabel id="CustomerProductSearch.caliber" doCapitalize={true}/>
        </th>
        <th className="min-w-100px">
          <YlbIntlLabel id="CustomerProductSearch.category" doCapitalize={true}/>
        </th>
        <th className="min-w-100px">
          <YlbIntlLabel id="CustomerProductSearch.packing" doCapitalize={true}/>
        </th>
        <th className="min-w-70px text-end">
          <YlbIntlLabel id="CustomerCart.palletCount" doCapitalize={true}/>
        </th>
        <th className="min-w-75px text-end">
          <YlbIntlLabel id="CustomerCart.boxCount" doCapitalize={true}/>
        </th>
        <th className="min-w-100px text-end">
          <YlbIntlLabel id="CustomerCart.totalWeight" doCapitalize={true}/>
        </th>
        <th className="min-w-100px text-end">
          <YlbIntlLabel id="CustomerProductSearch.priceKg" doCapitalize={true}/>
        </th>
        <th className="min-w-75px text-end">
          <YlbIntlLabel id="CustomerCart.totalPrice" doCapitalize={true}/>
        </th>
      </tr>
      </thead>
      <tbody className="fw-semibold text-gray-600">{
        order.products.map(product =>
            <tr key={product.priceToken}>
              <td className="text-start">{
                productDescription(intl, product.productType, product.category, product.caliber,
                    product.packing, product.packingWeightGrams)
              }</td>
              <td className="text-start">{caliberDisplay(product.caliber)}</td>
              <td className="text-start">{intlReferential({
                referential: "ConsumableProductCategory",
                id: product.category,
                doCapitalize: true,
                intl: intl
              })}</td>
              <td className="text-start">{(product.packing !== 'UKN' ? intlReferential({
                referential: "ProductPacking",
                id: product.packing,
                doCapitalize: true,
                intl: intl
              }) + " " : "") + numberMillisToString(product.packingWeightGrams!, ".", true) + "kg"}</td>
              <td className="text-end">
                {product.palletCount}
              </td>
              <td className="text-end">
                {product.boxPerPallet * product.palletCount}
              </td>
              <td className="text-end">
                {numberMillisToString(product.totalWeightGrams,
                    ".",
                    true)}&nbsp;kg
              </td>
              <td className="text-end">
                {numberCentsToString(product.pricePerKgCents)}
                &nbsp;{order.currency}
              </td>
              <td className="text-end">
                {numberCentsToString(product.totalPriceNoTaxCents)}
                &nbsp;{order.currency}
              </td>
            </tr>)}
      <tr>
        <td colSpan={colspanForTotal} className="text-end border-top border-gray-900 border-2 border-bottom-0"></td>
        <td className="text-end border-top border-gray-900 border-2">
          <YlbIntlLabel id="CustomerCheckout.subtotal" doCapitalize={true}/>
        </td>
        <td className="text-end border-top border-gray-900 border-2">
          {numberCentsToString(order.totalPriceNoTax)}
          &nbsp;{order.currency}
        </td>
      </tr>
      <tr>
        <td colSpan={colspanForTotal}></td>
        <td className="text-end border-gray-900 border-2">
          <YlbIntlLabel id="CustomerCheckout.vat" doCapitalize={true}/> (0%)
        </td>
        <td className="text-end border-gray-900 border-2">
          {numberCentsToString(0)}
          &nbsp;{order.currency}
        </td>
      </tr>
      {/*FREX-95 deactivated*/}
      {/*<tr>*/}
      {/*  <td colSpan={colspanForTotal}></td>*/}
      {/*  <td className="text-end">*/}
      {/*    <YlbIntlLabel id="CustomerCheckout.shippingRate" doCapitalize={true}/>*/}
      {/*  </td>*/}
      {/*  <td className="text-end">*/}
      {/*    (<YlbIntlLabel id="CustomerCheckout.shippingRateIncluded"/>)*/}
      {/*  </td>*/}
      {/*</tr>*/}
      <tr>
        <td colSpan={colspanForTotal}></td>
        <td className="fs-3 text-dark text-end border-top border-gray-900 border-2">
          <YlbIntlLabel id="CustomerCheckout.grandTotal" doCapitalize={true}/>
        </td>
        <td className="text-dark fs-3 fw-bolder text-end border-top border-gray-900 border-2">
          {numberCentsToString(order.totalPriceWithTax)}
          &nbsp;{order.currency}
        </td>
      </tr>
      </tbody>
    </table>
  </div>
}