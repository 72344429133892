import {FC, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {AdminRoutes} from "../modules/apps/admin/AdminRoutes";
import {CustomerRoutes} from "../modules/apps/customer/CustomerRoutes";
import {getHomePageUri} from "../../_ylb/routing/YRoutingHelper";
import {useAuth} from "../modules/auth";

const PrivateRoutes = () => {
  const {currentUser}=useAuth();

  return (
      <Routes>
        <Route element={<MasterLayout/>}>
          {/* Redirect to Dashboard after success login/registration */}
          <Route path='dashboard' element={<Navigate to={getHomePageUri(currentUser)}/>}/>
          <Route path='auth/*' element={<Navigate to={getHomePageUri(currentUser)}/>}/>
          {/* Pages */}
          {/*<Route path='dashboard' element={<DashboardWrapper/>}/>*/}
          {/*<Route path='builder' element={<BuilderPageWrapper/>}/>*/}
          {/*<Route path='menu-test' element={<MenuTestPage/>}/>*/}
          {/* Lazy Modules */}
          {/*<Route*/}
          {/*    path='crafted/pages/profile/*'*/}
          {/*    element={*/}
          {/*      <SuspensedView>*/}
          {/*        <ProfilePage/>*/}
          {/*      </SuspensedView>*/}
          {/*    }*/}
          {/*/>*/}
          {/*<Route*/}
          {/*    path='crafted/pages/wizards/*'*/}
          {/*    element={*/}
          {/*      <SuspensedView>*/}
          {/*        <WizardsPage/>*/}
          {/*      </SuspensedView>*/}
          {/*    }*/}
          {/*/>*/}
          {/*<Route*/}
          {/*    path='crafted/widgets/*'*/}
          {/*    element={*/}
          {/*      <SuspensedView>*/}
          {/*        <WidgetsPage/>*/}
          {/*      </SuspensedView>*/}
          {/*    }*/}
          {/*/>*/}
          {/*<Route*/}
          {/*    path='crafted/account/*'*/}
          {/*    element={*/}
          {/*      <SuspensedView>*/}
          {/*        <AccountPage/>*/}
          {/*      </SuspensedView>*/}
          {/*    }*/}
          {/*/>*/}
          {/*<Route*/}
          {/*    path='apps/chat/*'*/}
          {/*    element={*/}
          {/*      <SuspensedView>*/}
          {/*        <ChatPage/>*/}
          {/*      </SuspensedView>*/}
          {/*    }*/}
          {/*/>*/}
          {/*<Route*/}
          {/*    path='apps/user-management/*'*/}
          {/*    element={*/}
          {/*      <SuspensedView>*/}
          {/*        <UsersPage/>*/}
          {/*      </SuspensedView>*/}
          {/*    }*/}
          {/*/>*/}
          <Route
              path='admin/*'
              element={
                <SuspensedView>
                  <AdminRoutes/>
                </SuspensedView>
              }
          />
          <Route
              path='customer/*'
              element={
                <SuspensedView>
                    <CustomerRoutes/>
                </SuspensedView>
              }
          />
          {/* Page Not Found */}
          <Route path='*' element={<Navigate to='/error/404'/>}/>
        </Route>
      </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress/>}>{children}</Suspense>
}

export {PrivateRoutes}
