import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {backendApiRequest} from "../../../../../_ylb/api/YBackendApiClient";
import {
  customerProductCheckoutConfirmationEndpoint,
  customerProductCheckoutEndpoint,
} from "../../../../../_ylb/api/BackendEndpoints";
import {CustomerProductSearchContext} from "../product/CustomerProductSearchContext";
import {NotYetFetched, YFetched} from "../../../../../_ylb/api/YFetched";
import {Address} from "../../../../../_ylb/model/Address";
import {DateDisplay} from "../../../../../_ylb/helpers/DateDisplay";
import {Money} from "../../../../../_ylb/model/Money";
import {toast} from "react-toastify";
import {intlReferential, YlbIntlLabel} from "../../../../../_ylb/i18n/YlbIntlLabel";
import {useIntl} from "react-intl";
import {CountryDisplay} from "../../../../../_ylb/helpers/CountryDisplay";
import {OrderContents} from "./OrderContents";
import {
  ConsumableProductCategory,
  ProductPacking,
  ProductType
} from "../../../../../_ylb/model/BackendReferential";

type CheckoutRequestProductOnly = {
  priceToken: string
  palletCount: number
}

type CheckoutRequest = {
  departureDate: Date
  products: CheckoutRequestProductOnly[]
}

type CheckoutResponseProductOnly = CheckoutRequestProductOnly

type CheckoutResponseProductFull = CheckoutResponseProductOnly & {
  productType: ProductType
  category: ConsumableProductCategory
  caliber: string
  packing: ProductPacking
  packingWeightGrams: number
  pricePerKg: Money
  totalPrice: Money
  boxPerPallet: number
  totalWeightGrams: number
}

type CheckoutResponseContainer = {
  products: CheckoutResponseProductOnly[]
}

type CheckoutResponse = {
  products: CheckoutResponseProductFull[]
  containers: CheckoutResponseContainer[]
  shippingFrom: string
  shippingTo: string
  departureDate: Date
  customerName: string
  customerBillingAddress: Address
  totalPriceNoTax: Money
  totalPriceWithTax: Money
}

type CheckoutConfirmationResponse = {
  orderToken: string
}

const checkoutFormSateInitial = {
  confirmationButtonEnabled: true,
  conditionsAccepted: false,
  formErrors: [] as string[]
}

export const CustomerCheckoutV3 = () => {
  const {
    checkoutCart,
    emptyCart
  } = useContext(CustomerProductSearchContext);
  const [checkoutResponse, setCheckoutResponse] = useState<YFetched<CheckoutResponse>>(NotYetFetched);
  const [checkoutFormState, setCheckoutFormState] = useState(checkoutFormSateInitial);
  const navigate = useNavigate();
  const intl = useIntl();
  const displayConfirmation = false;

  useEffect(() => {
    backendApiRequest<CheckoutResponse, CheckoutRequest>({
      method: "POST",
      data: getCheckoutRequestData(),
      url: customerProductCheckoutEndpoint,
      onSuccess: (response) => {
        setCheckoutResponse({
          isFetched: true,
          data: response.data.data!
        });
      }
    });
  }, []);

  const getCheckoutRequestData = () => {
    return {
      products: checkoutCart.cartProducts.map(cartProduct => {
        return {
          priceToken: cartProduct.priceToken,
          palletCount: cartProduct.palletCount
        }
      }),
      departureDate: new Date()
    }
  };

  const clearFormError = () => {
    setCheckoutFormState({
      ...checkoutFormState,
      formErrors: []
    });
  };

  const sendCheckoutConfirmation = () => {
    if (displayConfirmation && !checkoutFormState.conditionsAccepted) {
      setCheckoutFormState({
        ...checkoutFormState,
        formErrors: ["You must accept conditions"]
      });
      return;
    }

    setCheckoutFormState({
      ...checkoutFormState,
      confirmationButtonEnabled: false
    });

    backendApiRequest<CheckoutConfirmationResponse, CheckoutRequest>({
      method: "POST",
      data: getCheckoutRequestData(),
      url: customerProductCheckoutConfirmationEndpoint,
      onSuccess: (response) => {
        toast.success(intlReferential({
          id: "CustomerCheckout.orderSaved",
          doCapitalize: true,
          intl: intl
        }) + ".");
        emptyCart();
        navigate("/customer/order/" + response.data.data!.orderToken);
      }
    });
  }

  return (
      <>{
        checkoutResponse.isFetched ?
            <div className="tab-content">
              <div className="tab-pane fade show active" id="kt_ecommerce_sales_order_summary"
                   role="tab-panel">
                <div className="d-flex flex-column gap-7 gap-lg-10">
                  <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
                    <div className="card card-flush py-4 flex-row-fluid position-relative">
                      <div
                          className="position-absolute top-0 end-0 bottom-0 opacity-10 d-flex align-items-center me-5">
                        <i className="ki-solid ki-two-credit-cart" style={{fontSize: "14em"}}>
                        </i>
                      </div>
                      <div className="card-header">
                        <div className="card-title">
                          <h2><YlbIntlLabel id="CustomerCheckout.shippingTitle"
                                            doCapitalize={true}/></h2>
                        </div>
                      </div>
                      <div className="card-body pt-0">
                        <table>
                          <tbody>
                          <tr>
                            <td className="min-w-160px">
                              <YlbIntlLabel id="CustomerCheckout.shippingFrom" doCapitalize={true}/>:
                            </td>
                            <td>{checkoutResponse.data?.shippingFrom}</td>
                          </tr>
                          <tr>
                            <td>
                              <YlbIntlLabel id="CustomerCheckout.shippingTo" doCapitalize={true}/>:
                            </td>
                            <td>{checkoutResponse.data?.shippingTo}</td>
                          </tr>
                          <tr>
                            <td>
                              <YlbIntlLabel id="CustomerCheckout.expectedDeparture"
                                            doCapitalize={true}/>:
                            </td>
                            <td>
                              {checkoutResponse.data!.departureDate ?
                                  <DateDisplay date={checkoutResponse.data!.departureDate}/>
                                  : <YlbIntlLabel id="CommonLabels.asap" doCapitalize={true}/>}
                            </td>
                          </tr>
                          <tr>
                            <td><YlbIntlLabel id="CustomerCart.containerCount" doCapitalize={true}/>:
                            </td>
                            <td>{checkoutResponse.data?.containers.length}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="card card-flush py-4 flex-row-fluid position-relative">
                      <div
                          className="position-absolute top-0 end-0 bottom-0 opacity-10 d-flex align-items-center me-5">
                        <i className="ki-solid ki-delivery" style={{fontSize: "13em"}}>
                        </i>
                      </div>
                      <div className="card-header">
                        <div className="card-title">
                          <h2><YlbIntlLabel id="CustomerCheckout.billingAddress"
                                            doCapitalize={true}/></h2>
                        </div>
                      </div>
                      <div className="card-body pt-0">
                        {checkoutResponse.data?.customerName}<br/>
                        {checkoutResponse.data?.customerBillingAddress.address1}<br/>
                        {checkoutResponse.data?.customerBillingAddress.address2 ? <>
                          {checkoutResponse.data?.customerBillingAddress.address2}<br/>
                        </> : <></>}
                        {checkoutResponse.data?.customerBillingAddress.state ? <>
                          {checkoutResponse.data?.customerBillingAddress.state}<br/>
                        </> : <></>}
                        {checkoutResponse.data?.customerBillingAddress.city}<br/>
                        {checkoutResponse.data?.customerBillingAddress.zipcode}<br/>
                        <CountryDisplay
                            country={checkoutResponse.data?.customerBillingAddress.country!}/>
                      </div>
                    </div>
                  </div>
                  <div className="card card-flush pt-4 flex-row-fluid overflow-hidden">
                    <div className="card-header">
                      <div className="card-title">
                        <h2><YlbIntlLabel id="CustomerCart.products" doCapitalize={true}/></h2>
                      </div>
                    </div>
                    <div className="card-body pt-0 pb-0">
                      <OrderContents
                          products={checkoutResponse.data!.products.map(product => ({
                            priceToken: product.priceToken,
                            productType: product.productType,
                            category: product.category,
                            caliber: product.caliber,
                            packing: product.packing,
                            packingWeightGrams: product.packingWeightGrams,
                            pricePerKgCents: product.pricePerKg.amountInCents,
                            totalPriceNoTaxCents: product.totalPrice.amountInCents,
                            palletCount: product.palletCount,
                            boxPerPallet: product.boxPerPallet,
                            totalWeightGrams: product.totalWeightGrams
                          }))}
                          currency={checkoutResponse.data!.totalPriceNoTax.currency}
                          totalPriceNoTax={checkoutResponse.data!.totalPriceNoTax.amountInCents}
                          totalPriceWithTax={checkoutResponse.data!.totalPriceWithTax.amountInCents}/>
                      <div className="d-flex align-items-end flex-column mt-5 mb-10">
                        {displayConfirmation &&
                            <div className="form-check form-check-custom form-check-solid">
                              <input className="form-check-input" type="checkbox" value="true"
                                     id="checkout_confirmation_box"
                                     defaultChecked={checkoutFormState.conditionsAccepted}
                                     onChange={(e) => setCheckoutFormState({
                                       ...checkoutFormState,
                                       formErrors: [],
                                       conditionsAccepted: e.target.value === 'true',
                                     })}/>
                              <label className="form-check-label"
                                     htmlFor="checkout_confirmation_box">
                                <YlbIntlLabel id="CustomerCheckout.confirmation1"
                                              doCapitalize={true}/>
                                &nbsp;<Link to="#">
                                <YlbIntlLabel id="CustomerCheckout.confirmation2"/>.
                              </Link>
                              </label>
                            </div>
                        }
                        {checkoutFormState.formErrors.length > 0 ?
                            <div
                                className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback text-end">
                              {checkoutFormState.formErrors.map(message =>
                                  <div key={Math.floor(Math.random() * 600) + 1}>{message}</div>
                              )}
                            </div>
                            : <></>}
                        <div className="mt-5">
                          <button type="button" className="btn btn-light me-5"
                                  onClick={() => navigate("/customer/cart")}>
                            <YlbIntlLabel id="CustomerCheckout.backToCart" doCapitalize={true}/>
                          </button>
                          <button type="submit" className="btn btn-primary"
                                  disabled={!checkoutFormState.confirmationButtonEnabled}
                                  onClick={() => {
                                    if (window.confirm(intlReferential({
                                      id: "CustomerCheckout.confirmModal",
                                      doCapitalize: true,
                                      intl
                                    }))) {
                                      sendCheckoutConfirmation();
                                    }
                                  }}>
                            <span className="indicator-label">
                              <i className='fas fa-circle-arrow-right ms-2 fs-5'></i>&nbsp;
                              <YlbIntlLabel id="CustomerCheckout.confirmationButton"
                                            doCapitalize={true}/>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            : <>
              Loading cart...
            </>
      }
      </>
  )
}