import dayjs from "dayjs";

export const DateDisplay = ({date, displayTime = false}: { date: Date, displayTime?: boolean }) => {
  let format = "DD/MM/YYYY";
  if (displayTime) {
    format += " HH:mm:ss";
  }
  return (
      <>{dayjs(date).format(format)}</>
  )
}