/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from "../../../../../app/modules/auth";
import {intlReferential} from "../../../../../_ylb/i18n/YlbIntlLabel";
import {useIntl} from "react-intl";

const SidebarMenuMain = () => {
  const {currentUser} = useAuth();
  const intl = useIntl();

  return (
      <>
        {currentUser!.scopes.includes("ROLE_PROVIDER") && <>
          <SidebarMenuItem
              to='#'
              icon='element-11'
              title='My products'
              fontIcon='bi-app-indicator'
          />
          <SidebarMenuItem
              to='#'
              icon='element-11'
              title='Orders'
              fontIcon='bi-app-indicator'
          />
        </>}
        {currentUser!.scopes.includes("ROLE_CUSTOMER") && <>
          <SidebarMenuItem
              to='/customer/product'
              icon='element-11'
              title={intlReferential({
                id: "Menu.availableProducts",
                doCapitalize: true,
                intl: intl
              })}
              fontIcon='bi-app-indicator'
          />
          <SidebarMenuItem
              to='/customer/order'
              icon='element-11'
              title={intlReferential({
                id: "Menu.myOrders",
                doCapitalize: true,
                intl: intl
              })}
              fontIcon='bi-app-indicator'
          />
        </>}
        {currentUser!.scopes.includes("ROLE_ADMIN") && <>
          <SidebarMenuItem
              to='/admin/provider'
              icon='element-11'
              title='Providers and products'
              fontIcon='bi-app-indicator'
          />
          <SidebarMenuItem
              to='/admin/customer'
              icon='element-11'
              title='Customers'
              fontIcon='bi-app-indicator'
          />
          <SidebarMenuItem
              to='/admin/order'
              icon='element-11'
              title='Orders'
              fontIcon='bi-app-indicator'
          />
          <SidebarMenuItem
              to='/admin/config/marginrule'
              icon='element-11'
              title='Configuration : Margins'
              fontIcon='bi-app-indicator'
          />
          <SidebarMenuItem
              to='/admin/config/transportquotation'
              icon='element-11'
              title='Configuration : Transport quotations'
              fontIcon='bi-app-indicator'
          />
        </>}
      </>
  )
}

export {SidebarMenuMain}
