import {
  ConsumableProductCategory,
  Country,
  Incoterm,
  ProductPacking,
  ProductType
} from "../../../../../_ylb/model/BackendReferential";
import {NotYetFetched, YFetched} from "../../../../../_ylb/api/YFetched";
import {createContext, PropsWithChildren, useState} from "react";
import {IntlShape} from "react-intl";
import {productDescription} from "../../../../../_ylb/helpers/ProductDescription";
import {toast} from "react-toastify";
import _ from "lodash";

export const MAX_CONTAINERS_PER_ORDER = 6;

export type CustomerProductSearchResponseItemDto = {
  providerToken: string
  providerName: string
  origin: Country
  departure: Country
  priceToken: string
  type: ProductType
  category: ConsumableProductCategory
  caliber: string
  packing: ProductPacking
  packingWeightGrams: number
  packingPerBox: number
  currency: string
  incoterm: Incoterm
  boxPerPallet: number
  palletPerContainer: number
  priceKg: number
}

export type CustomerProductSearchResponseDto = {
  destinationCountry: Country
  products: CustomerProductSearchResponseItemDto[]
}

export const enrichSearchProduct = (dto: CustomerProductSearchResponseItemDto,
                                    intl: IntlShape): CustomerProductSearchResponseItem => {
  return {
    ...dto,
    productDescription: productDescription(
        intl,
        dto.type,
        dto.category,
        dto.caliber,
        dto.packing,
        dto.packingWeightGrams),
    containerWeightGrams: dto.packingWeightGrams
        * dto.packingPerBox
        * dto.boxPerPallet
        * dto.palletPerContainer
  }
}

export type CustomerProductSearchResponseItem = CustomerProductSearchResponseItemDto & {
  productDescription: string
  containerWeightGrams: number
}

export type CustomerProductSearchResponse = {
  destinationCountry: Country
  products: CustomerProductSearchResponseItem[]
}

type ProviderSelectData = {
  name: string
  token: string
}

type SearchFilterRestrictions = {
  familyKeepOnly: string[]
  metaTypeKeepOnly: string[]
  typeKeepOnly: string[]
  departureKeepOnly: string[]
  originKeepOnly: string[]
  providers: ProviderSelectData[]
}

const defaultSearchFilterRestrictions = {
  familyKeepOnly: [],
  metaTypeKeepOnly: [],
  typeKeepOnly: [],
  departureKeepOnly: [],
  originKeepOnly: [],
  providers: []
}

type CustomerProductSearchResponseItemCart = CustomerProductSearchResponseItem & {
  palletCount: number
  boxCount: number
  totalWeightGrams: number
  totalPriceCents: number
}

export type CheckoutCart = {
  providerToken: string
  providerName: string
  providerCountry: string
  providerCurrency: string
  palletPerContainer: number
  providerProducts: CustomerProductSearchResponseItem[]
  cartProducts: CustomerProductSearchResponseItemCart[]
  palletCount: number
  boxCount: number
  containerCount: number
  totalWeightGrams: number
  totalPriceCents: number
  fillingPercent: number
}

export const defaultCheckoutCart = {
  providerToken: "XX",
  providerName: "",
  providerCountry: "",
  providerCurrency: "",
  palletPerContainer: 0,
  providerProducts: [],
  cartProducts: [],
  palletCount: 0,
  boxCount: 0,
  containerCount: 0,
  totalWeightGrams: 0,
  totalPriceCents: 0,
  fillingPercent: 0,
}

export type CustomerProductSearchHolder = {
  productSearchResultsBase: YFetched<CustomerProductSearchResponse>
  setProductSearchResultsBase: (results: YFetched<CustomerProductSearchResponse>) => void
  productSearchResultsFiltered: CustomerProductSearchResponseItem[]
  setProductSearchResultsFiltered: (results: CustomerProductSearchResponseItem[]) => void
  searchFilterRestrictions: SearchFilterRestrictions
  setSearchFilterRestrictions: (restrictions: SearchFilterRestrictions) => void
  checkoutCart: CheckoutCart
  updatePalletCount: (priceToken: string, palletCount: number) => void
  addProductToCheckout: (priceToken: string, quantity: number) => CheckoutCart | void
  emptyCart: () => void
}

export const CustomerProductSearchContext = createContext<CustomerProductSearchHolder>({
  productSearchResultsBase: NotYetFetched,
  setProductSearchResultsBase: () => {
  },
  productSearchResultsFiltered: [],
  setProductSearchResultsFiltered: () => {
  },
  searchFilterRestrictions: defaultSearchFilterRestrictions,
  setSearchFilterRestrictions: () => {
  },
  checkoutCart: defaultCheckoutCart,
  updatePalletCount: () => {
  },
  addProductToCheckout: () => defaultCheckoutCart,
  emptyCart: () => {
  }
})

export const CustomerProductSearchContextWrapper = ({children}: PropsWithChildren<{}>) => {
  const [productSearchResultsBase, setProductSearchResultsBase] = useState<YFetched<CustomerProductSearchResponse>>(NotYetFetched);
  const [productSearchResultsFiltered, setProductSearchResultsFiltered] = useState<CustomerProductSearchResponseItem[]>([]);
  const [searchFilterRestrictions, setSearchFilterRestrictions] = useState<SearchFilterRestrictions>(defaultSearchFilterRestrictions);
  const [checkoutCart, setCheckoutCart] = useState<CheckoutCart>(defaultCheckoutCart);

  const updatePalletCount = (priceToken: string, palletCount: number) => {
    setTimeout(() => {
      if (palletCount >= 0) {
        let cart = {...checkoutCart};
        // we also make defensive copy of product to allow later discard of the whole cart copy
        cart.cartProducts = cart.cartProducts.map(product => {
          if (product.priceToken == priceToken) {
            return {
              ...product,
              palletCount: palletCount
            }
          }
          return product;
        });
        setCheckoutCartWithInvariants(cart);
      }
    }, 1);
  }

  const addProductToCheckout = (priceToken: string, quantity: number) => {
    if (productSearchResultsBase.isFetched) {
      const currentProduct = productSearchResultsBase.data!.products
      .find(product => product.priceToken == priceToken);
      if (currentProduct) {
        let checkout = {...checkoutCart};
        if (checkout.providerToken != currentProduct.providerToken) {
          checkout = {
            ...defaultCheckoutCart,
            providerToken: currentProduct.providerToken,
            providerName: currentProduct.providerName,
            providerCountry: currentProduct.departure,
            providerCurrency: currentProduct.currency,
            palletPerContainer: currentProduct.palletPerContainer,
            cartProducts: [],
            providerProducts: _.chain(productSearchResultsBase.data!.products)
            .sortBy('productDescription')
            .filter(product => product.providerToken === currentProduct.providerToken)
            .value(),
          };
        }
        let currentProductCart;
        checkout.cartProducts = checkout.cartProducts.map(product => {
          if (product.priceToken == priceToken) {
            currentProductCart = {
              ...product,
            }
            return currentProductCart;
          }
          return product;
        });
        if (!currentProductCart) {
          currentProductCart = {
            ...productSearchResultsBase.data!.products.find(product => product.priceToken == currentProduct.priceToken)!,
            palletCount: 0,
            boxCount: 0,
            totalWeightGrams: 0,
            totalPriceCents: 0,
          };
          checkout.cartProducts.push(currentProductCart);
          // sort by description
          checkout = {
            ...checkout,
            cartProducts: _.chain(checkout.cartProducts)
            .sortBy('productDescription')
            .value()
          }
        }
        currentProductCart.palletCount += quantity;

        setCheckoutCartWithInvariants(checkout);
        return checkout;
      }
    } else {
      alert("Unable to load product cache");
    }
  }

  const setCheckoutCartWithInvariants = (cart: CheckoutCart) => {
    cart.palletCount = 0;
    cart.boxCount = 0;
    cart.containerCount = 0;
    cart.totalWeightGrams = 0;
    cart.totalPriceCents = 0;

    cart.cartProducts = cart.cartProducts.filter(product => product.palletCount > 0);
    cart.cartProducts.forEach(product => {
      product.boxCount = product.boxPerPallet * product.palletCount;
      product.totalWeightGrams = product.packingWeightGrams * product.packingPerBox * product.boxCount;
      product.totalPriceCents = Math.ceil(product.priceKg * product.totalWeightGrams / 1000);

      cart.totalWeightGrams += product.totalWeightGrams;
      cart.palletCount += product.palletCount;
      cart.boxCount += product.boxCount;
      cart.totalPriceCents += product.totalPriceCents;
    });
    if (cart.palletCount > 0 && cart.palletPerContainer > 0) {
      cart.containerCount = Math.ceil(cart.palletCount / cart.palletPerContainer);
      cart.fillingPercent = Math.floor(100 * cart.palletCount / (cart.containerCount * cart.palletPerContainer));
    }

    if (cart.containerCount > MAX_CONTAINERS_PER_ORDER) {
      toast.error("You are limited to " + MAX_CONTAINERS_PER_ORDER + " containers.");
    } else {
      setCheckoutCart(cart);
    }
  }

  const emptyCart = () => {
    setCheckoutCart(defaultCheckoutCart);
  }

  return (
      <CustomerProductSearchContext.Provider value={{
        productSearchResultsBase: productSearchResultsBase,
        setProductSearchResultsBase: setProductSearchResultsBase,
        productSearchResultsFiltered: productSearchResultsFiltered,
        setProductSearchResultsFiltered: setProductSearchResultsFiltered,
        searchFilterRestrictions: searchFilterRestrictions,
        setSearchFilterRestrictions: setSearchFilterRestrictions,
        checkoutCart: checkoutCart,
        updatePalletCount: updatePalletCount,
        addProductToCheckout: addProductToCheckout,
        emptyCart: emptyCart
      }}>
        {children}
      </CustomerProductSearchContext.Provider>
  )
}