import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
import {Navigate, Outlet, Route, Routes} from "react-router-dom";
import {CustomerProductSearch} from "./product/CustomerProductSearch";
import {CustomerCart} from "./checkout/CustomerCart";
import {CustomerCheckoutV3} from "./checkout/CustomerCheckoutV3";
import {CustomerOrderList} from "./order/CustomerOrderList";
import {intlReferential} from "../../../../_ylb/i18n/YlbIntlLabel";
import {useIntl} from "react-intl";
import CommonOrderDetailsRoutes from "../common/order/CommonOrderDetailsRoutes";

const customerBreadcrumbs: Array<PageLink> = [
  {
    title: 'Checkout',
    path: '/apps/customer/checkout',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const CustomerRoutes = () => {
  const intl = useIntl();

  return (
      <Routes>
        <Route element={
          <Outlet/>
        }>
          <Route
              path='product'
              element={
                <>
                  <CustomerProductSearch/>
                </>
              }
          />
          <Route
              path='checkout'
              element={
                <>
                  <PageTitle breadcrumbs={customerBreadcrumbs}>
                    {intlReferential({
                      id: "Menu.checkout",
                      doCapitalize: true,
                      intl: intl
                    })}
                  </PageTitle>
                  <CustomerCheckoutV3/>
                </>
              }
          />
          <Route
              path='cart'
              element={
                <>
                  <PageTitle breadcrumbs={customerBreadcrumbs}>
                    {intlReferential({
                      id: "Menu.cart",
                      doCapitalize: true,
                      intl: intl
                    })}
                  </PageTitle>
                  <CustomerCart/>
                </>
              }
          />
          <Route
              path='order'
              element={
                <>
                  <PageTitle breadcrumbs={customerBreadcrumbs}>
                    {intlReferential({
                      id: "Menu.orderList",
                      doCapitalize: true,
                      intl: intl
                    })}
                  </PageTitle>
                  <CustomerOrderList/>
                </>
              }
          />
          <Route
              path='order/:orderToken/*'
              element={
                <>
                  <CommonOrderDetailsRoutes/>
                </>
              }
          />
        </Route>

        <Route index element={<Navigate to='overview'/>}/>
      </Routes>
  )
}

export {CustomerRoutes}