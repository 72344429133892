import React, {ReactNode} from 'react';
import {Form, Formik} from "formik";
import {FormikConfig} from "formik/dist/types";

type Props = FormikConfig<any> & { className?: string };

const YForm = ({className, initialValues, onSubmit, children}: Props) => {
  return (
      <>
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validateOnBlur={false}
            validateOnChange={false}
        >
          {(formik) => (
              <Form className={className}>
                {/*weird, but I had to cast below*/}
                {(children as ReactNode)}

                {formik.status && <div className='fv-plugins-message-container invalid-feedback'>
                  {formik.status}
                </div>}
              </Form>
          )}
        </Formik>
      </>
  );
}

export default YForm;