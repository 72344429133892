import {allCountry} from "../model/BackendReferential";
import {useIntl} from "react-intl";
import {capitalize} from "../helpers/StringHelpers";
import {YSelectObjectBase, YSelectObjectBaseProps} from "./YSelectObjectBase";
import {YOption} from "./YSelect";

export const defaultCountryCode = "ZZ";

export const YSelectCountries = ({showDefault = false, ...props}: YSelectObjectBaseProps & {
  showDefault?: boolean
}) => {
  const intl = useIntl();

  let optionData: YOption[] = allCountry.map(country => {
    return {
      value: country,
      label: capitalize(
          intl.formatMessage({
            id: 'country.' + country,
            defaultMessage: country
          }))
    }
  });

  let atFirst: string[] = [];
  let additionalOptions: YOption[] = [];
  if (showDefault) {
    additionalOptions = [{
      value: defaultCountryCode,
      label: "- " + intl.formatMessage({
        id: 'country.selectdefault',
        defaultMessage: 'Select country'
      }) + " -"
    }];
    atFirst = [defaultCountryCode];
  }

  return <YSelectObjectBase options={optionData}
                            additionalOptions={additionalOptions}
                            atFirst={atFirst}
                            advancedMode={true}
                            {...props} />
}