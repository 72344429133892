import {toast} from "react-toastify";
import {Address} from "../../../../../../_ylb/model/Address";
import {adminProviderUpdateEndpoint} from "../../../../../../_ylb/api/BackendEndpoints";
import {useNavigate} from "react-router-dom";
import YForm from "../../../../../../_ylb/form/YForm";
import {backendApiRequest} from "../../../../../../_ylb/api/YBackendApiClient";
import {getClosestUri} from "../../../../../../_ylb/routing/YRoutingHelper";
import {KTCard, KTCardBody} from "../../../../../../_metronic/helpers";
import YInput from "../../../../../../_ylb/form/YInput";
import {YInputGroup} from "../../../../../../_ylb/form/YInputGroup";
import React from "react";
import {YSelectCountries} from "../../../../../../_ylb/form/YSelectCountries";
import {stringToCents} from "../../../../../../_ylb/helpers/numerics";
import {YSelectReferential} from "../../../../../../_ylb/form/YSelectReferential";
import {Currency, Incoterm, TransportHub} from "../../../../../../_ylb/model/BackendReferential";
import {handleFormErrors} from "../../../../../../_ylb/form/_form_helpers";

const providerInitialValues: ProviderData = {
  token: "",
  name: "",
  displayName: "",
  preDeliveryPaymentPercent: 0,
  marginPercent: "0",
  containerPreparationDelayDays: 0,
  defaultIncoterm: "FOB",
  preferredBoatHub: "XXX",
  registrationNumber: "",
  billingAddress: {
    address1: "",
    zipcode: "",
    city: "",
    state: "",
    country: "AF"
  }
}

export type ProviderData = {
  token: string
  name: string
  displayName: string
  preDeliveryPaymentPercent: number
  marginPercent: string
  containerPreparationDelayDays: number
  defaultIncoterm: Incoterm
  preferredBoatHub: TransportHub
  currency?: Currency
  registrationNumber: string
  billingAddress: Address
}

export const AdminProviderUpdate = ({providerData}: {
  providerData?: ProviderData
}) => {
  const navigate = useNavigate();

  let isExistingProvider = false;
  if (providerData) {
    isExistingProvider = true;
  }
  providerData = providerData ?? providerInitialValues;

  return (
      <>
        <YForm initialValues={providerData}
               onSubmit={(values, actions) => {
                 let {marginPercent, ...payload} = values;
                 payload = {
                   ...payload,
                   marginPercentCents: stringToCents(marginPercent)
                 };

                 backendApiRequest<string>({
                   url: adminProviderUpdateEndpoint,
                   method: "POST",
                   data: payload,
                   onSuccess: response => {
                     actions.setSubmitting(false);
                     toast.success("Provider successfully saved.");
                     navigate(getClosestUri(window.location.pathname, "/provider"));
                   },
                   onError: error => {
                     handleFormErrors(error.response?.data.payload!, values, actions,
                         fieldName => {
                           if (fieldName === "marginPercentCents") {
                             return "marginPercent"
                           }
                           return fieldName;
                         });
                     actions.setSubmitting(false);
                   }
                 });
               }}>
          <div className="row">
            <div className="col-6">
              <KTCard>
                <div className="card-header border-0 pt-5">
                  <h2 className="card-title align-items-start flex-column">
                    {isExistingProvider ? "Editing provider: " + providerData.token : "New provider"}
                  </h2>
                </div>
                <KTCardBody>
                  <div className="row pb-5">
                    <YInput label="Official name"
                            name="name"
                            containerClass="col-md-6"
                            required={true}/>
                    <YInput label="Registration number"
                            name="registrationNumber"
                            containerClass="col-md-6"
                            required={true}/>
                  </div>
                  <div className="row pb-5">
                    <YInput label="Public name"
                            name="displayName"
                            containerClass="col-md-6"
                            required={true}/>
                  </div>
                  <div className="row pb-5">
                    <h3>Order configuration</h3>
                  </div>
                  <div className="row pb-5">
                    <YInputGroup
                        rightLabel="%"
                        label="Pre-payment percentage"
                        name="preDeliveryPaymentPercent"
                        type="text"
                        containerClass="col-md-6"
                        className="text-end"/>
                    <YInput label="Container prep delays (days)"
                            name="containerPreparationDelayDays"
                            containerClass="col-6"/>
                    <YInputGroup
                        rightLabel="%"
                        label="All products margin percent"
                        name="marginPercent"
                        type="text"
                        containerClass="col-md-6"
                        className="text-end"/>
                    <YSelectReferential label="Default incoterm"
                                        name="defaultIncoterm"
                                        referential="Incoterm"
                                        containerClass="col-6"
                                        required={true}/>
                    <YSelectReferential label="Default Port"
                                        name="preferredBoatHub"
                                        referential="TransportHub"
                                        containerClass="col-4"
                                        disabled={isExistingProvider}/>
                    <YSelectReferential label="Billling currency"
                                        name="currency"
                                        referential="Currency"
                                        containerClass="col-4"
                                        disabled={isExistingProvider}/>
                  </div>
                  <div className="row pb-5">
                    <h3>Billing address</h3>
                  </div>
                  <div className="row pb-5">
                    <YInput label="Address (street & number)"
                            name="billingAddress.address1"
                            required={true}/>
                  </div>
                  <div className="row pb-5">
                    <YInput label="Address (more)"
                            name="billingAddress.address2"/>
                  </div>
                  <div className="row pb-5">
                    <YInput label="Zipcode"
                            name="billingAddress.zipcode"
                            containerClass="col-4"
                            required={true}/>
                    <YInput label="City"
                            name="billingAddress.city"
                            containerClass="col-8"
                            required={true}/>
                  </div>
                  <div className="row pb-5">
                    <YInput label="State"
                            name="billingAddress.state"
                            containerClass="col-6"/>
                    <YSelectCountries label="Country"
                                      name="billingAddress.country"
                                      containerClass="col-6"
                                      required={true}
                                      disabled={isExistingProvider}/>
                  </div>
                </KTCardBody>
              </KTCard>
              <div className="d-flex justify-content-end mt-10 mb-15">
                <button type="button" className="btn btn-light me-5"
                        onClick={() => navigate(-1)}>
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary"
                        data-kt-users-modal-action="submit">
                  <span className="indicator-label">
                    <i className='fas fa-floppy-disk ms-2 fs-5'></i> Save
                  </span>
                </button>
              </div>
            </div>
          </div>
        </YForm>
      </>
  )
}