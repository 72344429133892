import {ConsumableProductCategory, ProductPacking, ProductType} from "../model/BackendReferential";
import {intlReferential} from "../i18n/YlbIntlLabel";
import {IntlShape} from "react-intl";
import {capitalize} from "./StringHelpers";

export const productDescription = (
    intl: IntlShape,
    productType: ProductType,
    category?: ConsumableProductCategory,
    caliber?: string,
    packing?: ProductPacking,
    packingWeightGrams?: number,
) => {
  let description = intlReferential({
    referential: "ProductType",
    id: productType,
    doCapitalize: true,
    intl: intl
  });
  return description;
}

export const caliberDisplay = (caliber?: string) => {
  return (caliber && caliber.length > 0 && caliber.toUpperCase() != "UKNOWN" && caliber.toUpperCase() != "UKN") ?
      capitalize(caliber) : "";
}