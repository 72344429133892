import React, {useRef} from "react";
import {useOrderWrapper} from "./CommonOrderDetailsContext";
import {YlbIntlLabel} from "../../../../../_ylb/i18n/YlbIntlLabel";
import {Form, Formik} from "formik";
import {backendApiRequest} from "../../../../../_ylb/api/YBackendApiClient";
import {
  adminOrderProviderProformaDownloadEndpoint,
  adminOrderProviderProformaUploadConfirmationEndpoint,
  adminOrderProviderProformaUploadSignEndpoint,
  adminOrderUpdateEndpoint,
  urlEnrich
} from "../../../../../_ylb/api/BackendEndpoints";
import {toast} from "react-toastify";
import {KTSVG, toAbsoluteUrl} from "../../../../../_metronic/helpers";
import {OrderStatus} from "../../../../../_ylb/model/BackendReferential";
import {allOrderStatusChronological} from "./CustomerOrderDetailsOverview";
import _ from "lodash";
import clsx from "clsx";
import {YApiResponse} from "../../../../../_ylb/api/YApiResponse";
import axios from "axios";
import AwsS3 from "@uppy/aws-s3";
import Uppy from '@uppy/core';
import '@uppy/core/dist/style.min.css'
import '@uppy/dashboard/dist/style.min.css'
import {Dashboard} from "@uppy/react";

type OrderStatusUpdate = {
  status: OrderStatus | "UKN"
}

type ProviderProformaDownloadDto = {
  downloadUrl: string
}

export const AdminOrderDetailsOverview = () => {
  const orderWrapper = useOrderWrapper();
  const orderDetails = orderWrapper.order;
  const orderStatusModalCloseButtonRef = useRef<HTMLButtonElement>(null)
  const orderProviderProformaUploadModalCloseButtonRef = useRef<HTMLButtonElement>(null)

  const allowedOrderStatuses = _.concat(orderDetails.nextStatuses, [orderDetails.status])
  const allowedOrderStatusesChronological = allOrderStatusChronological.filter(status => allowedOrderStatuses.includes(status))

  const onProviderProformaDownload = () => {
    backendApiRequest<ProviderProformaDownloadDto>({
      url: urlEnrich({
        url: adminOrderProviderProformaDownloadEndpoint,
        orderToken: orderDetails.token
      }),
      method: "POST",
      onSuccess: response => {
        window.open(response.data.data!.downloadUrl, "_blank");
      },
      onError: error => {
        toast.error("Unable to get invoice download url")
      }
    });
  };

  /* ***********************************
   *         UPLOAD stuff
   *************************************/

  // @ts-ignore because this little shit doesn't initialize :(
  const uppy = new Uppy({
    // debug: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ['.pdf'],
    },
    onBeforeFileAdded: (currentFile, files) => {
      return {
        ...currentFile,
        name: 'proforma.pdf',
      }
    },
  })
  // @ts-ignore
  .use(AwsS3, {
    shouldUseMultipart: (file) => {
      // no multipart as files should by small size
      return false
    },
    // @ts-ignore
    getUploadParameters: (file) => {
      return axios.post<YApiResponse<any>, any>(urlEnrich({
        url: adminOrderProviderProformaUploadSignEndpoint,
        orderToken: orderDetails.token
      }))
      .then((response) => {
        return {
          url: response.data.data.uploadUrl,
          method: 'PUT',
          fields: {},
          headers: {
            'Content-Type': 'application/pdf',
          },
        }
      }).catch((error) => {
        console.log('An Error has occurred while uploading')
        console.log(error)
      })
    },
  })
  .on('upload-error', (file, error) => {
    console.log('error with file:', file?.id)
    console.log('error message:', error)
  })
  .on('complete', result => {
    // send confirmation
    axios.post<YApiResponse<any>, any>(
        urlEnrich({
          url: adminOrderProviderProformaUploadConfirmationEndpoint,
          orderToken: orderDetails.token
        }),
        {
          uploadUrl: result.successful[0].uploadURL
        })
    .then((response) => {
      // close upload modal asap
      orderProviderProformaUploadModalCloseButtonRef.current!.click()
      toast.success("Provider proforma successfully uploaded.");
      orderWrapper.reloadOrder()
    }).catch((error) => {
      console.log('An Error has occurred while confirming upload')
      console.log(error)
    })
  })

  return (
      <>
        <div className='row'>
          <div className='col-4'>
            <div className="card h-xl-100">
              <div className="card-header align-items-center border-0 px-5">
                <h3 className="card-title align-items-start flex-column">
                <span className="fw-bold mb-2 text-gray-900">
                  Order management
                </span>
                </h3>
              </div>

              <div className="card-body pt-0">
                {(orderDetails.nextStatuses && orderDetails.nextStatuses.length > 0) ? <>
                      <button type="button"
                              className="btn btn-primary btn-sm"
                              data-bs-toggle="modal"
                              data-bs-target="#admin_order_status_modal">
                        <span className="indicator-label">
                          <i className="fa fa-rotate fs-7"></i>
                          &nbsp;&nbsp;Update order status
                        </span>
                      </button>
                    </>
                    : <div
                        className="row py-3 mb-5 bg-light-warning border-warning border border-dashed d-flex align-items-center text-center">
                      <span>
                        This order cannot be modified.
                      </span>
                    </div>}
              </div>
            </div>
          </div>
          <div className='col-4'>
            <div className="card h-xl-100">
              <div className="card-header align-items-center border-0 px-5">
                <h3 className="card-title align-items-start flex-column">
                <span className="fw-bold mb-2 text-gray-900">
                  Payment actions
                </span>
                </h3>
              </div>
              <div className="card-body pt-0">
                <i>Coming soon...</i>
              </div>
            </div>
          </div>
          <div className='col-4'>
            <div className="card h-xl-100">
              <div className="card-header align-items-center border-0 px-5">
                <h3 className="card-title align-items-start flex-column">
                <span className="fw-bold mb-2 text-gray-900">
                  Order documents
                </span>
                </h3>
              </div>
              <div className="card-body px-5 pt-0">
                {orderDetails.hasProviderProforma ?
                    <div className='d-flex justify-content-between'>
                      <div className="d-flex flex-row">
                        <a href="#" onClick={onProviderProformaDownload}>
                          <img alt='' className='w-30px me-3'
                               src={toAbsoluteUrl('/media/svg/files/pdf.svg')}/>
                        </a>
                        <div className='ms-1 fw-bold d-flex align-items-center'>
                          <a href='#' className='fs-6 text-hover-primary fw-bolder'
                             onClick={onProviderProformaDownload}>
                            Provider proforma
                          </a>
                        </div>
                      </div>
                      <div>
                        <button type="button"
                                className="btn btn-primary btn-sm"
                                data-bs-toggle="modal"
                                data-bs-target="#admin_provider_proforma_upload_modal">
                          <span className="indicator-label">
                    Change
                  </span>
                        </button>
                      </div>
                    </div>
                    : <button type="button"
                              className="btn btn-primary btn-sm"
                              data-bs-toggle="modal"
                              data-bs-target="#admin_provider_proforma_upload_modal">
                      <i className="fa fa-upload fs-5"></i>
                      <span className="indicator-label">
                        &nbsp;Upload provider proforma
                      </span>
                    </button>
                }
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" tabIndex={-1} id="admin_order_status_modal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title">Order status update</h3>
                <div
                    className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                    data-bs-dismiss="modal"
                    aria-label="Close">
                  <KTSVG
                      path="media/icons/duotune/arrows/arr061.svg"
                      className="svg-icon svg-icon-2x"
                  />
                </div>
              </div>
              <Formik initialValues={{status: orderDetails.status}}
                      onSubmit={(values, actions) => {
                        backendApiRequest<any>({
                          url: urlEnrich({
                            url: adminOrderUpdateEndpoint,
                            orderToken: orderDetails.token
                          }),
                          method: "POST",
                          data: values,
                          onSuccess: response => {
                            orderStatusModalCloseButtonRef.current!.click();
                            actions.setSubmitting(false);
                            toast.success("Order status successfully saved.");
                            orderWrapper.reloadOrder();
                          }
                        });
                      }}>
                {({values, setFieldValue, isSubmitting}) => (
                    <Form>
                      <div className="modal-body">
                        <div data-kt-buttons="true">
                          {allowedOrderStatusesChronological.map((status) =>
                              <label key={status}
                                     className={clsx("btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-6 mb-5",
                                         {"btn-active-light-danger": status === "CNC"},
                                         {"btn-text-danger": status === "CNC"},
                                         {"btn-active-light-primary": status !== "CNC"},
                                         {"active": values.status === status}
                                     )}>
                                <div className="d-flex align-items-center me-2">
                                  <div
                                      className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                    <input className="form-check-input" type="radio" name="plan"
                                           value={status} checked={values.status === status}
                                           onChange={() => setFieldValue("status", status)}/>
                                  </div>
                                  <div className="flex-grow-1">
                                    <h2 className={clsx("d-flex align-items-center fs-3 fw-bold flex-wrap",
                                        {"text-danger": status === "CNC"})}>
                                      <YlbIntlLabel id={`OrderStatus.${status}`}
                                                    doCapitalize={true}/>
                                    </h2>
                                    <div className="fw-semibold opacity-50">
                                      <YlbIntlLabel id={`AdminOrderStatus.${status}`}
                                                    doCapitalize={true}/>
                                    </div>
                                  </div>
                                </div>
                              </label>
                          )}
                        </div>
                      </div>
                      <div className="modal-footer d-flex justify-content-between">
                        <button type="button" className="btn btn-light" data-bs-dismiss="modal"
                                ref={orderStatusModalCloseButtonRef}>
                          Cancel
                        </button>
                        <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                          Save order status
                        </button>
                      </div>
                    </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div className="modal fade" tabIndex={-1} id="admin_provider_proforma_upload_modal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title">Provider proforma upload</h3>
                <div
                    className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                    data-bs-dismiss="modal"
                    aria-label="Close">
                  <KTSVG
                      path="media/icons/duotune/arrows/arr061.svg"
                      className="svg-icon svg-icon-2x"
                  />
                </div>
              </div>
              <div className="modal-body">
                <Dashboard uppy={uppy} singleFileFullScreen={false} height="200px"
                           proudlyDisplayPoweredByUppy={false}/>
              </div>
              <div className="modal-footer d-flex justify-content-between">
                <button type="button" className="btn btn-light" data-bs-dismiss="modal"
                        ref={orderProviderProformaUploadModalCloseButtonRef}>
                  Cancel
                </button>
              </div>

            </div>
          </div>
        </div>
      </>
  )
}