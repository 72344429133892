import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {PlatformUserData, PlatformUserUpdate} from "./PlatformUserUpdate";
import {NotYetFetched, YFetched} from "../../../../../../_ylb/api/YFetched";
import {backendApiRequest} from "../../../../../../_ylb/api/YBackendApiClient";
import {adminUserDetailsEndpoint, urlEnrich} from "../../../../../../_ylb/api/BackendEndpoints";

export const PlatformUserEdit = () => {
  const {userToken, customerToken, providerToken} = useParams();
  const [userData, setUserData] = useState<YFetched<PlatformUserData>>(NotYetFetched);
  const queryParam = (customerToken !== undefined ? ['customerToken', customerToken!] : ['providerToken', providerToken!]);

  useEffect(() => {
        backendApiRequest<PlatformUserData>({
          url: urlEnrich({
            url: adminUserDetailsEndpoint,
            userToken: userToken
          }) + "?" + new URLSearchParams([queryParam]).toString(),
          onSuccess: (res) => {
            setUserData({
              data: res.data.data!,
              isFetched: true
            })
          }
        });
      }, [userToken]
  );

  return <>{
    userData.isFetched ?
        <>
          <PlatformUserUpdate userData={userData.data}/>
        </>
        : <></>
  }</>
}