import React from 'react';
import clsx from "clsx";
import {useField} from "formik";
import Select from "react-select";
import YFormLabel from "./YFormLabel";
import {CommonFieldProps} from "./_form_helpers";

export type YOption = {
  label: string
  value: string
}

export type YSelectBaseProps = CommonFieldProps<'select'> & {
  advancedMode?: boolean
}

/**
 * This component is not supposed to handle children.
 */
export const YSelect = ({
                          label,
                          containerClass,
                          required,
                          displaySize,
                          advancedMode = false,
                          options = [],
                          ...props
                        }: YSelectBaseProps & {
  options: YOption[]
}) => {
  const [field, meta, helpers] = useField({...props});

  // because react-select requires the whole option to set the initial value
  const defaultValue = options.filter(option => option.value == field.value).find(x => x !== undefined);
  return (
      <div className={clsx(containerClass)}>
        {label.length > 0 && (<YFormLabel label={label} required={required}/>)}
        {advancedMode ?
            <Select
                options={options}
                defaultValue={defaultValue}
                onChange={(newValue) => helpers.setValue(newValue!.value)}
                isDisabled={props.disabled}
            /> :
            <select data-control='select2'
                    {...field} {...props}
                    className={clsx('form-select form-select-solid',
                        {'is-invalid': meta.touched && meta.error},
                        {'form-select-sm': displaySize === "small"}
                    )}
            >
              {options.map(option =>
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
              )}
            </select>}
        {meta.error && <div className='fv-plugins-message-container invalid-feedback'>
          {meta.error}
        </div>}
      </div>
  );
}