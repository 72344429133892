import {useLocation, useNavigate, useParams} from "react-router-dom";
import YForm from "../../../../../../_ylb/form/YForm";
import {backendApiRequest} from "../../../../../../_ylb/api/YBackendApiClient";
import {toast} from "react-toastify";
import {getClosestUri} from "../../../../../../_ylb/routing/YRoutingHelper";
import {KTCard, KTCardBody} from "../../../../../../_metronic/helpers";
import YInput from "../../../../../../_ylb/form/YInput";
import React from "react";
import {adminUserUpdateEndpoint} from "../../../../../../_ylb/api/BackendEndpoints";

const userInitialValues: PlatformUserData = {
  email: "",
  firstName: "",
  lastName: "",
  password: ""
}

export type PlatformUserData = {
  email: string
  firstName: string
  lastName: string
  password: string
}

export const PlatformUserUpdate = ({userData}: {
  userData?: PlatformUserData
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {userToken, customerToken, providerToken} = useParams();

  let isExistingUser = false;
  if (userData) {
    isExistingUser = true;
  }
  const userValues = userData ?? userInitialValues;

  return (
      <>
        <YForm initialValues={userValues}
               onSubmit={(values, actions) => {
                 let payload = {
                   ...values,
                   token: userToken,
                   customerToken: customerToken,
                   providerToken: providerToken
                 };

                 backendApiRequest<string>({
                   url: adminUserUpdateEndpoint,
                   method: "POST",
                   data: payload,
                   onSuccess: response => {
                     actions.setSubmitting(false);
                     toast.success("User successfully saved.");
                     navigate(getClosestUri(location.pathname, "/user"));
                   }
                 });

               }}>
          <div className="row">
            <div className="col-6">
              <KTCard>
                <div className="card-header border-0 pt-5">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold text-dark">
                      {isExistingUser ? "Editing user: " + userToken : "New user"}
                    </span>
                  </h3>
                </div>
                <KTCardBody>
                  <div className="row pb-5">
                    <YInput label="First name"
                            name="firstName"
                            containerClass="col-6"
                            required={true}/>
                    <YInput label="Last name"
                            name="lastName"
                            containerClass="col-6"
                            required={true}/>
                  </div>
                  <div className="row pb-5">
                    <YInput label="Email address"
                            name="email"
                            containerClass="col-8"
                            required={true}/>
                  </div>
                  <div className="row pb-5">
                    <YInput label="New password"
                            name="password"
                            containerClass="col-8"/>
                  </div>
                </KTCardBody>
              </KTCard>
              <div className="d-flex justify-content-end mt-10 mb-15">
                <button type="button" className="btn btn-light me-5"
                        onClick={() => navigate(-1)}>
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary"
                        data-kt-users-modal-action="submit">
                  <span className="indicator-label">
                    <i className='fas fa-floppy-disk ms-2 fs-5'></i> Save
                  </span>
                </button>
              </div>
            </div>
          </div>
        </YForm>
      </>
  )
}