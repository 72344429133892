import {YlbIntlLabel} from "../../i18n/YlbIntlLabel";
import React from "react";

export const VerticalStepperStep = ({
                                      stepNumber,
                                      labelId,
                                      subLabelId,
                                      appendLine = false,
                                      currentStepNumber
                                    }: {
  stepNumber: number
  currentStepNumber?: number
  labelId: string
  subLabelId?: string
  appendLine?: boolean
}) => {
  let stepState = 'pending';
  if (currentStepNumber) {
    if (currentStepNumber > stepNumber) {
      stepState = 'completed';
    } else if (currentStepNumber === stepNumber) {
      stepState = 'current';
    }
  }

  return (
      <div className={'stepper-item '+stepState} data-kt-stepper-element='nav'>
        <div className='stepper-wrapper'>
          <div className='stepper-icon w-40px h-40px'>
            <i className='stepper-check fas fa-check'></i>
            <span className='stepper-number'>{stepNumber}</span>
          </div>
          <div className='stepper-label'>
            <h3 className='stepper-title'>
              <YlbIntlLabel id={labelId} doCapitalize={true}/>
            </h3>
            {subLabelId &&
                <div className='stepper-desc fw-semibold'>
                  <YlbIntlLabel id={subLabelId} doCapitalize={true}/>
                </div>}
          </div>
        </div>
        {appendLine && <div className='stepper-line h-40px'></div>}
      </div>
  )
}