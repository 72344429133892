import * as Referentials from "../model/BackendReferential";
import {useIntl} from "react-intl";
import {capitalize} from "../helpers/StringHelpers";
import {YSelectObjectBase, YSelectObjectBaseProps} from "./YSelectObjectBase";
import {YOption} from "./YSelect";

export const YSelectReferential = ({
                                     referential,
                                     topDefaultOptionValue,
                                     ...props
                                   }: YSelectObjectBaseProps & {
  topDefaultOptionValue?: string
  referential: string
}) => {
  const intl = useIntl();

  // DAL : couldn't find a way to properly setup this :(
  // @ts-ignore
  let values = Referentials["all" + referential] as string[];
  let optionData: YOption[] = values.map(value => {
    return {
      value: value,
      label: capitalize(
          intl.formatMessage({
            id: referential + '.' + value,
            defaultMessage: value
          }))
    }
  });

  let atFirst: string[] = [];
  let additionalOptions: YOption[] = [];
  if (topDefaultOptionValue) {
    additionalOptions = [{
      value: topDefaultOptionValue,
      label: "- " + intl.formatMessage({
        id: "CommonLabels.all",
        defaultMessage: 'Select value'
      }) + " -"
    }];
    atFirst = [topDefaultOptionValue];
  }

  return <YSelectObjectBase options={optionData}
                            additionalOptions={additionalOptions}
                            atFirst={atFirst}
                            advancedMode={true}
                            {...props} />
}