const customerToken = ":customerToken"
const orderToken = ":orderToken"
const productToken = ":productToken"
const providerToken = ":providerToken"
const userToken = ":userToken"
const productType = ":productType"
const marginRuleId = ":marginRuleId"

export const urlEnrich = (params: {
  url: string
  customerToken?: string
  orderToken?: string
  productToken?: string
  providerToken?: string
  userToken?: string
  productType?: string
  marginRuleId?: number
}) => {
  let finalUrl = params.url;
  if (params.customerToken) {
    finalUrl = finalUrl.replaceAll(customerToken, params.customerToken);
  }
  if (params.orderToken) {
    finalUrl = finalUrl.replaceAll(orderToken, params.orderToken);
  }
  if (params.productToken) {
    finalUrl = finalUrl.replaceAll(productToken, params.productToken);
  }
  if (params.providerToken) {
    finalUrl = finalUrl.replaceAll(providerToken, params.providerToken);
  }
  if (params.userToken) {
    finalUrl = finalUrl.replaceAll(userToken, params.userToken);
  }
  if (params.productType) {
    finalUrl = finalUrl.replaceAll(productType, params.productType);
  }
  if (params.marginRuleId) {
    finalUrl = finalUrl.replaceAll(marginRuleId, "" + params.marginRuleId);
  }
  return finalUrl;
}

const subdomain = window.location.hostname.split('.')[0];
const apiPrefix = window.location.protocol + "//" + subdomain + "-api." + window.location.hostname.replaceAll(subdomain + ".", "")
export const apiProcessLogin = apiPrefix + "/process-login"
export const apiGetSelfUserData = apiPrefix + "/self"

const customerPrefix = apiPrefix + "/customer"
const customerProduct = customerPrefix + "/product"
const customerCheckout = customerPrefix + "/checkout"
const customerCheckoutConfirmation = customerCheckout + "/confirm"
const customerOrder = customerPrefix + "/order"
const customerOrderWithToken = customerOrder + "/" + orderToken

export const customerProductSearchEndpoint = customerProduct
export const customerProductCheckoutEndpoint = customerCheckout
export const customerProductCheckoutConfirmationEndpoint = customerCheckoutConfirmation
export const customerOrderListEndpoint = customerOrder
export const customerOrderDetailsEndpoint = customerOrderWithToken

const adminPrefix = apiPrefix + "/admin"
const adminProvider = adminPrefix + "/provider"
const adminProviderWithToken = adminProvider + "/" + providerToken
const adminProviderProduct = adminProviderWithToken + "/product"
const adminProviderProductWithToken = adminProviderProduct + "/" + productToken
const adminProviderProductWithType = adminProviderWithToken + "/producttype/" + productType
const adminProviderProductCustomMargin = adminProviderProductWithType + "/margin"
const adminCustomer = adminPrefix + "/customer"
const adminCustomerWithToken = adminCustomer + "/" + customerToken
const adminConfig = adminPrefix + "/config"
const adminConfigMarginRule = adminConfig + "/marginrule"
const adminConfigMarginRuleWithId = adminConfigMarginRule + "/" + marginRuleId
const adminConfigTransportQuotation = adminConfig + "/transportquotation"
const adminUser = adminPrefix + "/user"
const adminUserDetails = adminUser + "/" + userToken
const adminOrder = adminPrefix + "/order"
const adminOrderWithToken = adminOrder + "/" + orderToken
const adminOrderUpdate = adminOrderWithToken + "/status"
const adminOrderProviderProforma = adminOrderWithToken + "/providerproforma"
const adminOrderProviderProformaConfirmation = adminOrderProviderProforma + "/confirm"
const adminOrderProviderProformaDownload = adminOrderProviderProforma + "/download"

export const adminProviderListEndpoint = adminProvider
export const adminProviderRecapEndpoint = adminProviderWithToken + "/recap"
export const adminProviderDetailsEndpoint = adminProviderWithToken
export const adminProviderUpdateEndpoint = adminProvider
export const adminProviderListProviderProductsEndpoint = adminProviderProduct
export const adminProviderShowProviderProductEndpoint = adminProviderProductWithToken
export const adminProviderUpdateProviderProductEndpoint = adminProviderProduct
export const adminProviderDeleteProviderProductEndpoint = adminProviderProductWithToken
export const adminProviderMarginEndpoint = adminProviderWithToken + "/margin"
export const adminProviderProductCustomMarginEndpoint = adminProviderProductCustomMargin
export const adminCustomerListEndpoint = adminCustomer
export const adminCustomerDetailsEndpoint = adminCustomerWithToken
export const adminCustomerUpdateEndpoint = adminCustomer
export const adminConfigMarginRuleListEndpoint = adminConfigMarginRule
export const adminConfigMarginRuleDetailsEndpoint = adminConfigMarginRuleWithId
export const adminConfigMarginRuleUpdateEndpoint = adminConfigMarginRule
export const adminConfigTransportQuotationListEndpoint = adminConfigTransportQuotation
export const adminUserListEndpoint = adminUser
export const adminUserDetailsEndpoint = adminUserDetails
export const adminUserUpdateEndpoint = adminUser
export const adminOrderListEndpoint = adminOrder
export const adminOrderDetailsEndpoint = adminOrderWithToken
export const adminOrderUpdateEndpoint = adminOrderUpdate
export const adminOrderProviderProformaUploadSignEndpoint = adminOrderProviderProforma
export const adminOrderProviderProformaUploadConfirmationEndpoint = adminOrderProviderProformaConfirmation
export const adminOrderProviderProformaDownloadEndpoint = adminOrderProviderProformaDownload
