import React, {useEffect, useState} from "react";
import {KTCard, KTCardBody} from "../../../../../_metronic/helpers";
import {Country} from "../../../../../_ylb/model/BackendReferential";
import {adminCustomerListEndpoint} from "../../../../../_ylb/api/BackendEndpoints";
import {Link, useNavigate} from "react-router-dom";
import {backendApiRequest} from "../../../../../_ylb/api/YBackendApiClient";
import {CountryDisplay} from "../../../../../_ylb/helpers/CountryDisplay";
import {NotYetFetched, YFetched} from "../../../../../_ylb/api/YFetched";

type CustomerList = {
  customers: CustomerListItem[]
}

type CustomerListItem = {
  token: string
  name: string
  country: Country
}

export const AdminCustomerList = () => {
  const [customerList, setCustomerList] = useState<YFetched<CustomerList>>(NotYetFetched);
  const navigate = useNavigate();

  useEffect(() => {
    backendApiRequest<CustomerList>({
      url: adminCustomerListEndpoint,
      onSuccess: (response) => setCustomerList({
        data: response.data.data!,
        isFetched: true
      })
    });
  }, []);

  return (
      <KTCard>
        <KTCardBody className='py-4'>
          {customerList.isFetched ?
              <>
                {customerList.data!.customers.length > 0 ? <>
                      <>
                        <table
                            className="table table-row-dashed table-row-gray-300 gy-2 text-center">
                          <thead>
                          <tr className="fw-bolder fs-6 text-gray-800">
                            <th className="text-start">Country</th>
                            <th className="text-start">Name</th>
                            <th>Actions</th>
                          </tr>
                          </thead>
                          <tbody>
                          {
                            customerList.data!.customers.map((customer) => {
                              return (
                                  <tr key={customer.token}>
                                    <td className="text-start">
                                      <CountryDisplay country={customer.country}/>
                                    </td>
                                    <td className="text-start">{customer.name}</td>
                                    <td>
                                      {/*<Link to={`${customer.token}`}>*/}
                                      {/*  <i*/}
                                      {/*      className='fas fa-regular fa-eye ms-2 fs-3'*/}
                                      {/*      data-bs-toggle='tooltip'*/}
                                      {/*      title='Show customer'*/}
                                      {/*  ></i>*/}
                                      {/*</Link>*/}
                                      <Link to={`${customer.token}/edit`}>
                                        <i
                                            className='fas fa-regular fa-pen-to-square ms-2 fs-3'
                                            data-bs-toggle='tooltip'
                                            title='Edit customer'
                                        ></i>
                                      </Link>
                                      <Link to={`${customer.token}/user`}>
                                        <i
                                            className='fas fa-regular fa-users ms-2 fs-3'
                                            data-bs-toggle='tooltip'
                                            title='Show users'
                                        ></i>
                                      </Link>
                                    </td>
                                  </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </>
                    </>
                    : <>
                      No customer found.
                    </>}
                <button className="btn btn-primary btn-sm" onClick={() => {
                  navigate("../customer/new");
                }}>
                  <i className="las la-plus-circle fs-4 me-2"></i> New customer
                </button>
              </>
              : <>
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>&nbsp;
                Loading...
              </>
          }
        </KTCardBody>
      </KTCard>
  )
}