import _ from "lodash";
import {CountryDisplay} from "../../../../../../../_ylb/helpers/CountryDisplay";
import React, {useEffect, useState} from "react";
import {useAdminProviderRecap} from "../AdminProviderIdentifiedContext";
import {useFormikContext} from "formik";
import {allowedIncotermForCustomPrices, ProviderProductData} from "./AdminProviderProductUpdate";
import {adminProviderMarginEndpoint} from "../../../../../../../_ylb/api/BackendEndpoints";
import {Country, Currency} from "../../../../../../../_ylb/model/BackendReferential";
import {useParams} from "react-router-dom";
import {NotYetFetched, YFetched} from "../../../../../../../_ylb/api/YFetched";
import {
  numberCentsToString,
  numberMillisToString,
  stringToCents,
  stringToMillis
} from "../../../../../../../_ylb/helpers/numerics";
import {useIntl} from "react-intl";
import {backendApiRequest} from "../../../../../../../_ylb/api/YBackendApiClient";

type MarginRecapApi = {
  destinationCountry: Country
  hasMargin: boolean
  marginPercentCents: number
  pricePerKgCents: number
  currency: Currency
}

type MarginRecapDto = {
  marginsByCountry: {
    [key in Country]: MarginRecapApi
  }
}

type MarginRecapEnriched = MarginRecapApi & {
  countryLabel: string
  containerCostCents: number
}

type AllMarginRecapEnriched = {
  margins: MarginRecapEnriched[]
}

export const ProductMarginDetails = () => {
  const intl = useIntl();
  const {providerToken} = useParams();
  const adminProviderRecap = useAdminProviderRecap();
  const {
    values: {
      type,
      incoterm,
      packingWeight,
      packingPerBox,
      boxesPerPallet,
      priceUnit,
      price,
      customMarginPercent,
      incotermPrices
    }
  } = useFormikContext<ProviderProductData>();
  const [marginRecap, setMarginRecap] =
      useState<YFetched<AllMarginRecapEnriched>>(NotYetFetched);

  const packingWeightGrams = stringToMillis(packingWeight);
  const boxWeight = packingPerBox * packingWeightGrams;
  const boxesPerContainer = adminProviderRecap.provider.palletPerContainer * boxesPerPallet;
  const containerWeightGrams = boxesPerContainer * boxWeight;
  const priceCents = stringToCents(price); // main price set in the page
  const customMarginPercentCents = stringToCents(customMarginPercent);
  const marginApiCallable =
      type !== "UKN"
      && packingWeightGrams > 0
      && customMarginPercentCents >= 0
      && packingPerBox > 0
      && boxesPerPallet > 0
      && incoterm !== "EXW"
      && (
          (!allowedIncotermForCustomPrices.includes(incoterm) && priceCents > 0)
          || (allowedIncotermForCustomPrices.includes(incoterm) && incotermPrices.length > 0 && incotermPrices.reduce((total, current) => {
            if (current.amount && current.amount.length > 0) {
              return total + stringToCents(current.amount);
            } else {
              return 0;
            }
          }, 0) > 0)
      );

  useEffect(() => {
        if (marginApiCallable) {
          setMarginRecap(NotYetFetched);
          const payload = {
            productType: type,
            providerToken: providerToken,
            productIncoterm: incoterm,
            packingWeightGrams: packingWeightGrams,
            packingPerBox: packingPerBox,
            boxesPerPallet: boxesPerPallet,
            priceUnit: priceUnit,
            priceCents: priceCents,
            customMarginPercentCents: customMarginPercentCents,
            incotermPrices: allowedIncotermForCustomPrices.includes(incoterm) ? incotermPrices.map(price => ({
              country: price.country,
              amountInCents: stringToCents(price.amount)
            })) : [],
          }
          backendApiRequest<MarginRecapDto>({
            method: "POST",
            url: adminProviderMarginEndpoint,
            data: payload,
            onSuccess: (res) => {
              let incotermPricesByCountry = {} as { [key in Country]: number };
              incotermPrices.forEach(price => {
                incotermPricesByCountry[price.country] = stringToCents(price.amount);
              });

              let allMargins = {
                margins: Object.keys(res.data.data!.marginsByCountry).map((key) => {
                  const country = key as Country
                  const marginApi = res.data.data!.marginsByCountry[country];

                  const providerPriceCents = !allowedIncotermForCustomPrices.includes(incoterm) ? priceCents
                      : stringToCents(_.chain(incotermPrices)
                      .find(incotermPrice => incotermPrice.country === marginApi.destinationCountry)
                      .value().amount);

                  const providerCostCentsPerBox = priceUnit === "BOX" ? providerPriceCents
                      : packingPerBox * packingWeightGrams * providerPriceCents / 1000;

                  return {
                    ...marginApi,
                    countryLabel: intl.formatMessage({
                      id: "country." + marginApi.destinationCountry,
                      defaultMessage: marginApi.destinationCountry
                    }),
                    containerCostCents: providerCostCentsPerBox * boxesPerContainer,
                  }
                })
              }
              allMargins.margins.sort((x, y) => x.countryLabel.localeCompare(y.countryLabel));

              setMarginRecap({
                data: allMargins,
                isFetched: true
              })
            }
          });
        }
      }, [type, incoterm, packingWeight, packingPerBox, boxesPerPallet, priceUnit, price, customMarginPercent, incotermPrices]
  );

  return (
      <>
        {marginApiCallable ?
            <>
              {
                marginRecap.isFetched ?
                    <table
                        className="table table-row-dashed table-row-gray-300 gy-2 text-center">
                      <thead>
                      <tr className="fw-bolder fs-6 text-gray-800 text-end">
                        <th className="text-start">Destination</th>
                        <th>Our margin</th>
                        <th>Customer price / kg</th>
                        <th>Container cost</th>
                        <th>Container Weight</th>
                        <th>Container profits</th>
                      </tr>
                      </thead>
                      <tbody>{
                        marginRecap.data!.margins.map(marginItem =>
                            <tr key={marginItem.destinationCountry}>
                              <td className="text-start">
                                <CountryDisplay country={marginItem.destinationCountry}/>
                              </td>
                              {
                                marginItem.hasMargin ?
                                    <>
                                      <td className="text-end">
                                        {numberCentsToString(marginItem.marginPercentCents, ".", false)}%
                                      </td>
                                      {
                                        marginApiCallable ?
                                            <>
                                              <td className="text-end">
                                                {numberCentsToString(marginItem.pricePerKgCents) + " " + marginItem.currency}
                                              </td>
                                              <td className="text-end">
                                                {numberCentsToString(marginItem.containerCostCents) + " " + adminProviderRecap.provider.currency}
                                              </td>
                                              <td className="text-end">
                                                {numberMillisToString(containerWeightGrams, ".", true)}&nbsp;kg
                                              </td>
                                              <td className="text-end">
                                                {numberCentsToString(Math.round((marginItem.containerCostCents * marginItem.marginPercentCents) / 10000))
                                                    + " " + adminProviderRecap.provider.currency}
                                              </td>
                                            </>
                                            : <td colSpan={4}>
                                              <em>Missing weight, pallets and price.</em>
                                            </td>
                                      }
                                    </>
                                    : <td colSpan={4}>
                                      <em>No margin for this product and country. Prices will not be
                                        computed.</em>
                                    </td>
                              }
                            </tr>)
                      }</tbody>
                    </table>
                    : <div className="row">
                      <div className="col-12 text-center">Loading margins...</div>
                    </div>
              }
            </> : <>
              <em><i className="fas fa-triangle-exclamation fs-4 me-2"></i>
                {incoterm === "EXW" ? <>
                  Incoterm EXW is not supported yet.
                </> : <>
                  Missing product data to allow margin computation.
                </>}
              </em>
            </>
        }
      </>
  )
}