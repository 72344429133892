import {KTCard, KTCardBody, WithChildren} from "../../../../../../_metronic/helpers";
import {intlReferential} from "../../../../../../_ylb/i18n/YlbIntlLabel";
import {useOrderWrapper} from "../CommonOrderDetailsContext";
import {useIntl} from "react-intl";
import {useNavigate, useParams} from "react-router-dom";
import './container-view.css'
import clsx from 'clsx'
import {isNumeric} from "../../../../../../_ylb/helpers/numerics";

export const CommonOrderDetailsContainers = ({children}: WithChildren) => {
  const intl = useIntl();
  const orderWrapper = useOrderWrapper();
  const orderDetails = orderWrapper.order;
  const {containerIndex} = useParams();
  const navigate = useNavigate();

  const containerIndexNumber = isNumeric(containerIndex) ? parseInt(containerIndex!) : -1;

  return (
      <KTCard>
        <KTCardBody className='py-4'>
          <div className="d-flex align-items-start">
            <ul className="nav nav-pills flex-column nav-pills border-end border-3 min-w-lg-150px me-3 align-items-start ylb-vertical-tabs">

              {orderDetails.containers.map((container, index) =>
                  <li className={clsx("nav-item w-100", {"active": containerIndexNumber === index})}
                      key={index}>

                    <button
                        className="nav-link text-primary fw-semibold position-relative w-100 px-0"
                        type="button"
                        onClick={() => navigate("../../containers/" + index)}>
                      <span className="d-flex flex-column align-items-start">
                              <span className="fs-4 fw-bold">Conteneur {index + 1}</span>
                              <span className="fs-7">
                                {intlReferential({
                                  referential: "OrderContainerVoyageStatus",
                                  id: container.voyageStatus,
                                  intl: intl
                                })}
                              </span>
                          </span>
                    </button>
                  </li>
              )}

            </ul>
            <div className="w-100 ps-2">
              {children}
            </div>
          </div>
        </KTCardBody>
      </KTCard>
  )
}