import {ProviderStatus} from "../BackendReferential";
import {capitalize} from "../../helpers/StringHelpers";
import React from "react";
import {useIntl} from "react-intl";

export const ProviderStatusDisplay = ({status}: { status: ProviderStatus }) => {
  const intl = useIntl();
  const value = capitalize(intl.formatMessage({
    id: "ProviderStatus." + status,
    defaultMessage: status
  }));
  const badgeLevel = () => {
    switch (status) {
      case "ACT":
        return "success";
        break;
      case "REV":
        return "warning";
        break;
      case "DIS":
      case "SBA":
        return "danger";
        break;
      default:
        return "dark";
    }
  }

  return (
      <span className={"badge badge-light-" + badgeLevel() + " fs-7 fw-bold"}>{value}</span>
  )
}