import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {
  adminProviderShowProviderProductEndpoint,
  urlEnrich
} from "../../../../../../../_ylb/api/BackendEndpoints";
import {NotYetFetched, YFetched} from "../../../../../../../_ylb/api/YFetched";
import {
  ConsumableProductCategory,
  Country,
  Currency,
  Incoterm,
  ProductPacking,
  ProductPriceUnit,
  ProductType
} from "../../../../../../../_ylb/model/BackendReferential";
import {AdminProviderProductUpdate, ProviderProductData} from "./AdminProviderProductUpdate";
import {
  numberCentsToString,
  numberMillisToString
} from "../../../../../../../_ylb/helpers/numerics";
import {backendApiRequest} from "../../../../../../../_ylb/api/YBackendApiClient";
import {intlReferential} from "../../../../../../../_ylb/i18n/YlbIntlLabel";
import {IntlShape} from "react-intl/src/types";
import {useIntl} from "react-intl";

export type ProductDetailsDto = {
  product: ProductDto
  prices: PriceDto[]
  customMarginPercentCents: number
}

type ProductIncotermPriceDto = {
  country: Country
  amountInCents: number
}

type ProductDto = {
  type: ProductType
  caliber: string
  category: ConsumableProductCategory
  packing: ProductPacking
  packingWeightGrams: number
  packingPerBox: number
  origin: Country
  incoterm: Incoterm
  incotermPrices: ProductIncotermPriceDto[]
  boxesPerPallet: 0
  currency: Currency
  priceUnit: ProductPriceUnit
  priceInCents: number
  customMarginPercentCents: number
  expiresAt: Date
}

type PriceDto = {
  token: string
  shippingTo: Country
  currency: Currency
  pricePerKg: number
  expiresAt: Date
}

export const mapProductDtoToInternal = (dto: ProductDetailsDto, intl: IntlShape) => {
  const {
    packingWeightGrams,
    priceInCents,
    incotermPrices,
    ...cleanedProductDto
  } = dto.product;
  return {
    ...cleanedProductDto,
    packingWeight: numberMillisToString(packingWeightGrams),
    price: numberCentsToString(priceInCents),
    customMarginPercent: numberCentsToString(dto.customMarginPercentCents),
    incotermPrices: incotermPrices === null ? [] :
        incotermPrices.map(price => ({
          country: price.country,
          amount: numberCentsToString(price.amountInCents),
          countryLabel: intlReferential({
            id: "Country",
            doCapitalize: true,
            intl: intl
          })
        }))
        .sort((x, y) => x.countryLabel.localeCompare(y.countryLabel))
  }
}

export const AdminProviderProductEdit = () => {
  const {providerToken, productToken} = useParams();
  const [productData, setProductData] = useState<YFetched<ProviderProductData>>(NotYetFetched);
  const intl = useIntl();

  useEffect(() => {
        backendApiRequest<ProductDetailsDto>({
          url: urlEnrich({
            url: adminProviderShowProviderProductEndpoint,
            providerToken: providerToken,
            productToken: productToken
          }),
          onSuccess: (res) => {
            const productDetailsDto = res.data.data!;
            setProductData({
              data: mapProductDtoToInternal(productDetailsDto, intl),
              isFetched: true
            })
          }
        });
      }, [productToken]
  );

  return <>{
    productData.isFetched ?
        <>
          <AdminProviderProductUpdate providerProductData={productData.data}/>
        </>
        : <></>
  }</>

}