import {Country, PlatformAsset} from "../../../../../_ylb/model/BackendReferential";
import React, {useEffect, useState} from "react";
import {NotYetFetched, YFetched} from "../../../../../_ylb/api/YFetched";
import {backendApiRequest} from "../../../../../_ylb/api/YBackendApiClient";
import {
  adminConfigMarginRuleDetailsEndpoint,
  adminConfigMarginRuleListEndpoint,
  urlEnrich
} from "../../../../../_ylb/api/BackendEndpoints";
import {KTCard, KTCardBody, KTSVG} from "../../../../../_metronic/helpers";
import {YTable} from "../../../../../_ylb/table/YTable";
import {createColumnHelper} from "@tanstack/react-table";
import {CountryDisplay} from "../../../../../_ylb/helpers/CountryDisplay";
import {intlReferential, YlbIntlLabel} from "../../../../../_ylb/i18n/YlbIntlLabel";
import {IntlShape, useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import {numberCentsToString} from "../../../../../_ylb/helpers/numerics";
import {toast} from "react-toastify";
import moment from "moment";

type MarginRuleListDto = {
  marginRules: MarginRuleListItemDto[]
}

type MarginRuleListItemDto = {
  id: number
  destinationCountry?: Country
  assetType: PlatformAsset
  assetValue: string
  marginPercentInCents: number
  expiresAt: Date
}

const getMarginValueReferential = (assetType: PlatformAsset) => {
  let referential = "ProductFamily";
  if (assetType === "PMT") {
    referential = "ProductMetaType";
  } else if (assetType === "PTY") {
    referential = "ProductType";
  }
  return referential;
}

const getMarginRuleBusinessKey = (margin: MarginRuleListItemDto, intl: IntlShape) => {
  return margin.assetType
      + "_" + intlReferential({
        referential: getMarginValueReferential(margin.assetType),
        id: margin.assetValue,
        intl: intl
      })
      + "_" + margin.marginPercentInCents;
}

const columnHelper = createColumnHelper<MarginRuleListItemDto>();

export const AdminConfigMarginRuleList = () => {
  const [marginRuleList, setMarginRuleList] = useState<YFetched<MarginRuleListDto>>(NotYetFetched);
  const intl = useIntl();
  const navigate = useNavigate();

  const refreshMarginRuleState = () => {
    backendApiRequest<MarginRuleListDto>({
      url: adminConfigMarginRuleListEndpoint,
      onSuccess: (response) => {
        response.data.data!.marginRules.sort((a, b) =>
            getMarginRuleBusinessKey(a, intl).localeCompare(getMarginRuleBusinessKey(b, intl)));

        setMarginRuleList({
          isFetched: true,
          data: response.data.data!
        });
      }
    });
  }

  const deleteMarginRule = (marginRuleId: number) => {
    if (window.confirm("Do you confirm deletion of margin rule #" + marginRuleId + " ?")) {
      backendApiRequest<any>({
        method: "DELETE",
        url: urlEnrich({
          url: adminConfigMarginRuleDetailsEndpoint,
          marginRuleId: marginRuleId
        }),
        onSuccess: (response) => {
          refreshMarginRuleState();
          toast.success("Margin rule #" + marginRuleId + " successfully deleted.");
        }
      });
    }
  }

  const columnDestinationCountry = columnHelper.accessor("destinationCountry",
      {
        header: () => <span>Destination</span>,
        cell: (context) => <CountryDisplay country={context.getValue()!}/>,
        meta: {
          getCellContext: (context) => {
            return {
              key: context.cell.id,
              className: 'text-start',
            }
          },
          getHeaderContext: (context) => {
            return {
              key: context.header.id,
              className: 'text-start',
            }
          },
        }
      });

  const columnTargetType = columnHelper.display({
    id: 'marginTargetType',
    header: () => <span>Target type</span>,
    cell: context => <>
      <YlbIntlLabel referential="PlatformAsset" id={context.row.original.assetType}/>
    </>,
  });

  const columnExpiresAt = columnHelper.accessor("expiresAt",
      {
        header: () => <span>Expiration</span>,
        cell: (context) => moment(context.getValue()).format("DD/MM/yyyy"),
        meta: {
          getCellContext: (context) => {
            return {
              key: context.cell.id,
              className: 'text-start',
            }
          },
          getHeaderContext: (context) => {
            return {
              key: context.header.id,
              className: 'text-start',
            }
          },
        }
      });

  const columnPercentage = columnHelper.accessor("marginPercentInCents",
      {
        header: () => <span>Margin</span>,
        cell: (context) =>
            <>{numberCentsToString(context.getValue(), ".", false)}%</>,
        meta: {
          getCellContext: (context) => {
            return {
              key: context.cell.id,
              className: 'text-end',
            }
          },
          getHeaderContext: (context) => {
            return {
              key: context.header.id,
              className: 'text-end',
            }
          },
        }
      });

  const columnTargetValue = columnHelper.display({
    id: 'marginTargetValue',
    header: () => <span>Target value</span>,
    cell: context => {
      const referential = getMarginValueReferential(context.row.original.assetType);
      return (<>
        <YlbIntlLabel referential={referential} id={context.row.original.assetValue}
                      doCapitalize={true}/>
      </>)
    },
  });

  const columnMarginRuleActions = columnHelper.display({
    id: 'actions',
    header: () => "",
    cell: context => <>
      <button
          type="button"
          className="btn btn-icon btn-active-light-primary w-30px h-30px"
          onClick={() => navigate("/admin/config/marginrule/" + context.row.original.id)}>
        <i
            className='fas fa-regular fa-pen-to-square ms-2 fs-3'
            data-bs-toggle='tooltip'
            title='Edit margin rule'
        ></i>
      </button>
      <button
          type="button"
          className="btn btn-icon btn-active-light-primary w-30px h-30px"
          onClick={() => deleteMarginRule(context.row.original.id)}>
        <KTSVG
            path='/media/icons/duotune/general/gen034.svg'
            className='svg-icon svg-icon-2x svg-icon-danger'
        />
      </button>
    </>,
    meta: {
      getHeaderContext: (context) => {
        return {
          key: context.header.id,
          className: 'min-w-70px',
        }
      },
    }
  })

  const columnsWithoutCountry = [
    columnTargetType,
    columnTargetValue,
    columnPercentage,
    columnExpiresAt,
    columnMarginRuleActions
  ];

  const columnsWithCountry = [
    columnDestinationCountry,
    columnTargetType,
    columnTargetValue,
    columnPercentage,
    columnExpiresAt,
    columnMarginRuleActions
  ];

  useEffect(refreshMarginRuleState, []);

  const globalMarginRules = marginRuleList.isFetched ? marginRuleList.data!.marginRules.filter(rule => rule.destinationCountry == null) : [];
  const countryMarginRules = marginRuleList.isFetched ? marginRuleList.data!.marginRules.filter(rule => rule.destinationCountry != null) : [];

  return (
      <>
        <button className="btn btn-primary btn-sm mb-5" onClick={() => {
          navigate("new");
        }}>
          <i className="las la-plus-circle fs-4 me-2"></i> New margin rule
        </button>
        <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
          <KTCard>
            <div className="card-header">
              <div className="card-title">
                <h2>Global margins</h2>
              </div>
            </div>
            <KTCardBody className='py-4'> {
              marginRuleList.isFetched ? globalMarginRules.length > 0 ?
                      <>
                        <YTable
                            data={globalMarginRules}
                            columns={columnsWithoutCountry}/>
                      </>
                      : <>
                        No margin rule found.
                      </>
                  : <>
              <span
                  className='spinner-border spinner-border-sm align-middle ms-2'></span>&nbsp;
                    Loading...
                  </>
            }
            </KTCardBody>
          </KTCard>

          <KTCard>
            <div className="card-header">
              <div className="card-title">
                <h2>Country additional margins</h2>
              </div>
            </div>
            <KTCardBody className='py-4'> {
              marginRuleList.isFetched ? countryMarginRules.length > 0 ?
                      <>
                        <YTable
                            data={countryMarginRules}
                            columns={columnsWithCountry}/>
                      </>
                      : <>
                        No country-specific margin rule found.
                      </>
                  : <>
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>&nbsp;
                    Loading...
                  </>
            }
            </KTCardBody>
          </KTCard>
        </div>
      </>
  )
}