import {OrderPaymentStatus} from "../../../../../_ylb/model/BackendReferential";
import {intlReferential} from "../../../../../_ylb/i18n/YlbIntlLabel";
import {useIntl} from "react-intl";

export const OrderPaymentStatusBadge = ({status}: { status: OrderPaymentStatus }) => {
  const intl = useIntl();
  const style = (function () {
    switch (status) {
      case "TPA":
        return "success";
      case "PRP":
        return "warning";
      case "CNC":
      case "PRE":
      case "RFU":
        return "danger";
      default:
        return "dark";
    }
  })();

  return (<>
    <div
        className={"badge badge-light-" + style}>{intlReferential({
      referential: "OrderPaymentStatus",
      id: status,
      intl: intl
    })}</div>
  </>)
}